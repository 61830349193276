import React, { useState } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import Dialog from "./Dialog";
import DOMPurify from "dompurify";
import DownloadButton from "./DownloadButton";
import StyledButton from "./StyledButton";
import EmailIcon from "./EmailIcon";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import CloseIcon from '@material-ui/icons/Close';
import { get } from "lodash";
import { useCallback, useEffect } from "react";
import { saveAs } from "file-saver";
import { attachment2Blob, IEmailData } from "../lib/emails";

const NoEmailWrapper = styled.div`
  height: 663px;
  width: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
NoEmailWrapper.displayName = "NoEmailWrapper";

const EmailInformation = styled.div`
  height: 181px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
EmailInformation.displayName = "EmailInformation";

const EmailCloseButton = styled.div`
  position: absolute;
  left: 98%;
  top: 0%;
  background: lightgray;
  color: gray;
`;
EmailCloseButton.displayName = "EmailCloseButton";

const EmailWrapper = styled.div`
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
`;
EmailWrapper.displayName = "EmailWrapper";

const EmailHeader = styled.table<DefaultThemeProps>`
  margin: 0px 20px;
`;
EmailHeader.displayName = "EmailHeader";

const EmailTableBody = styled.tbody``;
EmailTableBody.displayName = "EmailTableBody";

const EmailTableRow = styled.tr``;
EmailTableRow.displayName = "EmailTableRow";

const EmailTableHeader = styled.th`
  width: 0;
  font-weight: 500;
  color: ${props => props.theme.primaryText};
  padding-bottom: 5px;
`;
EmailTableHeader.displayName = "EmailTableHeader";

const EmailTableDataElement = styled.td`
  width: 100%;
  padding-left: 10px;
  font-weight: normal;
  color: ${props => props.theme.secondaryText};
  padding-bottom: 5px;
`;
EmailTableDataElement.displayName = "EmailTableDataElement";

const EmailBody = styled.div`
  height: 500px;
  overflow: scroll;

  a {
    cursor: default;
    pointer-events: none;
  }
`;
EmailBody.displayName = "EmailBody";

const EmailFailureText = styled.span`
  color: ${props => props.theme.secondaryText};
`;
EmailFailureText.displayName = "EmailFailureText";

const EmailTryAgainButton = styled(StyledButton)`
  padding: 12px 33px;
`;
EmailTryAgainButton.displayName = "EmailTryAgainButton";

type Props = {
  emailDetails: IEmailData | null;
  onClose(): void;
  refetchData(): void;
};

export default function EmailDialog({
  emailDetails,
  onClose,
  refetchData
}: Props) {
  const [emailData, setEmailData] = useState<IEmailData | null>(emailDetails);

  useEffect(() => {
    setEmailData(emailDetails);
  }, [emailDetails]);

  const dataRefetch = useCallback(() => {
    setEmailData(null);
    refetchData();
  }, [setEmailData, refetchData]);

  if (emailData && get(emailData, "body") !== "") {
    return (
      <Dialog
        title="Source Email"
        type="Notice"
        format="alert"
        noBodyMargin
        maxWidth="xl"
        enableBackdropClick
        onClose={onClose}
      >
        <EmailCloseButton>
          <CloseIcon
                    type="submit"
                    onClick={() => {
                      onClose()
                    }}
          />
        </EmailCloseButton>
        <EmailWrapper>
          <EmailHeader>
            <EmailTableBody>
              <EmailTableRow id="email_dialog_subject">
                <EmailTableHeader>Subject:</EmailTableHeader>
                <EmailTableDataElement>
                  {emailData.subject}
                </EmailTableDataElement>
              </EmailTableRow>
              <EmailTableRow id="email_dialog_from">
                <EmailTableHeader>From:</EmailTableHeader>
                <EmailTableDataElement>{emailData.from}</EmailTableDataElement>
              </EmailTableRow>
              <EmailTableRow id="email_dialog_to">
                <EmailTableHeader>To:</EmailTableHeader>
                <EmailTableDataElement>
                  {emailData.to.join(", ")}
                </EmailTableDataElement>
              </EmailTableRow>
            </EmailTableBody>
          </EmailHeader>
          <EmailBody
            id="email_dialog_body"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(emailData.body)
            }}
          />
          <EmailTableBody>
            <EmailTableRow id="email_dialog_attachments">
              <EmailTableHeader>No.&nbsp;of&nbsp;Attachments:</EmailTableHeader>
              <EmailTableDataElement>
                {emailData.attachments.length}
              </EmailTableDataElement>
            </EmailTableRow>
            {emailData.attachments.map((item, idx) => (
              <EmailTableRow id={`email_dialog_attachment_${idx}`}>
                <EmailTableHeader>{emailData.attachments[idx].name}</EmailTableHeader>
                <EmailTableDataElement>
                  <DownloadButton
                    id={`email_dialog_download_attachment_${idx}`}
                    type="submit"
                    onClick={() => {
                      saveAs(attachment2Blob(emailData.attachments[idx]), emailData.attachments[idx].name)
                    }}
                  />
                </EmailTableDataElement>
              </EmailTableRow>
            ))}
          </EmailTableBody>
        </EmailWrapper>
      </Dialog>
    );
  }

  return (
    <Dialog
      title="Source Email"
      type="Notice"
      format="alert"
      noBodyMargin
      enableBackdropClick
      onClose={onClose}
      maxWidth="xl"
    >
      <NoEmailWrapper>
        {emailData ? (
          <EmailInformation>
            <EmailIcon />
            <EmailFailureText>
              The email could not be retrieved.
            </EmailFailureText>
            <EmailTryAgainButton onClick={dataRefetch}>
              Try Again
            </EmailTryAgainButton>
          </EmailInformation>
        ) : (
          <LoadingSpinner size="80px" />
        )}
      </NoEmailWrapper>
    </Dialog>
  );
}
