import {
  deleteJson,
  getJson, getPreviewModeFromToken,
  getSenderIdFromToken,
  getUserLoginIdFromToken,
  getUserIdFromToken,
  postJson,
  putJson,
} from "../lib/helpers";
import {
  IAnswerReq,
  IKnownBrowserReq,
  ISecurityQAReq,
  IUpdatePasswordReq,
  IUserProfileReq,
  IUserRegistrationReq,
} from "../../common/types";
import { AxiosResponse } from "axios";

const SENDERS_BASE_URL = "/managesenders";

const USER_BASE_URL = SENDERS_BASE_URL + "/user";

const USERS_BASE_URL = USER_BASE_URL + "s/sender";

const QUESTIONS_BASE_URL = SENDERS_BASE_URL + "/login/security_question";

export const postLogout = (): [boolean , Promise<AxiosResponse>] => {
  const senderId = getSenderIdFromToken();
  const userLoginId = getUserLoginIdFromToken();
  const previewModeEnabled = getPreviewModeFromToken();
  const arcBaseUrl = process.env.REACT_APP_API_BASE_URL ? `${process.env.REACT_APP_API_BASE_URL}`.replace('/gateway', '') : 'http://localhost:3000';

  getJson(`${arcBaseUrl}/users/logout`);

  return [previewModeEnabled, deleteJson(`${USERS_BASE_URL}/${senderId}/login/${userLoginId}`) ];
};

export const registerUser = (req: IUserRegistrationReq): Promise<AxiosResponse> => {
  const userId = getUserIdFromToken();
  const userLoginId = getUserLoginIdFromToken();
  req.user_id = userId;
  req.login_id = userLoginId;

  return postJson(`${USER_BASE_URL}/register`, req);
};

export const getSecurityQuestions = (): Promise<AxiosResponse> => {
  return getJson(`${SENDERS_BASE_URL}/register/security_questions`);
};

export const getSecurityQuestion = (): Promise<AxiosResponse> => {
  const userId = getUserIdFromToken();
  const req = { user_id: userId };

  return postJson(QUESTIONS_BASE_URL, req);
};

export const verifySecurityAnswer = (req: IAnswerReq): Promise<AxiosResponse> => {
  const userId = getUserIdFromToken();
  req.user_id = userId;

  return postJson(`${QUESTIONS_BASE_URL}/validate`, req);
};

export const verifyKnownBrowser = (req: IKnownBrowserReq): Promise<AxiosResponse> => {
  const senderId = getSenderIdFromToken();
  const userId = getUserIdFromToken();
  req.user_id = userId;
  req.supplier_id = senderId;

  return postJson(`${USER_BASE_URL}/${userId}/verify`, req);
};

export const resetPassword = (email: string): Promise<AxiosResponse> => {
  const req = { email: email };

  return postJson(`${USER_BASE_URL}/password/reset`, req);
};

export const getUserAccountToken = (): Promise<AxiosResponse> => {
  const userId = getUserIdFromToken();

  const senderId = getSenderIdFromToken();

  return postJson(`${USER_BASE_URL}/${userId}/sender/${senderId}/identifiertoken`, {});
};

export const updatePassword = (req: IUpdatePasswordReq): Promise<AxiosResponse> => {
  return putJson(`${USER_BASE_URL}/password/update`, req);
};

export const getProfile = (): Promise<AxiosResponse> => {
  const userId = getUserIdFromToken();

  return getJson(`${USER_BASE_URL}/${userId}`);
};

export const updateProfile = (req: IUserProfileReq): Promise<AxiosResponse> => {
  return putJson(`${USER_BASE_URL}/profile`, req);
};

export const updateQAs = (qa: ISecurityQAReq): Promise<AxiosResponse> => {
  const userId = getUserIdFromToken();
  const req = { user_id: userId, qa: qa };

  return putJson(`${USER_BASE_URL}/profile/security_question`, req);
};

export const resetQuestions = (email: string): Promise<AxiosResponse> => {
  const req = { email: email };

  return postJson(`${SENDERS_BASE_URL}/reset/security_question`, req);
};
