import React, { Component } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { Grid } from "@material-ui/core";
import FormDialog from "../../../common/components/Dialog";
import InvoiceCell from "../InvoiceCell";

import { centsToDisplay, formatNormalDate, getUpperName } from "../../../common/lib/helpers";

type Props = {
  jsonData: any;
  onConfirm: () => void;
  onClose: () => void;
};
type State = {
  jsonData: any;
};

const existsOrNa = (key: any, value: any) => key ? value : "N/A";
const selfExistsOrNa = (value: any) => value ? value : "N/A";

const FormBody = styled.div<DefaultThemeProps>`
  width: 800px;
  text-align: left;
  padding: 20px 30px;
`;

const StyledNameTypeWrapper = styled.div`
  color: ${props => props.theme.secondaryText};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
StyledNameTypeWrapper.displayName = "StyledNameTypeWrapper";

const GreenPaid = styled.h1`
  color: green;
  text-decoration: bold;
  font-weight: 500;
  font-size: 18px;
`;
GreenPaid.displayName = "GreenPaid";

const RedPaid = styled.h1`
  color: red;
  text-decoration: bold;
  font-weight: 500;
  font-size: 18px;
`;
RedPaid.displayName = "RedPaid";

const StyledAmountStatus = styled.h1`
  font-weight: 500;
  font-size: 18px;
`;
StyledAmountStatus.displayName = "StyledAmountStatus";

export default class PaymentListingProcessModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state.jsonData = this.props.jsonData;
  }

  state: State = {
    jsonData: this.props.jsonData
  };

  renderPaymentStatusCell(status: string) {
    switch (status) {
      case "paid":
        return (
          <div className="secondary">
            <GreenPaid>Paid</GreenPaid>
          </div>
        );
      case "error":
      case "error_declined":
      case "error_nsf":
      case "reversal":
        return (
          <div className="secondary">
            <RedPaid>{getUpperName(status)}</RedPaid>
          </div>
        );
      case null:
      case undefined:
        return undefined;
      default:
        return (
          <div className="secondary">
            <StyledAmountStatus>{getUpperName(status)}</StyledAmountStatus>
          </div>
        );
    }
  }

  render() {
    return (
      <FormDialog
        title="PAYMENT DETAILS"
        type="Close"
        format="confirmation"
        maxWidth="xs"
        onConfirm={this.props.onConfirm}
        noBodyMargin
        onClose={this.props.onClose}
      >
        <FormBody>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <StyledNameTypeWrapper>
                Payment Amount
              </StyledNameTypeWrapper>
              <StyledAmountStatus>
                {centsToDisplay(this.state.jsonData.amount_cents, this.state.jsonData.currency)}
              </StyledAmountStatus>
            </Grid>
            <Grid item xs={6}>
              <StyledNameTypeWrapper>
                Payment Status
              </StyledNameTypeWrapper>
              {this.renderPaymentStatusCell(this.state.jsonData.status)}
            </Grid>
            <Grid item xs={6}>
              <StyledNameTypeWrapper>
                Customer Name
              </StyledNameTypeWrapper>
              {selfExistsOrNa(this.state.jsonData.payor_name)}
            </Grid>
            <Grid item xs={6}>
              <StyledNameTypeWrapper>
                Processed Date
              </StyledNameTypeWrapper>
              {existsOrNa(this.state.jsonData.transaction, formatNormalDate(this.state.jsonData.updated_at ? this.state.jsonData.updated_at : 0))}
            </Grid>
            <Grid item xs={6}>
              <StyledNameTypeWrapper>
                Invoice #
              </StyledNameTypeWrapper>
              <InvoiceCell record={this.state.jsonData} isShowing={false} />
            </Grid>
            <Grid item xs={6}>
              <StyledNameTypeWrapper>
                Payment #
              </StyledNameTypeWrapper>
              {selfExistsOrNa(this.state.jsonData.display_identifier)}
            </Grid>
            <Grid item xs={6}>
              <StyledNameTypeWrapper>
                Transaction Token
              </StyledNameTypeWrapper>
              {this.state.jsonData.transaction ? this.state.jsonData.transaction.token : "N/A"}
            </Grid>
            <Grid item xs={6}>
              <StyledNameTypeWrapper>
                Authorization Code
              </StyledNameTypeWrapper>
              {existsOrNa(this.state.jsonData.transaction, selfExistsOrNa(this.state.jsonData.credit_card_gateway_request ? this.state.jsonData.credit_card_gateway_request.authorization_token : null))}
            </Grid>
            <Grid item xs={12}>
              <StyledNameTypeWrapper>
                Internal Payment Reference #
              </StyledNameTypeWrapper>
              {existsOrNa(this.state.jsonData.transaction, selfExistsOrNa(this.state.jsonData.identifier))}
            </Grid>
          </Grid>
        </FormBody>
      </FormDialog>
    );
  }
}
