import React, { Component, Fragment } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import FullPageMessage from "../../common/components/PageLayout/FullPageMessage";
import StyledNavLink from "../../common/components/StyledNavLink";
import StyledAnchor from "../../common/components/StyledAnchor";
import Close from "@material-ui/icons/Close";

const StyledClose = styled(Close)<DefaultThemeProps>`
  margin-top: 17px;
  width: 100px;
  height: 100px;
  color: ${props => props.theme.providedColor};
  filter: drop-shadow(0px 3px 10px ${props => props.theme.shadowColor});
`;

export class NotFoundPage extends Component {
  navButtons() {
    return (
      <Fragment>
        <StyledAnchor
          minwidth="170px"
          id="email_link"
          href="mailto:support@versapay.com"
        >
          <span>Email Us</span>
        </StyledAnchor>
        <StyledNavLink
          minwidth="170px"
          id="payments_link"
          to="/enablement/payments"
          exact
        >
          <span>Go Back</span>
        </StyledNavLink>
      </Fragment>
    );
  }

  render() {
    return (
      <FullPageMessage
        icon={<StyledClose viewBox="5 5 14 14" />}
        messages={[
          "Page not found.",
          "If you typed this address in, please double check it. If you’ve followed a link please contact support@versapay.com"
        ]}
        padding="30px 29px 0"
      >
        {this.navButtons()}
      </FullPageMessage>
    );
  }
}

export default NotFoundPage;
