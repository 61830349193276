import {
  postJson,
  getUserFromToken
} from "../lib/helpers";
import { AxiosResponse } from "axios";

const SENDERS_BASE_URL = "/managesenders/senders/get-or-create-sender";

export const getSender = (): Promise<AxiosResponse> => {
  const userProfile = getUserFromToken();

  return postJson(`${SENDERS_BASE_URL}`, userProfile);
};
