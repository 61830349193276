import React from "react";
import { withTheme, DefaultThemeProps } from "styled-components";

const EmailIcon = ({ theme }: DefaultThemeProps) => {
  return (
    <svg
      width="100"
      height="80"
      viewBox="0 0 100 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M90 0H10C4.5 0 0.05 4.5 0.05 10L0 70C0 75.5 4.5 80 10 80H90C95.5 80 100 75.5 100 70V10C100 4.5 95.5 0 90 0ZM90 20L50 45L10 20V10L50 35L90 10V20Z"
        fill={theme.borderColor}
      />
    </svg>
  );
};

export default withTheme(EmailIcon);
