import React, { useState } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { Search } from "@material-ui/icons/";

type SearchBoxProps = {
  id?: string;
  value?: string;
  placeholder?: string;
  activeSearch: boolean;
  handleSearch(value: string): void;
};

const StyledSearchBoxInput = styled.input<DefaultThemeProps>`
  padding: 0 6px 0 0;
  width: 258px;
  background: transparent;
  font-size: 18px;
  border: none;
  color: ${props => props.theme.primaryText};

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${props => props.theme.tertiaryText};
    opacity: 1;
    font-size: 18px;
    font-weight: 300;
  }
`;
StyledSearchBoxInput.displayName = "StyledSearchBoxInput";

const StyledSearchBox = styled.div<SearchBoxProps>`
  display: inline-flex;
  align-items: center;
  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 2px 6px ${props => props.theme.shadowColor};
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 16px;
  height: 50px;
  svg {
    cursor: pointer;
    font-size: 27px;
    color: ${props =>
      props.activeSearch
        ? props.theme.providedColor
        : props.theme.secondaryText};
  }
`;
StyledSearchBox.displayName = "StyledSearchBox";

export default function Searchbox({
  id,
  value,
  placeholder,
  activeSearch,
  handleSearch
}: SearchBoxProps) {
  const [search, setSearch] = useState(value ? value : "");

  function onChangeInputSearchbox(event: React.ChangeEvent<HTMLInputElement>) {
    setSearch(event.currentTarget.value);
  }

  function onClickHandleCallback() {
    handleSearch && handleSearch(search);
  }

  function onClickHandle() {
    onClickHandleCallback();
  }

  function handleEnterKey(event: React.KeyboardEvent) {
    if (event.key === "Enter") {
      onClickHandleCallback();
    }
  }

  return (
    <StyledSearchBox
      id={id}
      activeSearch={activeSearch}
      handleSearch={handleSearch}
    >
      <StyledSearchBoxInput
        id={id && `${id}_input`}
        value={search}
        onChange={onChangeInputSearchbox}
        onKeyDown={handleEnterKey}
        placeholder={placeholder || "Search"}
      />
      <Search id={id && `${id}_icon`} onClick={onClickHandle} />
    </StyledSearchBox>
  );
}
