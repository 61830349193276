import React, { Component, Fragment } from "react";
import styled, { ThemeProvider, DefaultThemeProps } from "styled-components";
import { RouteComponentProps } from "react-router";
import { withRouter, NavLink } from "react-router-dom";
import { StylesProvider } from "@material-ui/styles";
import DownArrow from "@material-ui/icons/KeyboardArrowDown";
import {clearSession, createTheme, GlobalStyle} from "../../lib/helpers";
import { postLogout } from "../../../enablement/services/userServices";
import { IPageLayoutProps, INavigationItem } from "../../types";
import Popover from "../Popover";
import ProfileIcon from "../ProfileIcon";
import { getBrandColor } from "../../lib/helpers";
import { DEFAULT_BRANDING_COLOR } from "../../lib/constants";
import { History } from "history";

import {
  authenticated,
  getPreviewModeFromToken,
  getVerificationOfTC,
  standardPromiseCatch
} from "../../../enablement/lib/helpers";

type Props = {
  pageLayoutProps?: IPageLayoutProps;
  title?: string;
  backTo?: string;
  fixedContent?(): React.ReactNode;
  scrollableContent?(): React.ReactNode;
  overlayContent?(): React.ReactNode;
  noNavLinks?: boolean;
  noFooter?: boolean;
  history: History;
} & RouteComponentProps;

type State = {} & DefaultThemeProps;

const ApplicationWrapper = styled.div<DefaultThemeProps>`
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
`;

const StyledFooter = styled.div`
  padding-top: 10px;
  font-size: 10px;
  color: ${props => props.theme.secondaryText};
`;
StyledFooter.displayName = "StyledFooter";

const FixedContent = styled.div<DefaultThemeProps>`
  flex 0 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  header, nav {
    display: flex;
    align-items: center;

    width: 1230px;
    padding: 10px;
  }

  header {
    justify-content: space-between;
  }

  header > div {
    display: flex;
    align-items: center;
  }

  header > div.logo {
    img {
      margin-right: 15px;
    }
    font-size: 22px;
  }

  div.divider {
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }

  background: ${props => props.theme.solidBackground};
  border: 1px solid ${props => props.theme.borderColor};
  box-sizing: border-box;
  box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};

  header #application_title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
  }
`;
FixedContent.displayName = "FixedContent";


const StyledPortalLink = styled.a`
  display: inline-block;
  padding: 10px;
  background: ${props => props.theme.providedColor};
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
  color: ${props => props.theme.solidBackground};
  text-decoration: bold;
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  position: relative;
  margin: 15px;

  &:visited, &:hover {
    color: ${props => props.theme.solidBackground};
  }
`;
StyledPortalLink.displayName = "StyledPortalLink";

const ScrollableContent = styled.main`
  padding-top: 30px;
  flex 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 1230px;
    padding-left: 15px;
    padding-right: 15px;
  }

  overflow-y: scroll;
  .scrollPad {
    height: 30px;
  }
`;

const NavigationLink = styled(NavLink)<DefaultThemeProps>`
  display: inline-flex;
  align-items: center;
  color: ${props => props.theme.secondaryText};
  margin-right: 60px;
  height: 59px;

  :hover {
    color: ${props => props.theme.secondaryText};
  }

  &.active {
    color: ${props => props.theme.providedColor};
  }

  > .title {
    font-weight: 500;
    font-size: 16px;
    padding-left: 10px;
  }
`;
NavigationLink.displayName = "NavigationLink";

const UserMenuTrigger = styled.div<DefaultThemeProps>`
  margin-left: 10px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${props => props.theme.secondaryText};
  font-size: 12px;
  font-weight: 500;
  span {
    margin-right: 5px;
  }
  svg {
    font-size: 20px;
  }
`;
UserMenuTrigger.displayName = "UserMenuTrigger";

export class PageLayout extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.pageContent = this.pageContent.bind(this);
    this.onClickLogout = this.onClickLogout.bind(this);
    this.onClickUserProfile = this.onClickUserProfile.bind(this);
    this.performLogout = this.performLogout.bind(this);
  }

  state = {
    theme: createTheme(getBrandColor() || DEFAULT_BRANDING_COLOR)
  };

  componentDidMount() {
    if (authenticated()) {
      getVerificationOfTC()
        .then(resp => {
          if (
            this.props.history.location.pathname === "/enablement/terms" &&
            resp.status !== 201
          ) {
            this.props.history.replace("/enablement/payments");
          }
          if (
            resp.status === 201 &&
            this.props.history.location.pathname !== "/enablement/error"
          ) {
            this.props.history.replace("/enablement/terms");
          }
        })
        .catch(standardPromiseCatch(this.props.history));
    }
  }

  onClickLogout() {
    let [previewModeEnabled] = postLogout();
    this.performLogout(previewModeEnabled);
  }

  onClickUserProfile() {
    this.props.history.replace("/enablement/users/profile");
  }

  performLogout(previewModeEnabled: boolean) {
      clearSession();
      previewModeEnabled ? this.props.history.push("/enablement/signout") : this.props.history.push("/enablement/login");
  }

  pageContent() {
    if (!this.props.pageLayoutProps) return;
    const {
      applicationLogo,
      applicationTitle,
      applicationCopyright,
      applicationLink,
      user,
      navigation
    } = this.props.pageLayoutProps;
    const popoverActions = [
      {
        text: "My Profile",
        clickHandler: () => {
          this.onClickUserProfile();
        }
      },
      {
        text: "Sign Out",
        negative: true,
        clickHandler: () => {
          this.onClickLogout();
        }
      }
    ].slice(getPreviewModeFromToken() ? 1 : 0, 2);

    return (
      <ContentWrapper>
        <FixedContent>
          <header>
            <div className="logo">
              <img
                id="application_logo"
                src={applicationLogo}
                alt={applicationTitle}
              />
              <span id="application_title">{applicationTitle}</span>
            </div>
            {!this.props.noNavLinks && (
            <div>
              <Fragment>
               {applicationLink && (<StyledPortalLink href={applicationLink}>Manage Payments</StyledPortalLink>)}
              </Fragment>
              <ProfileIcon />
              <Popover
                trigger={
                  <UserMenuTrigger id="application_user_menu">
                    <span>{user.name || user.email}</span>
                    <DownArrow />
                  </UserMenuTrigger>
                }
                actions={popoverActions}
              />
            </div>
            )}
          </header>
          <div className="divider" />
          {!this.props.noNavLinks && (
            <nav>{this.navigationLinks(navigation)}</nav>
          )}
        </FixedContent>
        <ScrollableContent>
          <div>
            {this.props.scrollableContent && (
              <Fragment>
                {this.props.scrollableContent()}
                {!this.props.noFooter && (
                  <StyledFooter>
                    <span id="application_footer">
                      Powered by &copy; {applicationCopyright}
                    </span>
                  </StyledFooter>
                )}
                <div className="scrollPad">&nbsp;</div>
              </Fragment>
            )}
          </div>
        </ScrollableContent>
        {this.props.overlayContent && this.props.overlayContent()}
      </ContentWrapper>
    );
  }

  navigationLinks(navigation: ReadonlyArray<INavigationItem>) {
    return navigation.map((config, idx) => (
      <NavigationLink id={`nav-${idx}`} key={`nav-${idx}`} to={config.linkTo}>
        <span className="icon">{config.icon}</span>
        <span className="title">{config.title}</span>
      </NavigationLink>
    ));
  }

  render() {
    return (
      <StylesProvider injectFirst>
        <ThemeProvider theme={this.state.theme}>
          <ApplicationWrapper>
            <GlobalStyle />
            {this.props.pageLayoutProps
              ? this.pageContent()
              : this.props.children}
          </ApplicationWrapper>
        </ThemeProvider>
      </StylesProvider>
    );
  }
}

export default withRouter(PageLayout);
