import React from "react";
import styled from "styled-components";
import Tooltip from "@material-ui/core/Tooltip";

import { TooltipType } from "../types";

type ToolTipProps = {
  text: string;
  toolTipText?: string;
  link: string;
  placement?: TooltipType;
};

const RedPaid = styled.h4`
  color: red;
  text-decoration: bold;
`;
RedPaid.displayName = "RedPaid";

const StyledCCNumber = styled.a`
  :hover,
  :focus {
    color: ${props => props.theme.activeLinkText};
  }
`;
StyledCCNumber.displayName = "StyledCCNumber";

const StyledTooltip = styled(props => (
  <Tooltip
    classes={{ popper: props.className, tooltip: "tooltip" }}
    {...props}
  />
))`
  & .tooltip {
    display: flex;
    padding: 10px;
    color: ${props => props.theme.primaryText};
    background: ${props => props.theme.solidBackground};

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    width: 256px;
    min-height: 50px;

    box-sizing: border-box;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    border: 1px solid ${props => props.theme.borderColor};
  }
`;
StyledTooltip.displayName = "StyledTooltip";

export default function HoverLink({
  text,
  toolTipText,
  link,
  placement
}: ToolTipProps) {
  const defaultText =
    "Click on this link in order to access the Credit Card information for this payment";
  const defaultPlacement = "right";

  return (
    <React.Fragment>
      <StyledTooltip
        className={"tooltip"}
        title={toolTipText || defaultText}
        placement={placement || defaultPlacement}
      >
        {link === "" ? (
          <RedPaid>{text}</RedPaid>
        ) : (
          <StyledCCNumber
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            data-card-number
            aria-haspopup="true"
          >
            {text}
          </StyledCCNumber>
        )}
      </StyledTooltip>
    </React.Fragment>
  );
}
