import React, { memo, useState } from "react";
import Dialog from "../../../common/components/Dialog";
import Dropdown from "../../../common/components/Dropdown";
import Input from "../../../common/components/Input";
import styled from "styled-components";
import { IDropdownItem, IUsersFormat } from "../../../common/types";

import moment from "moment";

const EditWrapper = styled.div`
  display: flex;
  height: 381px;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 40px 60px 10px 60px;
  max-width: 300px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
EditWrapper.displayName = "EditWrapper";

const EmailWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
EmailWrapper.displayName = "EmailWrapper";

const UsersDialogRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
UsersDialogRowWrapper.displayName = "UsersDialogRowWrapper";

type UsersDialogProps = {
  usersData?: any;
  onClose: () => void;
  onConfirm: (details: IUsersFormat) => void;
};

function UsersDialog({ usersData, onClose, onConfirm }: UsersDialogProps) {
  let name = usersData.user_name.split(" ");

  const [email] = useState(usersData.email as string);
  const [firstName, setFirstName] = useState(name[0] as string);
  const [lastName, setLastName] = useState(name[1] as string);
  const [userType, setUserType] = useState(usersData.user_type as string);
  const [title, setTitle] = useState(usersData.title as string);
  const [titleDetails, setTitleDetails] = useState(
    usersData.title_details as string
  );
  const [phoneNumber, setPhoneNumber] = useState(
    usersData.phone_number as string
  );
  const [id] = useState(usersData.id as number);
  const [timeZone, setTimeZone] = useState(usersData.time_zone as number);

  function handleFirstNameChange(event: React.ChangeEvent<{ value: string }>) {
    setFirstName(event.target.value as string);
  }
  function handleLastNameChange(event: React.ChangeEvent<{ value: string }>) {
    setLastName(event.target.value as string);
  }
  function handleTitleChange(event: React.ChangeEvent<{ value: string }>) {
    setTitle(event.target.value as string);
  }
  function handleTitleDetailsChange(
    event: React.ChangeEvent<{ value: string }>
  ) {
    setTitleDetails(event.target.value as string);
  }
  function handlePhoneNumberChange(
    event: React.ChangeEvent<{ value: string }>
  ) {
    setPhoneNumber(event.target.value as string);
  }
  function handleTimeZoneChange(timeZone: IDropdownItem) {
    setTimeZone(timeZone.value as number);
  }
  function handleUserTypeChange(userType: IDropdownItem) {
    setUserType(userType.value as string);
  }

  return (
    <Dialog
      title="Edit User Details"
      type="Save"
      format="alert"
      noCancel
      onClose={onClose}
      onConfirm={() =>
        onConfirm({
          userType,
          firstName,
          lastName,
          phoneNumber,
          title,
          titleDetails,
          timeZone,
          id,
          email
        })
      }
      noBodyMargin
    >
      <EditWrapper>
        <EmailWrapper>{usersData.email}</EmailWrapper>
        <Dropdown
          title="Role"
          width={300}
          placeholder={userType}
          handleValue={handleUserTypeChange}
          list={[
            { title: `Admin`, value: `Admin` },
            { title: `User`, value: `User` }
          ]}
        />
        <UsersDialogRowWrapper>
          <Input
            header="First Name"
            width="140px"
            onChange={handleFirstNameChange}
            defaultValue={firstName}
          />
          <Input
            header="Last Name"
            width="140px"
            onChange={handleLastNameChange}
            defaultValue={lastName}
          />
        </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <Input
            header="Title"
            width="140px"
            onChange={handleTitleChange}
            defaultValue={title}
          />
          <Input
            header="Department"
            width="140px"
            onChange={handleTitleDetailsChange}
            defaultValue={titleDetails}
          />
        </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <Input
            header="Phone Number"
            width="140px"
            onChange={handlePhoneNumberChange}
            defaultValue={phoneNumber}
          />
          <Dropdown
            title="Time Zone"
            width={140}
            handleValue={handleTimeZoneChange}
            placeholder={moment(timeZone).format("Z z")}
            list={[
              {
                title: `-04:00`,
                value: 1554933169020
              },
              {
                title: `-03:58`,
                value: 1554933169040
              }
            ]}
          />
        </UsersDialogRowWrapper>
      </EditWrapper>
    </Dialog>
  );
}

export default memo(UsersDialog);
