import styled from "styled-components";
import Alert from '@material-ui/lab/Alert';

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 100%;
  text-decoration: bold;
  text-align: center;
  font-weight: 500;
  font-size: 17px;
  background: #D7373C;
  color: ${props => props.theme.activeText};
`;
StyledAlert.displayName = "StyledAlert";

const StyledSuccess = styled(StyledAlert)`
  background: green;
  text-align: left;
`;
StyledSuccess.displayName = "StyledSuccess";

export default StyledAlert;

export { StyledSuccess };
