import React, { Component } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import Input from "../../../common/components/Input";
import Dropdown from "../../../common/components/Dropdown";
import { Grid } from "@material-ui/core";
import AvailableFields from "./FormatModalAvailableFields";
import FormDialog from "../../../common/components/Dialog";
import SelectedFields from "./FormatModalSelectedFields";
import { IDropdownItem, IField, IIndexJsonData } from "../../../common/types";
import { createFormat } from "../../services/formatServices";
import { getSenderIdFromToken, toastErrorHandler } from "../../lib/helpers";
import { History } from "history";
import { cloneDeep } from "lodash";
import Toast from "../../../common/components/Toast";
import { FORMAT_MULTILINE } from "../../lib/constants";

type Props = {
  updateFormatsJson: Function;
  updateIsProcessingFormat: Function;
  formatsJson: IIndexJsonData | null;
  history: History;
  onConfirm: Function;
  onClose: () => void;
  formatDuplicate?: any;
  fields?: any;
  failFormat?: boolean;
};
type State = {
  formatName: string;
  formatDescription: string;
  defaultFields: IField[];
  fields: IField[];
  multiLineInvoice: boolean;
  duplicateFormatName: boolean;
};

const FormBody = styled.div<DefaultThemeProps>`
  width: 800px;
  text-align: left;
  padding: 20px 30px;
`;

const ChooseFieldsGuideline = styled.div<DefaultThemeProps>`
  margin: 20px 0;
  font-size: 14px;
`;
ChooseFieldsGuideline.displayName = "ChooseFieldsGuideline";

const StyledHelperText = styled.span`
  width: 240px;
  font-size: 12px;
  line-height: 150%;
  color: #96918b;
  margin-bottom: 5px;
`;
StyledHelperText.displayName = "StyledHelperText";

const StyledNameTypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;
StyledNameTypeWrapper.displayName = "StyledNameTypeWrapper";

export default class PaymentExportFormatModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.onClickResetFields = this.onClickResetFields.bind(this);
    this.onClickAddFormat = this.onClickAddFormat.bind(this);
    this.getDefaultFields = this.getDefaultFields.bind(this);
    this.renderDisabledButton = this.renderDisabledButton.bind(this);
    this.renderUpdatedFields = this.renderUpdatedFields.bind(this);
  }

  state: State = {
    formatName:
      (this.props.formatDuplicate && this.props.formatDuplicate.name) || "",
    formatDescription:
      (this.props.formatDuplicate && this.props.formatDuplicate.description) ||
      "",
    defaultFields: this.props.fields,
    fields: this.props.fields,
    multiLineInvoice:
      (this.props.formatDuplicate &&
        this.props.formatDuplicate.multi_line_invoice) ||
      false,
    duplicateFormatName: this.props.failFormat || false
  };

  getDefaultFields(): IField[] {
    return cloneDeep(this.state.defaultFields); // deep copy defaultFormatFields
  }

  onClickResetFields() {
    this.renderUpdatedFields(this.getDefaultFields());
    this.props.onClose();
  }

  renderDisabledButton() {
    let selectedFields = this.state.fields.filter(field => {
      return field.isSelected;
    });
    let haveEmptySelectedField =
      selectedFields.filter(field => {
        return !field.selectedAlias;
      }).length > 0;
    return (
      !this.state.formatName ||
      this.state.duplicateFormatName ||
      selectedFields.length === 0 ||
      haveEmptySelectedField
    ); // disable when don't have format name OR don't have selected field OR haveEmptySelectedField
  }
  updateState(key: string, value: string) {
    let object: any = {};
    object[key] = value;
    this.setState(Object.assign({}, this.state, object));
  }

  renderUpdatedFields(newFields: IField[]) {
    this.setState(Object.assign({}, this.state, { fields: newFields }));
  }

  multiLineInvoiceHelpText() {
    let { multiLineInvoice } = this.state;

    if (multiLineInvoice) {
      return (
        <StyledHelperText id="help_text_details">
          Each invoice paid (allocation) is shown as a separate line
        </StyledHelperText>
      );
    } else {
      return (
        <StyledHelperText id="help_text_header">
          One line per payment even if a payment covers multiple invoices
        </StyledHelperText>
      );
    }
  }

  onClickAddFormat(): Promise<any> {
    let {
      formatName,
      formatDescription,
      fields,
      multiLineInvoice
    } = this.state;
    return createFormat({
      supplier_id: getSenderIdFromToken(),
      name: formatName,
      description: formatDescription,
      multi_line_invoice: multiLineInvoice,
      columns: fields
        .filter(field => {
          return field.isSelected;
        })
        .map(field => {
          return {
            column_name: field.name,
            alias: field.selectedAlias,
            order: field.selectedOrder
          };
        })
    })
      .then(() => {
        Toast({ text: "Format Created" });
        this.props.updateIsProcessingFormat(true);
        this.props.onConfirm();
      })
      .catch(error => {
        if (error.response && error.response.status === 409) {
          this.setState({ duplicateFormatName: true });
        } else {
          this.props.updateIsProcessingFormat(true);
          toastErrorHandler(
            this.props.history,
            error.response.status,
            error.response.status.toString()
          );
        }
      });
  }

  render() {
    return (
      <FormDialog
        title="NEW PAYMENT EXPORT FORMAT"
        type="Save"
        format="confirmation"
        maxWidth={false}
        onConfirm={this.onClickAddFormat}
        isEnabled={!this.renderDisabledButton()}
        noBodyMargin
        onClose={this.onClickResetFields}
      >
        <FormBody>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <StyledNameTypeWrapper>
                <Input
                  isInvalid={this.state.duplicateFormatName}
                  defaultValue={this.state.formatName}
                  header="Name"
                  height="44px"
                  placeholder="Format Name"
                  id="payment_export_format_name"
                  isRequired={this.state.formatName === ""}
                  maxLength={255}
                  onChange={(event: React.ChangeEvent<{ value: string }>) => {
                    this.setState({
                      formatName: event.currentTarget.value,
                      duplicateFormatName: false
                    });
                  }}
                />
                <Dropdown
                  id="payment_export_multi_line_invoice"
                  title="Format Type"
                  height="44px"
                  initialValue={
                    this.state.multiLineInvoice
                      ? FORMAT_MULTILINE[1].title
                      : FORMAT_MULTILINE[0].title
                  }
                  list={FORMAT_MULTILINE}
                  handleValue={(multiLine: IDropdownItem) => {
                    this.setState({
                      multiLineInvoice:
                        (multiLine.title as string) !== "Payment Header (CSV)"
                    });
                  }}
                />
                {this.multiLineInvoiceHelpText()}
              </StyledNameTypeWrapper>
            </Grid>
            <Grid item xs={12}>
              <Input
                defaultValue={this.state.formatDescription}
                header="Description"
                placeholder="Enter your format’s description here..."
                id="payment_export_format_desc"
                height="44px"
                maxLength={255}
                onChange={(event: React.ChangeEvent<{ value: string }>) => {
                  this.setState({
                    formatDescription: event.currentTarget.value
                  });
                }}
              />
            </Grid>
          </Grid>
          <ChooseFieldsGuideline id="payment_export_format_guideline_text">
            <span>
              Choose any of the available fields below to add them to your
              payment export format.
            </span>
          </ChooseFieldsGuideline>
          <Grid container spacing={3}>
            <Grid item xs={5} id="available_field_wrapper">
              <AvailableFields
                fields={this.state.fields}
                updateFields={this.renderUpdatedFields}
              />
            </Grid>
            <Grid item xs={7} id="selected_field_wrapper">
              <SelectedFields
                fields={this.state.fields}
                updateFields={this.renderUpdatedFields}
              />
            </Grid>
          </Grid>
        </FormBody>
      </FormDialog>
    );
  }
}
