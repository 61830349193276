import React, { Component, Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled, { DefaultThemeProps } from "styled-components";
import { toUpper, findIndex } from "lodash";
import UsersDialog from "./UsersDialog";

import Dialog from "../../../common/components/Dialog";
import DataTable from "../../../common/components/DataTable";
import PageLayout from "../../../common/components/PageLayout/PageLayout";
import Toast from "../../../common/components/Toast";

import Users from "../../assets/usersListing.json";
import ProfileIcon from "../../../common/components/ProfileIcon";

import { pageLayoutProps } from "../../lib/helpers";

import {
  IIndexJsonData,
  IJsonRecord,
  IPopoverAction,
  IRowConfig,
  IUsersFormat
} from "../../../common/types.js";
import { ROW_LIMIT } from "../../lib/constants";

type PageProps = {} & RouteComponentProps;
type PageState = {
  jsonData: IIndexJsonData | null;
  windowStart: number;
  windowSize: number;
  isRemoveOpen: boolean;
  addUser: boolean;
  editDetails: any;
};

const ConfirmationWrapper = styled.div<DefaultThemeProps>`
  margin: 40px 50px 30px 50px;
  text-align: center;
  max-width: 320px;
  height: 98px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
ConfirmationWrapper.displayName = "ConfirmationWrapper";

const EditWrapper = styled.div`
  display: flex;
  height: 381px;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 40px 60px 10px 60px;
  max-width: 300px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
EditWrapper.displayName = "EditWrapper";

const ConfirmationHeaderText = styled.span`
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
  color: ${props => props.theme.primaryText};
`;
ConfirmationHeaderText.displayName = "ConfirmationHeaderText";

const ConfirmationSecondaryText = styled.span`
  font-size: 16px;
  font-weight: normal;
  color: ${props => props.theme.secondaryText};
`;
ConfirmationSecondaryText.displayName = "ConfirmationSecondaryText";

const SpacerDataTable = styled(DataTable)<DefaultThemeProps>`
  margin-top: 10px;
`;

const UserTypeCell = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  border-radius: 2px;
  padding: 2px 10px;
  color: ${props => props.theme.activeText};
  background: ${props => props.theme.primaryText};
`;

const UserTypeAdminCell = styled(UserTypeCell)`
  background: ${props => props.theme.providedColor};
`;

export class UsersListingPage extends Component<PageProps, PageState> {
  state: PageState = {
    jsonData: Users, // for now
    windowStart: 0,
    windowSize: ROW_LIMIT[0].value,
    isRemoveOpen: false,
    addUser: false,
    editDetails: null
  };

  iconCell = () => {
    return (
      <span>
        <ProfileIcon size="medium" />
      </span>
    );
  };

  userTypeCell = (record: IJsonRecord) => {
    const userType = record && record.user_type;

    if (userType === "Admin") {
      return <UserTypeAdminCell>{toUpper(userType)}</UserTypeAdminCell>;
    }

    return <UserTypeCell>{toUpper(userType)}</UserTypeCell>;
  };

  rowMenuOptions = (record: IJsonRecord): IPopoverAction[] => {
    return [
      {
        text: "Edit Details",
        // disabled: true,
        clickHandler: () => {
          this.setState({ editDetails: record });
        }
      },
      {
        text: "Remove",
        disabled: false,
        negative: true,
        clickHandler: () => {
          this.setState({ isRemoveOpen: true });
        }
      }
    ];
  };

  rowConfig = (): IRowConfig => {
    const emptySettings = {
      heading: {},
      width: ""
    };

    return {
      noColumnHeader: true,
      identifier: "users_information",
      options: [
        {
          heading: { text: "Icon" },
          width: "8.5%",
          attribute: "icon",
          align: "center",
          dataType: "cell",
          cardCell: this.iconCell
        },
        {
          heading: { text: "User" },
          width: "15.3%",
          dataType: "nested",
          nested: [
            {
              ...emptySettings,
              dataType: "text",
              attribute: "user_name",
              titleCase: true,
              className: "primary"
            },
            {
              ...emptySettings,
              attribute: "user_type",
              dataType: "cell",
              cardCell: this.userTypeCell
            }
          ]
        },
        {
          heading: { text: "User Information" },
          width: "25.5%",
          dataType: "nested",
          nested: [
            {
              ...emptySettings,
              dataType: "text",
              attribute: "email",
              className: "smaller"
            },
            {
              ...emptySettings,
              dataType: "text",
              attribute: "phone_number",
              className: "smaller"
            }
          ]
        },
        {
          heading: { text: "User Occupation" },
          width: "46.5%",
          dataType: "nested",
          nested: [
            {
              ...emptySettings,
              dataType: "text",
              attribute: "title",
              titleCase: true,
              className: "smaller"
            },
            {
              ...emptySettings,
              dataType: "text",
              attribute: "title_details",
              titleCase: true,
              className: "smaller"
            }
          ]
        },
        {
          heading: { text: "" },
          width: "4.2%",
          align: "center",
          dataType: "menu",
          menuOptions: this.rowMenuOptions
        }
      ]
    };
  };

  openNewUserDialog = () => {
    this.props.history.replace("/enablement/users/new_user");
  };

  renderUsersTables = () => {
    return (
      <SpacerDataTable
        header={{
          text: "Users",
          actions: [{ text: "Add User", clickHandler: this.openNewUserDialog }]
        }}
        json={this.state.jsonData}
        row={this.rowConfig()}
      />
    );
  };

  pageContent = () => {
    return <Fragment>{this.renderUsersTables()}</Fragment>;
  };

  editDialog = (
    onClose: () => void,
    onConfirm: (details: IUsersFormat) => void
  ) => {
    let { editDetails } = this.state;
    return (
      <UsersDialog
        usersData={editDetails}
        onClose={onClose}
        onConfirm={onConfirm}
      />
    );
  };

  removalConfirmation = (
    onClose: () => void,
    onConfirm: () => void,
    messages: string[]
  ) => {
    return (
      <Dialog
        title="Removal Confirmation"
        type="Remove"
        format="alert"
        onClose={onClose}
        onConfirm={onConfirm}
        noCancel
        button="Remove User"
        noBodyMargin
      >
        <ConfirmationWrapper>
          <ConfirmationHeaderText>{messages[0]}</ConfirmationHeaderText>
          <ConfirmationSecondaryText>{messages[1]}</ConfirmationSecondaryText>
        </ConfirmationWrapper>
      </Dialog>
    );
  };

  inviteConfirmation = (
    onClose: () => void,
    onConfirm: () => void,
    messages: string[]
  ) => {
    return (
      <Dialog
        title="Invite Sent"
        type="Save"
        format="alert"
        onClose={onClose}
        onConfirm={onConfirm}
        noCancel
        button="Ok"
        noBodyMargin
      >
        <ConfirmationWrapper>
          <ConfirmationHeaderText>{messages[0]}</ConfirmationHeaderText>
          <ConfirmationSecondaryText>{messages[1]}</ConfirmationSecondaryText>
        </ConfirmationWrapper>
      </Dialog>
    );
  };

  overlayContent = () => {
    const { isRemoveOpen, editDetails, jsonData } = this.state;
    let content = [
      "Are You Sure?",
      "When you remove a user, they will no longer be able to access your account."
    ];

    // let content2 = [
    //   "Your Request Has Been Sent!",
    //   "We’ve created a temporary account for you to store all your payments in. When your merge request has been accepted, all of your payment information will be combined with the account you selected."
    // ];

    if (isRemoveOpen) {
      return this.removalConfirmation(
        () => {
          this.setState({ isRemoveOpen: false });
        },
        () => {
          this.setState({ isRemoveOpen: false }, () => {
            alert("not possible my dude");
          });
        },
        content
      );
    }

    // if (isRemoveOpen) {
    //   return this.inviteConfirmation(
    //     () => {
    //       this.setState({ isRemoveOpen: false });
    //     },
    //     () => {
    //       this.setState({ isRemoveOpen: false }, () => {
    //         alert("not possible my dude");
    //       });
    //     },
    //     content2
    //   );
    // }

    if (editDetails) {
      return this.editDialog(
        () => {
          this.setState({ editDetails: null });
        },
        (details: IUsersFormat) => {
          if (jsonData) {
            let newUserInformationList = findIndex(jsonData.records, val => {
              return val.id === details.id;
            });

            jsonData.records.splice(newUserInformationList, 1, {
              supplier_id: 21,
              user_id: 1,
              id: details.id,
              user_name: `${details.firstName} ${details.lastName}`,
              user_type: details.userType,
              email: details.email,
              phone_number: details.phoneNumber,
              title: details.title,
              title_details: details.titleDetails,
              time_zone: details.timeZone
            });
            this.setState(
              Object.assign({}, this.state, { editDetails: null }),
              () => {
                Toast({ text: "Users Updated" });
              }
            );
          }
        }
      );
    }
  };

  render() {
    return (
      <PageLayout
        pageLayoutProps={pageLayoutProps()}
        scrollableContent={this.pageContent}
        overlayContent={this.overlayContent}
      />
    );
  }
}

export default withRouter(UsersListingPage);
