import {
  postJson,
  getSenderIdFromToken,
  deleteJson,
  mockDataPromise,
  getJson
} from "../lib/helpers";
import { AxiosResponse } from "axios";
import { USE_MOCK_JSON } from "../lib/constants";
import MOCK_SCHEDULE_DATA from "../assets/paymentExportSchedules.json";
import { IScheduleFormat } from "../../common/types";

const SCHEDULE_BASE_URL = "/manageexports/schedule";
const SCHEDULE_NEW_BASE_URL = `${SCHEDULE_BASE_URL}/sender`;

export const deleteSchedule = (id: number): Promise<AxiosResponse> => {
  return deleteJson(
    `${SCHEDULE_NEW_BASE_URL}/${getSenderIdFromToken()}/schedule/${id}`
  );
};

export const createSchedule = (options: IScheduleFormat) => {
  return postJson(SCHEDULE_BASE_URL, {
    supplier_id: getSenderIdFromToken(),
    format_id: options.selectedFormat,
    enabled: true,
    schedule: options.selectedFrequency!.toLowerCase(),
    is_sftp: options.usingSFTP,
    sftp_destination: options.SFTPDestination,
    sftp_username: options.SFTPUsername,
    sftp_password: options.SFTPPassword
  });
};

export const getSchedules = (): Promise<any> => {
  if (USE_MOCK_JSON) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mockDataPromise(MOCK_SCHEDULE_DATA));
      }, 750);
    });
  }
  return getJson(`${SCHEDULE_NEW_BASE_URL}/${getSenderIdFromToken()}`);
};

export const scheduleCheck = (id: number): Promise<AxiosResponse> => {
  return getJson(
    `${SCHEDULE_NEW_BASE_URL}/${getSenderIdFromToken()}/format/${id}`
  );
};

export const sftpCheck = (
  sftp_destination: string,
  username: string,
  password: string
): Promise<AxiosResponse> => {
  const checkSFTPJSON = {
    supplier_id: getSenderIdFromToken(),
    username: username,
    password: password,
    path_dir: "/tmp",
    host: sftp_destination,
    port: 22,
    tag: "BYTE",
    attachments: []
  };
  return postJson(`/managenotification/sftp/test`, checkSFTPJSON);
};
