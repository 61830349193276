import React from "react";
import axios, { AxiosResponse, AxiosInstance } from "axios";
import queryString from "query-string";
import JwtDecode from "jwt-decode";
import { merge } from 'lodash';
import styled from "styled-components";
import { BASE_URL } from "./constants";
import Payments from "@material-ui/icons/Payment";
import Exports from "@material-ui/icons/CloudDownload";
import MASTERCARD_LOGO from "../assets/mastercardSupplier.svg";
import VERSAPAY_LOGO from "../assets/versapayLogo.svg";
import { IPageLayoutProps, IUserProfile } from "../../common/types";
import {
  getToken,
  getBrandLogo,
  authorizationHeader,
  formUrlEncodedHeader
} from "../../common/lib/helpers";
import { History } from "history";
import Toast from "../../common/components/Toast";

const DEFAULT_BRANDING_LOGO_NAME: string = "versapay";

const StyledPayments = styled(Payments)`
  font-size: 31px;
`;

const StyledExports = styled(Exports)`
  font-size: 30px;
`;

/* TODO to be restored for multi user arc
import AccountCircle from "@material-ui/icons/AccountCircle";
const StyledAccountCircle = styled(AccountCircle)`
  font-size: 29px;
`;*/

const axiosInstance = (): AxiosInstance => {
  const instance = axios.create({
    baseURL: BASE_URL,
    headers: Object.assign({}, authorizationHeader())
  });
  return instance;
};

export const toastErrorHandler = (
  history: History,
  error: any,
  toastText?: string
): boolean => {
  if (unauthorizedErrorHandler(history, error)) {
    return false;
  }
  Toast({ text: toastText || "Action Failed", type: "error" });
  return true;
};

export const unauthorizedErrorHandler = (
  history: History,
  error: any
): boolean => {
  if (error.response && error.response.status === 401) {
    if (getPreviewModeFromToken()) {
      history.replace("/enablement/signout");
    } else {
      history.replace("/enablement/login");
    }
    return true;
  }
  return false;
};

export const toastPromiseCatch = (history: History, toastText?: string) => {
  return (error: any) => {
    toastErrorHandler(history, error, toastText);
  };
};

export const standardPromiseCatch = (history: History) => {
  return (error: any) => {
    if (unauthorizedErrorHandler(history, error)) return;

    const message = error.message || "An unexpected error has occurred";
    history.replace("/enablement/error", { message });
  };
};

export const getJson = (url: string): Promise<AxiosResponse> => {
  return axiosInstance().get(url);
};

export const postJson = (url: string, json: object): Promise<AxiosResponse> => {
  return axiosInstance().post(url, json);
};

export const putJson = (url: string, json: object): Promise<AxiosResponse> => {
  return axiosInstance().put(url, json);
};

export const deleteJson = (url: string): Promise<AxiosResponse> => {
  return axiosInstance().delete(url);
};

export const mockDataPromise = (data: object): Promise<any> => {
  return new Promise(resolve => {
    resolve({ data });
  });
};

export const objectLookup = (
  path: Array<string>,
  obj: { [index: string]: any }
): any => {
  if (path.length === 0) {
    return null;
  } else if (path.length === 1) {
    return obj[path[0]];
  } else {
    return objectLookup(path, obj[path.shift()!]);
  }
};

export const authenticateWithClaim = (token: string): Promise<any> => {
  const url = "/uaaservice/oauth2/token";

  const params = queryString.stringify({
    grant_type: "prime",
    client_id: "apex-cli",
    token
  });

  const options = {
    baseURL: BASE_URL,
    headers: formUrlEncodedHeader()
  };
  return axios.post(url, params, options);
};

export const authenticateWithPassword = (usernam: string, passwd: string): Promise<any> => {
  const url = "/uaaservice/oauth2/token";

  const params = queryString.stringify({
    grant_type: "prime",
    client_id: "apex-cli",
    username: usernam,
    password: passwd,
  });

  const options = {
    baseURL: BASE_URL,
    headers: formUrlEncodedHeader()
  };
  return axios.post(url, params, options);
};

export const authenticateWithLtoken = (ltok: string): Promise<any> => {
  const url = "/uaaservice/oauth2/token";

  const params = queryString.stringify({
    grant_type: "prime",
    client_id: "apex-cli",
    ltoken: ltok,
  });

  const options = {
    baseURL: BASE_URL,
    headers: formUrlEncodedHeader()
  };
  return axios.post(url, params, options);
};

const decodedToken = (): any => {
  const token = getToken();
  if (token) {
    try {
      return JwtDecode(token);
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
};

export const getTermsOfConditions = (): Promise<any> => {
  const url = "/managesenders/user/get-terms-and-conditions-by-email/text";
  const decoded = decodedToken();

  return postJson(url, { identifier: decoded.email });
};

export const getVerificationOfTC = (): Promise<any> => {
  const url = "/managesenders/user/get-terms-and-conditions-by-email";
  const decoded = decodedToken();

  return postJson(url, { identifier: decoded.email });
};

export const postVerificationOfTC = (): Promise<any> => {
  const url = "/managesenders/user/set-terms-and-conditions-by-email";
  const decoded = decodedToken();

  return postJson(url, { identifier: decoded.email });
};

export const getUserFromToken = (): IUserProfile => {
  const decoded = decodedToken();
  if (decoded) {
    const firstName = decoded["first_name"] || "";
    const lastName = decoded["last_name"] || "";
    return { email: decoded["email"], name: `${firstName} ${lastName}`.trim() };
  } else {
    return { email: "", name: "" };
  }
};

const getDataFromToken = (key: string, defaultValue: any): any => {
  const decoded = decodedToken();
  if (decoded) {
    return decoded[key];
  } else {
    return defaultValue;
  }
};

export let getPreviewModeFromToken = (): boolean => {
  return getDataFromToken("preview_mode", true) as boolean;
};

export const getSenderIdFromToken = (): number => {
  return getDataFromToken("sender_id", -1) as number;
};

export const getUserLoginIdFromToken = (): number => {
  return getDataFromToken("user_login_id", -1) as number;
};

export const getUserIdFromToken = (): number => {
  return getDataFromToken("sub", -1) as number;
};

export const getSenderNameFromToken = (): string => {
  return getDataFromToken("sender_name", "") as string;
};

export const getBrandColorFromToken = (): string => {
  return getKeyFromBrand("hex_color", "");
}

export const getBrandLogoFromToken = (): string => {
  return getKeyFromBrand("brand_code", "");
}

const getBrandFromToken = (): any => {
  return getDataFromToken("brand", {});
}

const getKeyFromBrand = (key: string, defaultValue: string): string => {
  const brand = getBrandFromToken();
  if (brand) {
    return brand[key];
  }
  return defaultValue;
}

export const authenticated = (): boolean => {
  const decoded = decodedToken();
  return decoded !== null;
};

export const pageLayoutProps = (userFullName?: string): IPageLayoutProps => {
  let applicationLogo, applicationTitle;
  let appLogoFetch = getBrandLogo() || DEFAULT_BRANDING_LOGO_NAME;

  if (appLogoFetch === "mastercard") {
    applicationLogo = MASTERCARD_LOGO;
    applicationTitle = "Virtual Card Receivables Service";
  } else {
    applicationLogo = VERSAPAY_LOGO;
    applicationTitle = "";
  }
  const loggedInUser = getUserFromToken()
  loggedInUser.name = userFullName != null ? userFullName : loggedInUser.name

  const copyrightYear = new Date().getFullYear();

  return {
    applicationTitle,
    applicationLogo,
    applicationCopyright: `${copyrightYear} VersaPay Corporation`,
    applicationLink: "",
    navigation: [
      {
        icon: <StyledPayments />,
        title: "Payments",
        linkTo: "/enablement/payments"
      },
      {
        icon: <StyledExports />,
        title: "Exports",
        linkTo: "/enablement/payment_exports"
      },
      /* TODO will be restored with the multi-user story arc {
        icon: <StyledAccountCircle />,
        title: "Users",
        linkTo: "/enablement/users/profile"
      }, */
    ],
    external: [
      { title: "Support" },
      { title: "Privacy Policy" },
      { title: "Terms Of Use" }
    ],
    user: loggedInUser
  };
};

/**
 * Remove the user id from local storage when the account is locked
 * delete the user id and set local storage again
 * @param {number} userId user id of the user
 */
export const removerUserFromStorage = (userId: number) => {
  const vpyUserIdentifiersString = localStorage.getItem('vpyUserIdentifiers');
  if (vpyUserIdentifiersString) {
    const vpyUserIdentifiers = JSON.parse(vpyUserIdentifiersString);
    if (vpyUserIdentifiers && vpyUserIdentifiers[userId]) {
      delete vpyUserIdentifiers[userId];
      localStorage.setItem('vpyUserIdentifiers', JSON.stringify(vpyUserIdentifiers));
    }
  }
};

/**
 * Check user_id in token is present in the local storage of the browser
 * @return {String} returns the identifier if the user_id is present in local storage, otherwise returns an empty string
 */
export const knownBrowser = (): string => {
  const userId = getUserIdFromToken();
  if (userId > -1) {
    const vpyUserIdentifiersString = localStorage.getItem('vpyUserIdentifiers');
    if (vpyUserIdentifiersString) {
      const vpyUserIdentifiers = JSON.parse(vpyUserIdentifiersString);
      const identifier = vpyUserIdentifiers[userId];
      if (vpyUserIdentifiers && identifier) {
        return identifier;
      }
    }
  }
  return '';
};

export const setKnownBrowser = (identifier: string) => {
  const userId = getUserIdFromToken();
  let stringified;
  if (localStorage.vpyUserIdentifiers) {
    stringified = JSON.stringify(merge(JSON.parse(localStorage.vpyUserIdentifiers), { [userId]: identifier }));
  } else {
    stringified = JSON.stringify({ [userId]: identifier });
  }
  localStorage.setItem('vpyUserIdentifiers', stringified);
};

export default {
  postJson,
  authenticateWithClaim,
  authenticateWithPassword,
  getPreviewModeFromToken,
  getSenderIdFromToken,
  getSenderNameFromToken,
  getUserFromToken,
  authenticated,
  pageLayoutProps,
  objectLookup
};
