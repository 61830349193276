import React from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { SortDirection } from "../types";

type Props = {
  direction: SortDirection;
  size?: number;
  className?: string;
};

type ArrowProps = {
  active: boolean;
  size: number;
} & DefaultThemeProps;

const UpArrow = styled.div<ArrowProps>`
  width: 0;
  height: 0;
  margin-bottom: 2px;
  border-left: ${props => props.size}px solid transparent;
  border-right: ${props => props.size}px solid transparent;
  border-bottom: ${props => props.size * 1.732}px solid
    ${props =>
      props.active ? props.theme.positiveText : props.theme.secondaryText};
`;
UpArrow.displayName = "UpArrow";

const DownArrow = styled.div<ArrowProps>`
  width: 0;
  height: 0;
  margin-top: 2px;
  border-left: ${props => props.size}px solid transparent;
  border-right: ${props => props.size}px solid transparent;
  border-top: ${props => props.size * 1.732}px solid
    ${props =>
      props.active ? props.theme.negativeText : props.theme.secondaryText};
`;
DownArrow.displayName = "DownArrow";

const SortWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
SortWrapper.displayName = "SortWrapper";

const DEFAULT_SIZE = 3;

const SortIndicator = ({
  direction,
  className,
  size = DEFAULT_SIZE
}: Props) => {
  return (
    <SortWrapper className={className}>
      <UpArrow size={size} active={direction === "asc"} />
      <DownArrow size={size} active={direction === "desc"} />
    </SortWrapper>
  );
};

export default SortIndicator;
