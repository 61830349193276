import React, { Component } from "react";

import FullPageMessage from "../../common/components/PageLayout/FullPageMessage";
import ProfileIcon from "../../common/components/ProfileIcon";
import { clearToken } from "../../common/lib/helpers";

export class SignoutPage extends Component {
  componentDidMount() {
    clearToken();
    sessionStorage.clear();
  }

  render() {
    return (
      <FullPageMessage
        icon={<ProfileIcon size="large" />}
        messages={[
          "Signed Out",
          "Please use the link in your payment email to sign back in."
        ]}
        padding="20px 30px 0"
      />
    );
  }
}

export default SignoutPage;
