import React, { ReactNode } from "react";
import styled, { DefaultThemeProps } from "styled-components";

type Props = {
  title: string;
  children: ReactNode;
};

const FieldBox = styled.div<DefaultThemeProps>`
  border: 1px solid ${props => props.theme.borderColor};
  box-sizing: border-box;
  box-shadow: 0px 2px 6px ${props => props.theme.shadowColor};
  border-radius: 3px;
`;
FieldBox.displayName = "FieldBox";

const FieldBoxTitle = styled.div<DefaultThemeProps>`
  padding: 7px 19px;
  width: 100%;
  height: 34px;
  background: ${props => props.theme.titleBackground};
`;
FieldBoxTitle.displayName = "FieldBoxTitle";

const FieldBoxTitleSpan = styled.div<DefaultThemeProps>`
  font-weight: 600;
  font-size: 11px;
  color: ${props => props.theme.secondaryText};
  text-transform: uppercase;
`;
FieldBoxTitleSpan.displayName = "FieldBoxTitleSpan";

const FieldBoxBody = styled.div`
  height: 416px;
`;
FieldBoxBody.displayName = "FieldBoxBody";

const FieldBoxComponent = (props: Props) => {
  return (
    <FieldBox>
      <FieldBoxTitle>
        <FieldBoxTitleSpan>{props.title}</FieldBoxTitleSpan>
      </FieldBoxTitle>
      <FieldBoxBody>{props.children}</FieldBoxBody>
    </FieldBox>
  );
};

export default FieldBoxComponent;
