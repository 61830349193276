import React, { Component } from "react";
import { RouteComponentProps } from "react-router";

import LoadingSpinner from "../../common/components/LoadingSpinner/LoadingSpinner";
import { PageLayout } from "../../common/components/PageLayout/PageLayout";
import {
  setToken,
  setBrandColor,
  setBrandLogo
} from "../../common/lib/helpers";
import {
  authenticateWithClaim,
  authenticateWithLtoken,
  getVerificationOfTC,
  standardPromiseCatch,
  getBrandColorFromToken,
  getBrandLogoFromToken
} from "../lib/helpers";

interface MatchParams {
  token: string;
}

type Props = {} & RouteComponentProps<MatchParams>;

export class Authorize extends Component<Props> {
  constructor(props: Props) {
    super(props);
    this.useQuery = this.useQuery.bind(this);
  }

  useQuery(): URLSearchParams {
    return new URLSearchParams(this.props.location.search);
  }

  authenticate(pathname: string, token: string): Promise<any> {
    if (pathname.includes("/ltoken/")) {
      return authenticateWithLtoken(token);
    } else {
      return authenticateWithClaim(token);
    }
  }

  componentDidMount() {
    this.authenticate(this.props.location.pathname, this.props.match.params.token)
      .then(response => {
        setToken(response.data.access_token);
        if (this.useQuery().get('reset') === "true") {
          sessionStorage.showSuccess = "2";
        } else if (this.useQuery().get('unlock') === "true") {
          sessionStorage.showSuccess = "1";
        }
        setBrandColor(getBrandColorFromToken())
        setBrandLogo(getBrandLogoFromToken());
        getVerificationOfTC()
          .then(resp => {
            if (resp.status === 201) {
              this.props.history.replace("/enablement/terms");
            } else {
              this.props.history.replace("/enablement/payments");
            }
          })
          .catch(standardPromiseCatch(this.props.history));
      })
      .catch(error => {
        if (error && error.response && error.response.status && error.response.status === 401) {
          if (this.useQuery().get('reset') === "true") {
            sessionStorage.showSuccess = "1";
          }
          setBrandColor(error.response.data.hex_color);
          setBrandLogo(error.response.data.brand_code);
          this.props.history.replace("/enablement/login");
        } else {
          this.props.history.replace("/enablement/signout");
        }
      });
  }

  render() {
    return (
      <PageLayout {...this.props}>
        <LoadingSpinner size="full" />
      </PageLayout>
    );
  }
}

export default Authorize;
