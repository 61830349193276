import React, { PureComponent } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { pick } from "lodash";
import { IFilterPanelState, IFilterCheckboxGroup } from "../types";
import Checkbox from "./Checkbox";

const FilterCheckbox = styled.div<DefaultThemeProps>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  label {
    cursor: pointer;
    color: ${props => props.theme.secondaryText};
    margin-bottom: 0;
    margin-left: 10px;
  }
`;
FilterCheckbox.displayName = "FilterCheckbox";

const Checkboxes = styled.div<DefaultThemeProps>`
  display: flex;
  justify-content: center;
  ${FilterCheckbox} {
    margin-right: 20px;
  }
  ${FilterCheckbox}:last {
    margin-right: 0;
  }
`;
Checkboxes.displayName = "Checkboxes";

type Props = {
  filterState: IFilterPanelState;
  filterGroup: IFilterCheckboxGroup;
  applyFilters: (filters: IFilterPanelState) => void;
};

export default class FilterCheckboxes extends PureComponent<
  Props,
  IFilterPanelState
> {
  state = pick(
    this.props.filterState,
    this.props.filterGroup.items.map(item => item.key)
  );

  onClickToggleCheckbox = (key: string): void => {
    this.setState(
      Object.assign({}, this.state, { [key]: !Boolean(this.state[key]) }),
      () => {
        this.props.applyFilters(
          Object.assign({}, this.props.filterState, this.state)
        );
      }
    );
  };

  renderCheckbox = (
    text: string,
    key: string,
    active: boolean
  ): React.ReactNode => {
    const id = `filter_checkbox_option_${this.props.filterGroup.key}_${key}`;
    return (
      <FilterCheckbox
        key={id}
        id={id}
        data-selected={active}
        onClick={() => this.onClickToggleCheckbox(key)}
      >
        <Checkbox checked={active} />
        <label>{text}</label>
      </FilterCheckbox>
    );
  };

  render() {
    return (
      <Checkboxes>
        {this.props.filterGroup.items.map(filterOption =>
          this.renderCheckbox(filterOption.text, filterOption.key, this.state[
            filterOption.key
          ] as boolean)
        )}
      </Checkboxes>
    );
  }
}
