import React, { Component, Fragment } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import PageLayout from "../../../common/components/PageLayout/PageLayout";
import StyledButton from "../../../common/components/StyledButton";
import Checkbox from "../../../common/components/Checkbox";
import Dropdown from "../../../common/components/Dropdown";
import Input from "../../../common/components/Input";
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import CloseIcon from '@material-ui/icons/Close';
import {
  getSecurityQuestions,
  getUserAccountToken,
  registerUser,
} from "../../services/userServices";

import {
  setToken,
  setBrandColor,
  setBrandLogo
} from "../../../common/lib/helpers";
import {
  authenticateWithPassword,
  getBrandColorFromToken,
  getBrandLogoFromToken,
  getUserFromToken,
  getUserIdFromToken,
  pageLayoutProps,
  setKnownBrowser,
} from "../../lib/helpers";
import { REG_EXP } from "../../../common/lib/constants";
import {
  IDropdownItem,
  IUserRegistrationReq
} from "../../../common/types";
import { History } from "history";

const StyledFooter = styled.div`
  position: absolute;
  left: 15px;
  bottom: 0;
  padding-top: 10px;
  font-size: 10px;
  color: ${props => props.theme.secondaryText};
`;
StyledFooter.displayName = "StyledFooter";

const StyledNext = styled(StyledButton)`
  padding: 12px 67px;
`;
StyledNext.displayName = "StyledNext";

const ErrorXButton = styled(IconButton)`
  background: transparent;
  color: ${props => props.theme.providedColor};
  cursor: not-allowed;
  pointer-events: none;
  transform: scale(5);
`;
ErrorXButton.displayName = "ErrorXButton";

const StyledInvisible = styled(KeyboardArrowLeft)`
  display: inline-flex;
  position: relative;
  width: 10%;
  color: white;
`;
StyledInvisible.displayName = "StyledInvisible";

const StyledMidInvisible = styled(StyledInvisible)`
  width: 25%;
`;
StyledMidInvisible.displayName = "StyledMidInvisible";

const StyledQuestionMidInvisible = styled(StyledInvisible)`
  width: 25%;
`;
StyledQuestionMidInvisible.displayName = "StyledQuestionMidInvisible";

const StyledQuestionInvisible = styled(StyledInvisible)`
  width: 140px;
`;
StyledQuestionInvisible.displayName = "StyledQuestionInvisible";

const StyledQuestionListInvisible = styled(StyledInvisible)`
  width: 180px;
`;
StyledQuestionListInvisible.displayName = "StyledQuestionInvisible";

const PageContentDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;
PageContentDiv.displayName = "PageContentDiv";

const BoxDiv = styled.div<DefaultThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-width: 280px;
  max-width: 420px;
  min-height: 300px;
  padding: 20px;

  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
`;
BoxDiv.displayName = "BoxDiv";

const BoxRowDiv = styled.div<DefaultThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-width: 1040px;
  max-width: 1040px;
  min-height: 300px;
  padding: 20px;

  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
`;
BoxRowDiv.displayName = "BoxRowDiv";

const BoxDropdownDiv = styled.div<DefaultThemeProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
BoxDropdownDiv.displayName = "BoxDropdownDiv";

const BoxDivSpacer = styled.div<DefaultThemeProps>`
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: ${props => props.theme.providedColor};
  align-items: center;
  span {
    cursor: pointer;
  }
`;
BoxDivSpacer.displayName = "BoxDivSpacer";

const BoxDivSecurityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  white-space: nowrap;
  overflow: hidden;
`;
BoxDivSecurityWrapper.displayName = "BoxDivSecurityWrapper";

const BoxDivMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
`;
BoxDivMessageWrapper.displayName = "BoxDivMessageWrapper";

const BoxRowDivMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 1040px;
  max-width: 1040px;
`;
BoxRowDivMessageWrapper.displayName = "BoxRowDivMessageWrapper";

const BoxMessageTitle = styled.span<DefaultThemeProps>`
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;

  color: ${props => props.theme.primaryText};
  text-align: center;
  width: 100%;
`;
BoxMessageTitle.displayName = "BoxMessageTitle";

const BoxMessageSecondary = styled.span<DefaultThemeProps>`
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.secondaryText};
  text-align: center;
  width: 100%;
  padding-top: 20px;
`;
BoxMessageSecondary.displayName = "BoxMessageSecondary";

const EmailWrapper = styled.div`
  margin: 40px 0px;
`;
EmailWrapper.displayName = "EmailWrapper";

const PasswordWrapper = styled(EmailWrapper)`
  margin-top: 28px;
`;
PasswordWrapper.displayName = "PasswordWrapper";

const StyledBackSpan = styled.span`
  font-size: 16px;
  line-height: 19px;
`;
StyledBackSpan.displayName = "StyledBackSpan";

const PasswordReqSpan = styled.span`
  cursor: default;
  margin-left: 10px;
  font-size: 12px;
  color: ${props => props.theme.primaryText};
`;
PasswordReqSpan.displayName = "PasswordReqSpan";

const PasswordReqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 30px;
`;
PasswordReqWrapper.displayName = "PasswordReqWrapper";

const PasswordReqRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
PasswordReqRowWrapper.displayName = "PasswordReqRowWrapper";

const PasswordReqItemWrapper = styled(PasswordReqRowWrapper)`
  align-items: center;
  width: 150px;
  margin-bottom: 10px;
`;
PasswordReqItemWrapper.displayName = "PasswordReqItemWrapper";

const BoxDivButtonWrapper = styled.div`
  margin-bottom: 20px;
`;
BoxDivButtonWrapper.displayName = "BoxDivButtonWrapper";

const BoxUnfoundWrapper = styled(BoxDivButtonWrapper)`
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  height: 81px;
  align-items: center;
  justify-content: space-between;
`;
BoxUnfoundWrapper.displayName = "BoxUnfoundWrapper";

type PageProps = {
  padding: string;
  renderBack?: boolean;
  history: History;
} & RouteComponentProps;

type PageState = {
  boxNum: number;
  question1: string;
  question2: string;
  answer1: string;
  answer2: string;
  password: string;
  confirmPassword: string;
  question1Custom: boolean;
  question2Custom: boolean;
  regExp8Char: boolean;
  regExpUpper: boolean;
  regExpLower: boolean;
  regExp1Num: boolean;
  securityQuestions1: ReadonlyArray<IDropdownItem>;
  securityQuestions2: ReadonlyArray<IDropdownItem>;
};

export class SignUpPage extends Component<PageProps, PageState> {
  state: PageState = {
    boxNum: 1,
    question1: "",
    question2: "",
    answer1: "",
    answer2: "",
    password: "",
    confirmPassword: "",
    question1Custom: false,
    question2Custom: false,
    regExp8Char: false,
    regExpUpper: false,
    regExpLower: false,
    regExp1Num: false,
    securityQuestions1: [],
    securityQuestions2: [],
  };

  handleQuestion1Val = (selected: IDropdownItem) => {
    this.setState(
      Object.assign({}, this.state, {
        question1: selected.value,
      })
    );
  };

  handleQuestion2Val = (selected: IDropdownItem) => {
    this.setState(
      Object.assign({}, this.state, {
        question2: selected.value,
      })
    );
  };

  handleCustomQuestionVal = (key: string, event: React.ChangeEvent<{ value: string }>) => {
    this.setState(
      Object.assign({}, this.state, {
        [key]: event.target.value,
      })
    );
  };

  handleAnswer1Val = (event: React.ChangeEvent<{ value: string }>) => {
    this.setState(
      Object.assign({}, this.state, {
        answer1: event.target.value,
      })
    );
  };

  handleAnswer2Val = (event: React.ChangeEvent<{ value: string }>) => {
    this.setState(
      Object.assign({}, this.state, {
        answer2: event.target.value,
      })
    );
  };

  handlePassword = (event: React.ChangeEvent<{ value: string }>) => {
    let { RegExp8Char, RegExpUpper, RegExpLower, RegExp1Number } = REG_EXP;

    let valTrim = event.target.value.trim();
    this.setState(
      Object.assign({}, this.state, {
        password: event.target.value,
        regExp8Char: RegExp8Char.test(valTrim),
        regExpUpper: RegExpUpper.test(valTrim),
        regExpLower: RegExpLower.test(valTrim),
        regExp1Num: RegExp1Number.test(valTrim)
      })
    );
  };

  handleConfirmPassword = (event: React.ChangeEvent<{ value: string }>) => {
    this.setState(
      Object.assign({}, this.state, { confirmPassword: event.target.value })
    );
  };

  statePromiseCatch = () => {
    return (error: any) => {
      this.setState(
        Object.assign({}, this.state, { boxNum: 3 })
      );
    };
  };

  handleQuestions = () => {
    getSecurityQuestions()
      .then(response => {
        response && response.data && response.data.en && response.data.en.question_set_1 && this.setState({ securityQuestions1: response.data.en.question_set_1.map((q: string) => ({ 'title': q, 'value': q })) });

        response && response.data.en && response.data.en.question_set_2 && this.setState({securityQuestions2: response.data.en.question_set_2.map((q: string) => ({ 'title': q, 'value': q })) });
      })
    .catch(this.statePromiseCatch());
  };

  handleRegisterUser = (req: IUserRegistrationReq) => {
    registerUser(req).then(resp => {
      let userProfile = getUserFromToken();
      authenticateWithPassword(userProfile.email, req.password).then(response => {
        sessionStorage.clear();
        setToken(response.data.access_token);
        setBrandColor(getBrandColorFromToken());
        setBrandLogo(getBrandLogoFromToken());
        getUserAccountToken().then(resp => {
          setKnownBrowser(getUserIdFromToken() + resp.data.identifier);
        })
        .catch(this.statePromiseCatch());
        this.props.history.replace("/enablement/payments");
      })
      .catch(this.statePromiseCatch());
    })
    .catch(this.statePromiseCatch());
  };

  handleSwitch = (value: number) => {
    const baseAttributes = {
      boxNum: value,
      regExp8Char: false,
      regExpUpper: false,
      regExpLower: false,
      regExp1Num: false
    }

    const pwdAttributes = {
      password: "",
      confirmPassword: ""
    }

    this.setState(
      Object.assign({}, this.state, { ...baseAttributes, ...(value === 1 && pwdAttributes) })
    );
  };

  renderBox = () => {
    let {
      boxNum,
      question1,
      question2,
      answer1,
      answer2,
      password,
      confirmPassword,
      regExp1Num,
      regExp8Char,
      regExpLower,
      regExpUpper
    } = this.state;

    if (boxNum === 1) {
      return (
        <BoxDiv>
          <BoxDivSpacer
            onClick={() => {
              this.props.history.goBack();
            }}
          >
            <KeyboardArrowLeft viewBox="2 2 20 20" />
            <StyledBackSpan>Back</StyledBackSpan>
          </BoxDivSpacer>
          <BoxDivMessageWrapper>
            <BoxMessageTitle>Add a Password</BoxMessageTitle>
          </BoxDivMessageWrapper>
          <PasswordWrapper>
            <Input
              id="user_password"
              header="Password"
              type="password"
              width="300px"
              value={password}
              onChange={e => {
                this.handlePassword(e);
              }}
            />
            <PasswordReqWrapper>
              <PasswordReqRowWrapper>
                <PasswordReqItemWrapper id="password_req_item_length_wrapper">
                  <Checkbox checked={regExp8Char} />
                  <PasswordReqSpan>12+ Characters</PasswordReqSpan>
                </PasswordReqItemWrapper>
                <PasswordReqItemWrapper id="password_req_item_number_wrapper">
                  <Checkbox checked={regExp1Num} />
                  <PasswordReqSpan>1+ Number</PasswordReqSpan>
                </PasswordReqItemWrapper>
              </PasswordReqRowWrapper>
              <PasswordReqRowWrapper>
                <PasswordReqItemWrapper id="password_req_item_uppercase_wrapper">
                  <Checkbox checked={regExpUpper} />
                  <PasswordReqSpan>1+ Uppercase</PasswordReqSpan>
                </PasswordReqItemWrapper>
                <PasswordReqItemWrapper id="password_req_item_lowercase_wrapper">
                  <Checkbox checked={regExpLower} />
                  <PasswordReqSpan>1+ Lowercase</PasswordReqSpan>
                </PasswordReqItemWrapper>
              </PasswordReqRowWrapper>
              <PasswordReqRowWrapper>
                <PasswordReqItemWrapper id="password_req_item_password_match_wrapper">
                  <Checkbox checked={password === confirmPassword && password.trim() !== ""} />
                  <PasswordReqSpan>Password Match</PasswordReqSpan>
                </PasswordReqItemWrapper>
              </PasswordReqRowWrapper>
            </PasswordReqWrapper>
            <Input
              id="user_confirm_password"
              header="Confirm Password"
              type="password"
              width="300px"
              value={confirmPassword}
              onChange={e => {
                this.handleConfirmPassword(e);
              }}
            />
          </PasswordWrapper>
          <BoxDivButtonWrapper>
            <StyledNext
              id="user_password_next_button"
              disabled={password !== confirmPassword || password.trim() === "" || !regExpLower || !regExpUpper || !regExp1Num || !regExp8Char}
              onClick={() => {
                this.handleQuestions();
                this.handleSwitch(2);
              }}
            >
              Continue
            </StyledNext>
          </BoxDivButtonWrapper>
        </BoxDiv>
      );
    } else if (boxNum === 2) {
      return (
        <BoxRowDiv>
          <BoxDivSpacer
            onClick={() => {
              this.handleSwitch(1);
            }}
          >
            <KeyboardArrowLeft viewBox="2 2 20 20" />
            <StyledBackSpan>Back</StyledBackSpan>
          </BoxDivSpacer>
          <BoxRowDivMessageWrapper>
            <BoxMessageTitle>One Last Step</BoxMessageTitle>
            <BoxMessageSecondary>
              Please create two security questions.
            </BoxMessageSecondary>
            <BoxDivSpacer />
            <BoxDropdownDiv>
              <StyledInvisible />
              {this.state.question1Custom ? (
                <Input
                  width="300px"
                  placeholder="Example: what is your favorite food?"
                  value={question1}
                  onChange={e => this.handleCustomQuestionVal('question1', e)}
                />) : (
                <Dropdown
                  id="securityquestionone"
                  placeholder="Pick a Security Question"
                  handleValue={this.handleQuestion1Val}
                  list={this.state.securityQuestions1}
                  width={300}
                />)}
              <StyledMidInvisible />
              {this.state.question2Custom ? (
                <Input
                  width="300px"
                  placeholder="Example: what is your favorite food?"
                  value={question2}
                  onChange={e => this.handleCustomQuestionVal('question2', e)}
                />) : (
                <Dropdown
                  id="securityquestiontwo"
                  placeholder="Pick a Security Question"
                  handleValue={this.handleQuestion2Val}
                  list={this.state.securityQuestions2}
                  width={300}
                />)}
              <StyledInvisible />
            </BoxDropdownDiv>
            <br/>
            <BoxDropdownDiv>
              <BoxDivSecurityWrapper>
                {this.state.question1Custom ? (
                  <Fragment>
                    <StyledQuestionListInvisible />
                    or&nbsp;<u
                      onClick={() => {
                        this.setState(
                          Object.assign({}, this.state, { question1: "", question1Custom: false })
                        );
                      }}
                    >select from a list</u>
                  </Fragment>
                  ) : (
                  <Fragment>
                    <StyledQuestionInvisible />
                    or&nbsp;<u
                      onClick={() => {
                        this.setState(
                          Object.assign({}, this.state, { question1: "", question1Custom: true })
                        );
                      }}
                    >write your own question</u>
                  </Fragment>)}
              </BoxDivSecurityWrapper>
              <StyledQuestionMidInvisible />
              <BoxDivSecurityWrapper>
                <StyledQuestionInvisible />
                {this.state.question2Custom ? (
                  <Fragment>
                    or&nbsp;<u
                      onClick={() => {
                        this.setState(
                          Object.assign({}, this.state, { question2: "", question2Custom: false })
                        );
                      }}
                    >select from a list</u>
                    <StyledQuestionListInvisible />
                  </Fragment>
                  ) : (
                  <Fragment>
                    or&nbsp;<u
                      onClick={() => {
                        this.setState(
                          Object.assign({}, this.state, { question2: "", question2Custom: true })
                        );
                      }}
                    >write your own question</u>
                    <StyledQuestionInvisible />
                  </Fragment>)}
              </BoxDivSecurityWrapper>
            </BoxDropdownDiv>
            <br/>
            <BoxDropdownDiv>
              <StyledInvisible />
              <Input
                width="300px"
                placeholder="Security answer"
                value={answer1}
                onChange={e => this.handleAnswer1Val(e)}
              />
              <StyledMidInvisible />
              <Input
                width="300px"
                placeholder="Security answer"
                value={answer2}
                onChange={e => this.handleAnswer2Val(e)}
              />
              <StyledInvisible />
            </BoxDropdownDiv>
          </BoxRowDivMessageWrapper>
          <BoxDivButtonWrapper>
            <BoxDivSpacer />
            <StyledNext
              disabled={question1.trim() === "" || question2.trim() === "" || answer1.trim() === "" || answer2.trim() === ""}
              onClick={() => {
                let req = { 'user_id': -1, 'password': password, 'security_question_1': question1, 'security_answer_1': answer1, 'security_question_2': question2, 'security_answer_2': answer2, 'login_id': -1 };
                this.handleRegisterUser(req);
              }}
            >
              Done
            </StyledNext>
          </BoxDivButtonWrapper>
        </BoxRowDiv>
      );
    } else if (boxNum === 3) {
      return (
        <BoxDiv>
          <BoxDivSpacer />
          <BoxDivMessageWrapper>
            <ErrorXButton><CloseIcon /></ErrorXButton>
          </BoxDivMessageWrapper>
          <p/>
          <p/>
          Page not found.
          <p/>
          Oops - something went wrong.
          <BoxUnfoundWrapper>
            <StyledNext
              onClick={() => {
                window.open("mailto:support@versapay.com");
              }}
            >
              Email Us
            </StyledNext>
            <br/>
            <StyledNext
              onClick={() => {
                this.props.history.replace("/enablement/payments");
              }}
            >
              Go Back&nbsp;
            </StyledNext>
          </BoxUnfoundWrapper>
        </BoxDiv>
      );
    }

    return null;
  };

  pageContent = (): React.ReactNode => {
    return (
      <Fragment>
        <PageContentDiv>
          {this.renderBox()}
          <StyledFooter>
            <span id="application_footer">
             Powered by &copy; {pageLayoutProps().applicationCopyright}
            </span>
          </StyledFooter>
        </PageContentDiv>
      </Fragment>
    );
  };

  render() {
    return (
      <PageLayout
        noNavLinks
        noFooter
        pageLayoutProps={pageLayoutProps()}
        scrollableContent={this.pageContent}
      />
    );
  }
}

export default withRouter(SignUpPage);
