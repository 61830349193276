import React, { ReactNode, Fragment } from "react";
import styled from "styled-components";
import StyledButton from "./StyledButton";
import { IDialogType, IDialogFormat } from "../types";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";

type Props = {
  children: ReactNode;
  title?: string;
  type: IDialogType;
  button?: string;
  format: IDialogFormat;
  maxWidth?: any;
  onClose?: () => void;
  onConfirm?: Function;
  isEnabled?: boolean | undefined;
  noBodyMargin?: boolean;
  noButtons?: boolean;
  noCancel?: boolean;
  enableBackdropClick?: boolean;
};

type FormDialogBodyProps = {
  noMargin?: boolean;
};

const TriggerWrapper = styled.span`
  cursor: pointer;
`;
TriggerWrapper.displayName = "TriggerWrapper";

const FormDialogWrapper = styled.div`
  min-width: 390px;
`;
FormDialogWrapper.displayName = "FormDialogWrapper";

const FormDialogHeader = styled.div`
  width: 100%;
  height: 34px;
  background: ${props => props.theme.titleBackground};
  border-radius: 3px 3px 0px 0px;
  padding: 10px 19px;

  span {
    font-weight: 600;
    font-size: 11px;
    color: ${props => props.theme.secondaryText};
    text-transform: uppercase;
    position: absolute;
  }
`;
FormDialogHeader.displayName = "FormDialogHeader";

const FormDialogBody = styled.div<FormDialogBodyProps>`
  display: flex;
  flex: 1;
  justify-content: center;
  ${props => (props.noMargin ? "margin-bottom: 10px" : "margin: 20px 30px")};
`;
FormDialogBody.displayName = "FormDialogBody";

const FormDialogFooter = styled(DialogActions)<Props>`
  justify-content: ${props =>
    props.type === "Notice" || "Save" ? "center" : "space-between"};
  padding: 0;

  ${props => props.format === "confirmation" && "margin: 10px 30px 20px 30px;"}
  ${props => props.format === "alert" && "margin: 10px 46px 20px 46px;"}
`;
FormDialogFooter.displayName = "FormDialogFooter";

const StyledCursorButton = styled(StyledButton)`
  width: 140px;
  display: block;
  background: ${props => props.theme.providedColor};

  :disabled {
    cursor: auto;
  }
`;
StyledCursorButton.displayName = "StyledCursorButton";

const StyledCancelButton = styled(StyledCursorButton)`
  background: ${props => props.theme.secondaryText};
`;
StyledCancelButton.displayName = "StyledCancelButton";

const StyledRemoveButton = styled(StyledCursorButton)`
  background: ${props => props.theme.negativeText};
  padding: 0;
`;
StyledRemoveButton.displayName = "StyledRemoveButton";

const StyledCloseButton = styled(StyledCursorButton)`
  width: 100%
`;
StyledCloseButton.displayName = "StyledCloseButton";

export default function FormDialog({
  title,
  type,
  format,
  children,
  onClose,
  onConfirm,
  maxWidth,
  isEnabled,
  noBodyMargin,
  noButtons,
  noCancel,
  button,
  enableBackdropClick
}: Props) {
  function handleClose() {
    onClose && onClose();
  }

  function onClickCancel() {
    handleClose();
  }

  function onClickConfirm() {
    onConfirm && onConfirm();
  }

  function renderDialogFooter() {
    const disabled = isEnabled === undefined ? false : !isEnabled;
    let confirmButton = (
      <StyledCursorButton disabled={disabled} onClick={onClickConfirm}>
        {button || type}
      </StyledCursorButton>
    );
    let cancelButton = (
      <StyledCancelButton onClick={onClickCancel}>Cancel</StyledCancelButton>
    );
    if (type === "Remove") {
      confirmButton = (
        <StyledRemoveButton disabled={disabled} onClick={onClickConfirm}>
          {button || type}
        </StyledRemoveButton>
      );
    } else if (type === "Notice") {
      cancelButton = (
        <StyledCancelButton onClick={onClickCancel}>
          {button || `OK`}
        </StyledCancelButton>
      );
    } else if (type === "Close") {
      cancelButton = (
        <StyledCloseButton onClick={onClickCancel}>{button || `Close`}</StyledCloseButton>
      );
    }
    return (
      <Fragment>
        <FormDialogFooter format={format} type={type} id="form_dialog_footer">
          {!noCancel && cancelButton}
          {(type === "Notice" || type === "Close") ? null : confirmButton}
        </FormDialogFooter>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Dialog
        disableBackdropClick={!noCancel || !enableBackdropClick}
        disableEscapeKeyDown={!noCancel}
        onClose={onClickCancel}
        open={true}
        aria-labelledby="form-dialog-title"
        maxWidth={maxWidth}
        scroll="body"
      >
        <FormDialogWrapper>
          <FormDialogHeader id="form_dialog_header">
            <span>{title}</span>
          </FormDialogHeader>
          <FormDialogBody noMargin={noBodyMargin} id="form_dialog_body">
            {children}
          </FormDialogBody>
          {(!noButtons && !enableBackdropClick) && renderDialogFooter()}
        </FormDialogWrapper>
      </Dialog>
    </Fragment>
  );
}
