import styled, { DefaultThemeProps } from "styled-components";

const SeparatorDiv = styled.div<DefaultThemeProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  :only-child {
    border-bottom: none;
  }
`;

export default SeparatorDiv;
