import React, { Fragment, useState, memo } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import StyledButton from "./StyledButton";

type PaginationProps = {
  totalRows: number;
  rowsPerPage: number;
  currentPage?: number;
  applyPage?: (currentPage: number) => void;
};

type NumberProps = {
  isSelected?: boolean;
  onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
} & DefaultThemeProps;

const TextButton = styled(StyledButton)`
  padding: 0 10px;
  background: ${props => props.theme.mutedBackground};
  box-shadow: none;
  color: ${props => props.theme.secondaryText};
  height: 30px;
  font-size: 12px;
  line-height: 14px;
  margin: 0;
`;

const PrevButton = styled(TextButton)`
  margin-right: 5px;
`;
PrevButton.displayName = "PrevButton";

const NextButton = styled(TextButton)`
  margin-left: 5px;
`;
NextButton.displayName = "NextButton";

const NumberButton = styled(StyledButton)<NumberProps>`
  color: ${props =>
    props.isSelected ? props.theme.primaryText : props.theme.secondaryText};
  cursor: ${props => (props.isSelected ? "default" : "pointer")};
  font-size: 12px;
  line-height: 14px;
  height: auto;
  margin: 0;
  padding: 0 5px;
  background: none;
  box-shadow: none;
`;
NumberButton.displayName = "NumberButton";

const Elipses = styled.div`
  color: ${props => props.theme.secondaryText};
  cursor: default;
  margin: 0;
  padding: 0 5px;
  background: none;
  box-shadow: none;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 30px;
  width: auto;
`;

function PaginationButtons({
  totalRows,
  currentPage,
  rowsPerPage,
  applyPage
}: PaginationProps) {
  const [currPage, setPage] = useState(currentPage ? currentPage : 1);
  const totalPages = Math.ceil(totalRows / rowsPerPage);

  const onClickHandleCallback = (val: number) => {
    applyPage && applyPage(val);
  };

  function onClickSelectPage(
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    let value = parseInt(e.currentTarget.value);
    setPage(value);
    onClickHandleCallback(value);
  }

  function onClickIncrement() {
    let value = currPage + 1;
    setPage(value);
    onClickHandleCallback(value);
  }

  function onClickDecrement() {
    let value = currPage - 1;
    setPage(value);
    onClickHandleCallback(value);
  }

  function renderNumber(val: number) {
    return (
      <NumberButton
        isSelected={currPage === val}
        key={`pg_btn_${val}`}
        onClick={currPage !== val ? onClickSelectPage : () => {}}
        value={val}
      >
        {val}
      </NumberButton>
    );
  }

  function firstLastNumbers(val1: number, val2: number) {
    return (
      <Fragment>
        {renderNumber(val1)}
        {renderNumber(val2)}
      </Fragment>
    );
  }

  function renderPages() {
    let pg = [];
    let range = 4;
    let renderLower = 3;
    let renderUpper = totalPages - 2;

    if (totalPages > 15) {
      if (currPage < 9) {
        renderLower = 3;
        renderUpper = 12;
      } else if (currPage > totalPages - 8) {
        renderLower = totalPages - 11;
        renderUpper = totalPages - 2;
      } else {
        renderLower = currPage - range;
        renderUpper = currPage + range;
      }
    } else if (totalPages < 5) {
      renderLower = 1;
      renderUpper = totalPages;
    }

    if (currPage > 8 && totalPages > 15) {
      pg.push(<Elipses key={"pg_elipses_lower_bound"}>...</Elipses>);
    }

    if (totalPages !== 1) {
      for (let i = renderLower; i <= renderUpper; i++) {
        pg.push(renderNumber(i));
      }
    }

    if (currPage <= totalPages - 8 && totalPages > 15) {
      pg.push(<Elipses key={"pg_elipses_upper_bound"}>...</Elipses>);
    }
    return pg;
  }

  return (
    <PaginationWrapper>
      {currPage !== 1 && (
        <PrevButton
          value="Previous"
          key="pg_btn_prev"
          onClick={onClickDecrement}
        >
          Previous
        </PrevButton>
      )}
      {totalPages > 4 && firstLastNumbers(1, 2)}
      {renderPages()}
      {totalPages > 4 && firstLastNumbers(totalPages - 1, totalPages)}
      {currPage !== Math.ceil(totalRows / rowsPerPage) && (
        <NextButton value="Next" key="pg_btn_next" onClick={onClickIncrement}>
          Next
        </NextButton>
      )}
    </PaginationWrapper>
  );
}

export default memo(PaginationButtons);
