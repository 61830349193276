import React from "react";
import styled from "styled-components";
import { CheckCircle, Close } from "@material-ui/icons/";
import { toast, ToastOptions } from "react-toastify";

type Props = {
  text: string;
  type?: "success" | "error";
  subText?: string;
  onClick?: Function;
  extraDuration?: boolean;
};

const SuccessIcon = styled(CheckCircle)`
  font-size: 38px !important;
`;
SuccessIcon.displayName = "StyledCheckCircle";

const ErrorIcon = styled(Close)`
  font-size: 38px !important;
`;
ErrorIcon.displayName = "StyledClose";

const ToastWrapper = styled.div`
  display: inline-flex;
  flex-direction: row;
  margin: auto;
  width: 100%;
  height: 40px;
  svg {
    margin-left: 12px;
    margin-right: 20px;
  }
`;
ToastWrapper.displayName = "ToastWrapper";

const ToastWrapperText = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-content: space-between;
`;
ToastWrapperText.displayName = "ToastWrapperText";

const BannerText = styled.span`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  margin: auto;
`;
BannerText.displayName = "BannerText";

const BannerSubText = styled.span`
  font-weight: 500;
  font-size: 12px;
  margin: auto;
  line-height: 14px;
`;
BannerSubText.displayName = "BannerSubText";

const Toast = ({ text, type, subText, onClick, extraDuration }: Props) => {
  const toastType = type || "success";
  const success = toastType === "success";

  const toastContent = (
    <ToastWrapper id="toast_wrapper">
      {success ? <SuccessIcon /> : <ErrorIcon />}
      <ToastWrapperText>
        <BannerText id={"toast_text"}>{text}</BannerText>
        {subText && (
          <BannerSubText id={"toast_sub_text"}>{subText}</BannerSubText>
        )}
      </ToastWrapperText>
    </ToastWrapper>
  );

  const onClickHandler = () => {
    if (onClick) {
      toast.dismiss();
      onClick();
    }
  };

  const toastOptions: ToastOptions = {
    className: `arc-toast${onClick ? " clickable" : ""}`,
    type: toastType,
    autoClose: extraDuration && 6500, // On success export we would like to see +4000ms (6500ms) compared to the other banners, Default at 2500ms
    onClick: onClickHandler
  };

  toast.dismiss();
  toast(toastContent, toastOptions);
};

export default Toast;
