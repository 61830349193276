import React, { memo, useState } from "react";
import Dialog from "../../common/components/Dialog";
import Dropdown from "../../common/components/Dropdown";
import styled, { DefaultThemeProps, keyframes } from "styled-components";
import { Autorenew, Close } from "@material-ui/icons/";
import { isNil } from "lodash";
import Input from "../../common/components/Input";
import Switch from "../../common/components/Switch";
import { History } from "history";
import { sftpCheck } from "../services/scheduleServices";
import { toastErrorHandler } from "../lib/helpers";
import { IDropdownItem, IScheduleFormat } from "../../common/types";

const ScheduleDialogColumn = styled.div<DefaultThemeProps>`
  width: 300px;
  height: 505px;
  padding: 30px 30px 10px 30px;
  display: flex;
  flex-direction: column;

  #frequencies_dropdown_wrapper {
    margin-top: 20px;
  }

  span {
    font-size: 12px;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    line-height: 150%;
  }

  span#frequency_text {
    margin-top: 10px;
    color: ${props => props.theme.secondaryText};
  }

  span:last-child {
    margin-top: auto;
    color: ${props => props.theme.primaryText};
  }
`;
ScheduleDialogColumn.displayName = "ScheduleDialogColumn";

const SFTPColumn = styled.div`
  width: 300px;
  height: 505px;
  padding: 23px 30px 0px 30px;

  > div:not(:nth-last-child(-n + 2)) {
    margin-bottom: 20px;
  }
`;
SFTPColumn.displayName = "SFTPColumn";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const RenewIcon = styled(Autorenew)`
  font-size: 33px !important;
  color: ${props => props.theme.providedColor};
  transform: rotate(90deg);
  animation: ${rotate} 2s linear infinite;
`;
RenewIcon.displayName = "RenewIcon";

const CloseIcon = styled(Close)`
  font-size: 20px !important;
  color: ${props => props.theme.negativeText};
`;
CloseIcon.displayName = "CloseIcon";

const SFTPStatusWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 36px;
  align-items: center;

  span {
    font-size: 12px;
    color: ${props => props.theme.secondaryText};
  }
`;
SFTPStatusWrapper.displayName = "SFTPStatusWrapper";

const SFTPFailStatusWrapper = styled(SFTPStatusWrapper)`
  span {
    color: ${props => props.theme.negativeText};
  }
`;
SFTPFailStatusWrapper.displayName = "SFTPFailStatusWrapper";

const SFTPSwitch = styled.div<DefaultThemeProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${props => props.theme.secondaryText};
  }
`;
SFTPSwitch.displayName = "SFTPSwitch";

const ScheduleDialogWrapper = styled.div`
  width: 600px;
  height: 505px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid ${props => props.theme.borderColor};
  margin-bottom: 10px;

  ${SFTPColumn} {
    border-left: 1px solid ${props => props.theme.borderColor};
  }
`;
ScheduleDialogWrapper.displayName = "ScheduleDialogWrapper";

const SFTPInfoTextWrapper = styled.div``;
SFTPInfoTextWrapper.displayName = "SFTPInfoTextWrapper";

const SFTPInfoText = styled.span`
  font-size: 12px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
`;
SFTPInfoText.displayName = "SFTPInfoText";

type ScheduleDialogProps = {
  onClose: () => void;
  onConfirm: (options: IScheduleFormat) => void;
  formatsList: Array<IDropdownItem>;
  frequenciesList: Array<IDropdownItem>;
  history: History;
};

function ScheduleDialog({
  formatsList,
  frequenciesList,
  onClose,
  onConfirm,
  history
}: ScheduleDialogProps) {
  const [selectedFormat, setSelectedFormat] = useState(null as number | null);
  const [selectedFrequency, setSelectedFrequency] = useState(
    null as string | null
  );
  const [usingSFTP, setUsingSFTP] = useState(false as boolean);
  const [SFTPDestination, setSFTPDestination] = useState("" as string | null);
  const [SFTPUsername, setSFTPUsername] = useState("" as string | null);
  const [SFTPPassword, setSFTPPassword] = useState("" as string | null);
  const [SFTPStatus, setSFTPStatus] = useState(null as string | null);

  function formInvalid() {
    return (
      isNil(selectedFormat) ||
      isNil(selectedFrequency) ||
      SFTPStatus === "fail" ||
      SFTPStatus === "testing"
    );
  }

  function handleSFTPDestinationChange(
    event: React.ChangeEvent<{ value: string }>
  ) {
    setSFTPDestination(event.target.value as string);
    setSFTPStatus(null);
  }

  function handleSFTPUsernameChange(
    event: React.ChangeEvent<{ value: string }>
  ) {
    setSFTPUsername(event.target.value as string);
    setSFTPStatus(null);
  }

  function handleSFTPPasswordChange(
    event: React.ChangeEvent<{ value: string }>
  ) {
    setSFTPPassword(event.target.value as string);
    setSFTPStatus(null);
  }

  function handleFormatVal(formatVal: IDropdownItem) {
    setSelectedFormat(formatVal.value as number);
  }

  function handleFrequencyVal(frequencyVal: IDropdownItem) {
    setSelectedFrequency(frequencyVal.value as string);
  }

  function handleSFTPSwitchToggle() {
    if (usingSFTP) {
      setSFTPDestination("");
      setSFTPUsername("");
      setSFTPPassword("");
      setSFTPStatus(null);
    }
    setUsingSFTP(!usingSFTP);
  }

  function frequencyText() {
    if (!isNil(selectedFrequency)) {
      if (selectedFrequency === "daily") {
        return (
          <span id="frequency_text">
            Daily schedules will run everyday at 12:10AM EST.
          </span>
        );
      } else if (selectedFrequency === "weekly") {
        return (
          <span id="frequency_text">
            Weekly schedules will run every Saturday at 12:10AM EST.
          </span>
        );
      }
    }
  }

  function handleSFTPStatus() {
    if (SFTPStatus === "testing") {
      return (
        <SFTPStatusWrapper>
          <RenewIcon />
          <span>Testing...</span>
        </SFTPStatusWrapper>
      );
    } else if (SFTPStatus === "fail") {
      return (
        <SFTPFailStatusWrapper>
          <CloseIcon />
          <span>Failed</span>
        </SFTPFailStatusWrapper>
      );
    } else {
      return <SFTPStatusWrapper />;
    }
  }

  function checkSFTPOnConfirm() {
    setSFTPStatus("testing" as string);
    sftpCheck(
      SFTPDestination as string,
      SFTPUsername as string,
      SFTPPassword as string
    )
      .then(response => {
        if (response.data.success) {
          onConfirm({
            selectedFormat,
            selectedFrequency,
            SFTPDestination,
            SFTPUsername,
            SFTPPassword,
            usingSFTP
          });
        } else {
          setSFTPStatus("fail" as string);
        }
      })
      .catch(error => {
        setSFTPStatus("fail" as string);
        toastErrorHandler(history, error);
      });
  }

  return (
    <Dialog
      onClose={onClose}
      onConfirm={() => {
        usingSFTP
          ? checkSFTPOnConfirm()
          : onConfirm({
              selectedFormat,
              selectedFrequency,
              usingSFTP,
              SFTPDestination,
              SFTPUsername,
              SFTPPassword
            });
      }}
      isEnabled={!formInvalid()}
      type="Save"
      format="confirmation"
      title="New Payment Export Schedule"
      noBodyMargin
    >
      <ScheduleDialogWrapper>
        <ScheduleDialogColumn>
          <Dropdown
            id="formats"
            title="Format"
            required
            placeholder="Select Format"
            handleValue={handleFormatVal}
            list={formatsList}
          />
          <Dropdown
            id="frequencies"
            title="Frequency"
            required
            placeholder="Select Frequency"
            handleValue={handleFrequencyVal}
            list={frequenciesList}
          />
          {frequencyText()}
          <span>
            Exports that do not have an SFTP destination are available for
            Download in the Exports tab.
          </span>
        </ScheduleDialogColumn>
        <div>
          <SFTPColumn>
            <SFTPSwitch>
              <span id="sftp_header">Secure File Transfer</span>
              <div
                id="sftp_toggle_wrapper"
                onClick={() => handleSFTPSwitchToggle()}
              >
                <Switch checked={usingSFTP} />
              </div>
            </SFTPSwitch>
            <Input
              isInvalid={SFTPStatus === "fail"}
              disabled={!usingSFTP || SFTPStatus === "testing"}
              id="sftp_destination"
              header="Destination"
              onChange={handleSFTPDestinationChange}
              value={SFTPDestination as string}
            />
            <SFTPInfoTextWrapper>
              <SFTPInfoText id="sftp_info_text_1">
                Host name or IP address only (no path)
              </SFTPInfoText>
            </SFTPInfoTextWrapper>
            <Input
              isInvalid={SFTPStatus === "fail"}
              disabled={!usingSFTP || SFTPStatus === "testing"}
              id="sftp_username"
              header="Username"
              onChange={handleSFTPUsernameChange}
              value={SFTPUsername as string}
            />
            <SFTPInfoTextWrapper>
              <SFTPInfoText id="sftp_info_text_2">
                If the delivery destination requires a specific path, ensure
                that this user account has that path setup as the home directory
              </SFTPInfoText>
            </SFTPInfoTextWrapper>

            <Input
              isInvalid={SFTPStatus === "fail"}
              type="password"
              disabled={!usingSFTP || SFTPStatus === "testing"}
              id="sftp_password"
              header="Password"
              onChange={handleSFTPPasswordChange}
              value={SFTPPassword as string}
            />
            {handleSFTPStatus()}
          </SFTPColumn>
        </div>
      </ScheduleDialogWrapper>
    </Dialog>
  );
}

export default memo(ScheduleDialog);
