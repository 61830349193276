import React, { Component } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import PageLayout from "./PageLayout";

type Props = {
  padding: string;
};

type StyleProps = Props & DefaultThemeProps;

const ApplicationWrapper = styled.div<DefaultThemeProps>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
  display: flex;
`;

const PageWrapper = styled.div<StyleProps>`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .box {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 280px;
    max-width: 420px;
    min-height: 300px;
    padding: 30px;

    border: 1px solid ${props => props.theme.borderColor};
    box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
    div.message {
      font-size: 16px;
      line-height: 150%;
      color: ${props => props.theme.secondaryText};
      text-align: center;
      padding: ${props => props.padding};
    }
  }
`;
PageWrapper.displayName = "PageWrapper";

type PageProps = {
  icon?: React.ReactNode;
  messages?: ReadonlyArray<string>;
  outsideBox?: React.ReactNode;
  padding: string;
};

export class FullPageMessage extends Component<PageProps> {
  constructor(props: PageProps) {
    super(props);

    this.renderMessages = this.renderMessages.bind(this);
  }

  renderMessages() {
    const { messages } = this.props;
    if (messages) {
      return messages.map((message, idx) => {
        return (
          <div key={`message_${idx}`} className="message">
            {message}
          </div>
        );
      });
    }
    return null;
  }

  render() {
    const { icon, outsideBox, children, padding } = this.props;

    return (
      <PageLayout>
       <ApplicationWrapper>
        <PageWrapper padding={padding}>
          <div className="box">
            {icon}
            {this.renderMessages()}
            {children}
          </div>
          {outsideBox}
        </PageWrapper>
       </ApplicationWrapper>
      </PageLayout>
    );
  }
}

export default FullPageMessage;
