import React from 'react';
import styled, { withTheme, DefaultThemeProps } from 'styled-components';
import Loading from 'react-loading';

type Props = {
  size: string
} & DefaultThemeProps;

const LoadingWrapper = styled.div`
  display: inline-block;
  padding: 4px;
`;

const HorizontalAlignWrapper = styled.div`
  text-align: center;
`;

const FullPage = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LoadingSpinner = ({ size, theme }: Props) => {
  if (size === 'full') {
    return (
      <FullPage>
        <Loading type='spin' color={theme.providedColor} height='100px' width='100px' delay={0} />
      </FullPage>
    );
  }

  return (
    <HorizontalAlignWrapper>
      <LoadingWrapper>
        <Loading type='spin' color={theme.providedColor} height={size} width={size} delay={0} />
      </LoadingWrapper>
    </HorizontalAlignWrapper>
  );
};

export default withTheme(LoadingSpinner);
