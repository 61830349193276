import React, { Component } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";

import Alert from '@material-ui/lab/Alert';
import CheckCircle from "@material-ui/icons/CheckCircle";
import Payment from "@material-ui/icons/Payment";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Toast from "../../../common/components/Toast";
import HoverLink from "../../../common/components/HoverLink";

import ExportDialog from "../ExportDialog";
import InvoiceCell from "../InvoiceCell";

import PaymentListingProcessModal from "./PaymentListingProcessModal";

import {
  pageLayoutProps,
  getSenderIdFromToken,
  getPreviewModeFromToken,
  standardPromiseCatch,
  toastPromiseCatch
} from "../../lib/helpers";

import { getFormats } from "../../services/formatServices";
import { exportPayments } from "../../services/exportServices";
import { getEmail } from "../../services/notificationServices";
import { getPayments } from "../../services/paymentServices";
import { markPaid } from "../../services/attributeServices";
import { getSender } from "../../services/senderServices";

import ListingPage from "../../../common/components/PageLayout/ListingPage";
import EmailDialog from "../../../common/components/EmailDialog";

import {
  formatCreditCard,
  formatNormalUtcDate,
  getUpperName,
  getBrandLogo,
  centsToDisplay
} from "../../../common/lib/helpers";

import {
  IIndexJsonData,
  IPageLayoutProps,
  IFilterPanelState,
  IJsonRecord,
  IRowConfig,
  IAggregateConfig,
  ISelectionConfig,
  IFilterConfig,
  IPageConfig,
  IRowLimitConfig,
  SortDirection,
  ICardOption,
  IPopoverAction,
  IDropdownItem
} from "../../../common/types.js";

import { ROW_LIMIT } from "../../lib/constants";

const PositiveCheckCircle = styled(CheckCircle)`
  color: ${props => props.theme.positiveText};
`;
PositiveCheckCircle.displayName = "PositiveCheckCircle";

const StyledPayment = styled(Payment)`
  font-size: 29px;
  margin-right: 10px;
`;
StyledPayment.displayName = "StyledPayment";

const CardDetails = styled.div<DefaultThemeProps>`
  width: 100%;

  span,
  a {
    font-size: 12px;
    margin-right: 20px;
    vertical-align: middle;
  }
`;
CardDetails.displayName = "CardDetails";

const StyledSuccess = styled(Alert)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 600;
  text-decoration: bold;
  font-weight: 500;
  font-size: 17px;
  background: green;
  color: ${props => props.theme.activeText};
StyledSuccess.displayName = "StyledSuccess";
`;

const GreenPaid = styled.h4`
  color: green;
  text-decoration: bold;
`;
GreenPaid.displayName = "GreenPaid";

const RedPaid = styled.h4`
  color: red;
  text-decoration: bold;
`;
RedPaid.displayName = "RedPaid";

const StyledPaymentNo = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 75px;
`;
StyledPaymentNo.displayName = "StyledPaymentNo";

const StyledMoreDetails = styled.div`
  color: ${props => props.theme.primaryText};
  display: inline-flex;
  flex-direction: row;
  :hover,
  :focus {
    color: ${props => props.theme.primaryText};
    text-decoration: underline;
  }
`;
StyledMoreDetails.displayName = "StyledMoreDetails";

const StyledMoreDetailsArrow = styled(ArrowForwardIcon)`
  display: inline-flex;
  vertical-align: top;
  flex-wrap: wrap;
`;
StyledMoreDetailsArrow.displayName = "StyledMoreDetailsArrow";

const StyledCCNumber = styled.a`
  :hover,
  :focus {
    color: ${props => props.theme.activeLinkText};
  }
`;
StyledCCNumber.displayName = "StyledCCNumber";

type PageProps = {} & RouteComponentProps;
type PageState = {
  jsonData: IIndexJsonData | null;
  emailData: any | null;
  formatData: Array<IDropdownItem | any> | null;
  searchText: string | null;
  windowStart: number;
  windowSize: number;
  sortField: string;
  showToday: boolean;
  show7Days: boolean;
  showNotExported: boolean;
  showPaid: boolean;
  showInProgress: boolean;
  showError: boolean;
  showCardDetails: boolean;
  isSearching: boolean;
  isDialogOpen: boolean;
  isPaymentDetail: boolean;
  ids: number[];
  hasMerchant: boolean;
};

export class PaymentListingPage extends Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);

    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onClickSort = this.onClickSort.bind(this);
    this.onClickSortDirection = this.onClickSortDirection.bind(this);
    this.onClickFilter = this.onClickFilter.bind(this);
    this.onClickExportPayments = this.onClickExportPayments.bind(this);
    this.onClickPageChange = this.onClickPageChange.bind(this);
    this.onClickRowChange = this.onClickRowChange.bind(this);
    this.getDataOptions = this.getDataOptions.bind(this);
    this.getData = this.getData.bind(this);
    this.getCustomFormats = this.getCustomFormats.bind(this);
    this.handleMarkPaid = this.handleMarkPaid.bind(this);
    this.handleMoreDetails = this.handleMoreDetails.bind(this);
    this.getEmailFile = this.getEmailFile.bind(this);
    this.renderOverlayContent = this.renderOverlayContent.bind(this);
    this.renderRowConfig = this.renderRowConfig.bind(this);
    this.renderRowMenuOptions = this.renderRowMenuOptions.bind(this);
    this.renderSelectionConfig = this.renderSelectionConfig.bind(this);
    this.renderFilterConfig = this.renderFilterConfig.bind(this);
    this.renderPageConfig = this.renderPageConfig.bind(this);
    this.renderRowLimitConfig = this.renderRowLimitConfig.bind(this);
    this.paymentPageLayoutProps = this.paymentPageLayoutProps.bind(this);
    this.renderTopBar = this.renderTopBar.bind(this);
  }

  state: PageState = {
    jsonData: null,
    formatData: null,
    searchText: null,
    windowStart: 0,
    windowSize: ROW_LIMIT[0].value,
    sortField: "created_at DESC",
    showToday: false,
    show7Days: false,
    showNotExported: false,
    showPaid: false,
    showInProgress: false,
    showError: false,
    showCardDetails: getBrandLogo() === "mineraltree" || false,
    isSearching: false,
    isDialogOpen: false,
    isPaymentDetail: false,
    ids: [],
    hasMerchant: false,
    emailData: null
  };

  componentDidMount() {
    this.getData();
  }

  componentWillUnmount() {
      sessionStorage.removeItem('showSuccess');
  }

  // TODO PUSH LOWER
  onChangeSearch(searchText: string): void {
    this.setState({ searchText, windowStart: 0, jsonData: null }, this.getData);
  }

  // TODO PUSH LOWER
  onClickSort(field: string): void {
    let { sortField } = this.state;
    if (sortField === `${field} ASC`) {
      sortField = `${field} DESC`;
    } else {
      sortField = `${field} ASC`;
    }
    this.setState({ sortField, jsonData: null }, this.getData);
  }

  // TODO PUSH LOWER
  onClickSortDirection(field: string): SortDirection {
    const { sortField } = this.state;
    if (sortField === `${field} ASC`) return "asc";
    if (sortField === `${field} DESC`) return "desc";
    return null;
  }

  // TODO PUSH LOWER
  onClickFilter(filterState: IFilterPanelState | null) {
    const showCardDetails =
      (filterState && filterState.showCardDetails) || false;
    if (showCardDetails !== this.state.showCardDetails) {
      this.setState({ showCardDetails });
      return;
    }

    const showToday = (filterState && filterState.showToday) || false;
    const show7Days = (filterState && filterState.show7Days) || false;
    const showNotExported =
      (filterState && filterState.showNotExported) || false;
    const showPaid = (filterState && filterState.showPaid) || false;
    const showInProgress = (filterState && filterState.showInProgress) || false;
    const showError = (filterState && filterState.showError) || false;
    this.setState(
      {
        windowStart: 0,
        showToday,
        show7Days,
        showNotExported,
        showPaid,
        showInProgress,
        showError,
        jsonData: null
      },
      this.getData
    );
  }

  onClickExportPayments(ids: number[], format: IDropdownItem) {
    exportPayments(ids, format)
      .then(() => {
        this.setState({ jsonData: null }, this.getData);
        Toast({
          text: "Export In Progress...",
          subText: "Click here to view your exports.",
          onClick: () => {
            this.props.history.replace("/enablement/payment_exports");
          },
          extraDuration: true
        });
      })
      .catch(toastPromiseCatch(this.props.history));
  }

  onClickPageChange(pageNumber: number) {
    let { windowSize } = this.state;
    let windowStart = pageNumber - 1;

    this.setState(
      {
        windowStart,
        windowSize,
        jsonData: null
      },
      this.getData
    );
  }

  onClickRowChange(rowLimit: number) {
    this.setState(
      {
        windowStart: 0,
        windowSize: rowLimit,
        jsonData: null
      },
      this.getData
    );
  }

  getDataOptions() {
    const [key, order] = this.state.sortField.split(" ");

    const json: { [index: string]: any } = {
      sender_id: getSenderIdFromToken(),
      filters: [],
      sort_keys: [{ key, order }],
      window_start: this.state.windowStart,
      window_size: this.state.windowSize,
      columns: [
        "id",
        "date",
        "created_at",
        "payor_name",
        "display_identifier",
        "currency",
        "amount_cents",
        "payment_amounts.amount_cents",
        "payment_amounts.invoice_number",
        "payment_amounts.invoice_identifier",
        "payment_amounts.invoice_display_number",
        "virtual_credit_cards.account_number",
        "virtual_credit_cards.expiry_date",
        "virtual_credit_cards.cvc2",
        "attributes.exported",
        "attributes.json.cc_info_link",
        "payments_portal_link",
        "transaction.token",
        "identifier",
        "updated_at",
        "return_reason",
        "credit_card_gateway_requests.authorization_token",
        "credit_card_gateway_request",
        "status",
        "error_message",
        "attributes.email_identifier"
      ]
    };

    let isSearching = false;
    const { searchText, showToday, show7Days, showNotExported, showPaid, showInProgress, showError } = this.state;
    if (showToday) {
      json["filters"].push({ key: "date", value: [-1, 0], ops: "RELATIVE" });
      isSearching = true;
    }
    if (show7Days) {
      json["filters"].push({ key: "date", value: [-7, 0], ops: "RELATIVE" });
      isSearching = true;
    }
    if (showNotExported) {
      json["filters"].push({
        key: "attributes.exported",
        value: false,
        ops: "EQ"
      });
      isSearching = true;
    }
    if (showPaid) {
      json["filters"].push({
        key: "status",
        value: "paid",
        ops: "EQ"
      });
      isSearching = true;
    }
    if (showInProgress) {
      json["filters"].push({
        key: "",
        value: [
          {
            key: "status",
            value: "in_progress",
            ops: "EQ"
          },
          {
            key: "status",
            value: "pending",
            ops: "EQ"
          }
        ],
        ops: "OR"
      });
      isSearching = true;
    }
    if (showError) {
      json["filters"].push({
        key: "",
        value: [
          {
            key: "status",
            value: "error%",
            ops: "SQLILIKE"
          },
          {
            key: "status",
            value: "reversal",
            ops: "EQ"
          }
        ],
        ops: "OR"
      });
      isSearching = true;
    }
    if (searchText !== null && searchText !== "") {
      json["search_string"] = searchText;
      isSearching = true;
    }
    return { isSearching, json };
  }

  // TODO PUSH LOWER
  getData() {
    const { isSearching, json } = this.getDataOptions();
    getSender()
      .then(response => {
        if (response && response.data) {
          this.setState({ hasMerchant: response.data.creditCardSettlementCurrencies.length > 0 });
        }
      })
      .catch(standardPromiseCatch(this.props.history));
    getPayments(json)
      .then(response => {
        this.setState({ isSearching, jsonData: response.data });
      })
      .catch(standardPromiseCatch(this.props.history));

  }

  handleMoreDetails(ids: number[]) {
    this.setState({ isPaymentDetail: true, ids: ids });
  }

  handleMarkPaid(ids: number[]) {
    let jsonData = this.state.jsonData;
    markPaid(PaymentListingPage.getUnprocessedOfSelectedIds(jsonData, ids))
      .then(response => {
        this.setState({ jsonData: null }, this.getData);
      })
      .catch(standardPromiseCatch(this.props.history));
  }

  getEmailFile(ids: number[]) {
    getEmail(ids)
      .then(response => {
        const emailData = response.data;
        this.setState({
          emailData,
          isDialogOpen: true,
          ids
        });
      })
      .catch(() => {
        const emailData = {
          to: [""],
          from: [""],
          subject: "",
          body: ""
        };
        this.setState({
          emailData,
          isDialogOpen: true,
          ids
        });
        standardPromiseCatch(this.props.history);
      });
  }

  getCustomFormats(ids: number[]) {
    getFormats()
      .then(response => {
        const formatData = response.data.records.map((value: IJsonRecord) => {
          return { title: value.name, value: value.id };
        });
        this.setState({
          formatData,
          isDialogOpen: formatData.length === 0 ? false : true,
          ids: ids
        });
      })
      .catch(standardPromiseCatch(this.props.history));
  }

  static getProcessedOfSelectedMethod(jsonData: IIndexJsonData | null, ids: number[]) {
    let selectedData = jsonData && jsonData.records ? jsonData.records.filter(record => (ids.indexOf(record.id) !== -1)) : null;
    return selectedData ? selectedData.filter(record => (record && record.status !== "paid")).length === selectedData.length : false;
  }

  static isProgressOrErrorStatus(reason: string) {
    return ["reversal", "refund", "error", "error_declined", "error_nsf", "in_progress"].includes(reason);
  }

  static getUnprocessedOfSelectedMethod(jsonData: IIndexJsonData | null, ids: number[]) {
    let selectedData = jsonData && jsonData.records ? jsonData.records.filter(record => (ids.indexOf(record.id) !== -1)) : null;
    return selectedData ? selectedData.filter(record => (!record || record.status === "paid") || PaymentListingPage.isProgressOrErrorStatus(record.status)).length === selectedData.length : false;
  }

  static getUnprocessedOfSelectedIds(jsonData: IIndexJsonData | null, ids: number[]) {
    let selectedData = jsonData && jsonData.records ? jsonData.records.filter(record => (ids.indexOf(record.id) !== -1)) : null;
    return selectedData ? selectedData.filter(record => (record && (!record.status || record.status === "new"))).map(record => record.id) : [];
  }

  static getProcessedOfSelectedIds(jsonData: IIndexJsonData | null, ids: number[]) {
    let selectedData = jsonData && jsonData.records ? jsonData.records.filter(record => (ids.indexOf(record.id) !== -1)) : null;
    return selectedData ? selectedData.filter(record => (!record || record.status === "paid")).map(record => record.id) : [];
  }

  static getPaymentIdentifierOfSelectedIds(jsonData: IIndexJsonData | null, ids: number[]) {
    let selectedData = jsonData && jsonData.records ? jsonData.records.filter(record => (ids.indexOf(record.id) !== -1)) : null;
    return selectedData ? selectedData.filter(record => record && record.attributes && !record.attributes.emailIdentifier).length === selectedData.length : false;
  }

  shouldExportBeDisabled(ids?: number[]) {
    let jsonData = this.state.jsonData;
    if (!ids) return true;
    return PaymentListingPage.getProcessedOfSelectedMethod(jsonData, ids);
  }

  shouldMarkBeDisabled(ids?: number[]) {
    let jsonData = this.state.jsonData;
    if (!ids) return true;
    return PaymentListingPage.getUnprocessedOfSelectedMethod(jsonData, ids);
  }

  shouldViewEmailBeInvisible(ids?: number[]) {
    let jsonData = this.state.jsonData;
    if (!ids) return true;
    return PaymentListingPage.getPaymentIdentifierOfSelectedIds(jsonData, ids);
  }

  renderTopBar(): React.ReactNode {
      if (sessionStorage.showSuccess === "1" || sessionStorage.showSuccess === "2") {
        setTimeout(() => {
          sessionStorage.removeItem('showSuccess');
          this.forceUpdate();
        }, sessionStorage.showSuccess === "2" ? 30000 : 20000);

        return (
          <StyledSuccess icon={false} severity="success" >
            {sessionStorage.showSuccess === "2" ? "Your password has been changed successfully. You are now signed in." : "Your password and security questions have been updated."}
          </StyledSuccess>
        );
      } else {
        return null;
      }
  }

  renderOverlayContent(): React.ReactNode {
    const { isPaymentDetail, isDialogOpen, formatData, ids, emailData } = this.state;
    let jsonData = this.state.jsonData;

    if (emailData && isDialogOpen) {
      return (
        <EmailDialog
          emailDetails={emailData}
          onClose={() => {
            this.setState({ emailData: null, isDialogOpen: false });
          }}
          refetchData={() => {
            this.getEmailFile(ids);
          }}
        />
      );
    }

    if (isPaymentDetail && jsonData && jsonData.records && ids) {
      let jsonRecord = jsonData.records.filter(record => (!record || record.id === ids[0]))[0];
      return (
        <PaymentListingProcessModal
          onClose={() => {this.setState({ isPaymentDetail: false });}}
          onConfirm={() => {this.setState({ isPaymentDetail: false });}}
          jsonData={jsonRecord}
        />
      );
    } else if (isDialogOpen && formatData && ids) {
      return (
        <ExportDialog
          onClose={() => {
            this.setState({ isDialogOpen: false, formatData: null });
          }}
          onConfirm={format => {
            this.setState({ isDialogOpen: false }, () => {
              this.onClickExportPayments(PaymentListingPage.getProcessedOfSelectedIds(jsonData, ids), format);
            });
          }}
          list={formatData}
        />
      );
    }
  }

  paymentPageLayoutProps(): IPageLayoutProps {
    let props = pageLayoutProps();
    let jsonData = this.state.jsonData;
    let hasMerchant = this.state.hasMerchant;
    let link = jsonData && jsonData.records && jsonData.records[0] ? jsonData.records[0].payments_portal_link : "";
    props.applicationLink = hasMerchant ? link : "";
    return props;
  }

  renderRowConfig(): IRowConfig {
    return {
      identifier: "payments",
      options: [
        {
          heading: { text: "Customer" },
          width: "16.81%",
          dataType: "text",
          attribute: "payor_name",
          className: "primary"
        },
        {
          heading: { text: "Invoice #" },
          width: "13.24%",
          dataType: "cell",
          cardCell: this.renderInvoiceCell
        },
        {
          heading: {
            text: "Payment Date",
            sortDirection: () => {
              return this.onClickSortDirection("date");
            },
            clickHandler: () => {
              this.onClickSort("date");
            }
          },
          width: "16.87%",
          dataType: "date",
          attribute: "date",
          parseFormat: "YYYY-MM-DD",
          displayFormat: "MM/DD/YYYY",
          className: "secondary"
        },
        {
          heading: {
            text: "Payment Created At",
            sortDirection: () => {
              return this.onClickSortDirection("created_at");
            },
            clickHandler: () => {
              this.onClickSort("created_at");
            }
          },
          width: "21.87%",
          dataType: "date",
          attribute: "created_at",
          parseFormat: "YYYY-MM-DD HH:mm:ss",
          displayFormat: "MM/DD/YYYY HH:mm",
          className: "secondary"
        },
        {
          heading: { text: "External Payment #" },
          width: "11.55%",
          className: "secondary",
          dataType: "cell",
          cardCell: this.renderPaymentCell
        },
        {
          heading: { text: "Exported" },
          width: "8.56%",
          align: "center",
          dataType: "cell",
          cardCell: this.renderExportedCell
        },
        {
          heading: { text: "Payment Status" },
          width: "9.81%",
          align: "center",
          dataType: "cell",
          cardCell: this.renderPaymentStatusCell
        },
        {
          heading: {
            text: "Amount",
            sortDirection: () => {
              return this.onClickSortDirection("amount_cents");
            },
            clickHandler: () => {
              this.onClickSort("amount_cents");
            }
          },
          width: "16.37%",
          align: "right",
          dataType: "amount",
          attribute: "amount_cents",
          className: "emphasized"
        },
        {
          heading: { text: "" },
          width: "4.2%",
          align: "center",
          dataType: "menu",
          menuOptions: this.renderRowMenuOptions
        }
      ],
      subCard: this.state.showCardDetails
        ? this.renderSubCardConfig()
        : undefined
    };
  }

  renderRowMenuOptions(record: IJsonRecord): IPopoverAction[] {
    return [
      {
        text: "View Email",
        clickHandler: () => {
          this.getEmailFile([record.id]);
        },
        invisible: Boolean(this.shouldViewEmailBeInvisible([record.id]))
      },
      {
        text: "Export",
        clickHandler: () => {
          this.getCustomFormats([record.id]);
        },
        disabled: Boolean(this.shouldExportBeDisabled([record.id])),
      },
      {
        text: "Mark as Paid",
        clickHandler: () => {
          this.handleMarkPaid([record.id]);
        },
        disabled: Boolean(this.shouldMarkBeDisabled([record.id])),
      }
    ];
  }

  renderSelectionConfig(ids?: number[]): ISelectionConfig {
    return {
      actions:
        [
          {
            text: "Export Selected",
            handler: this.getCustomFormats,
            disabled: this.shouldExportBeDisabled(ids)
          },
          {
            text: "Mark as Paid",
            handler: this.handleMarkPaid,
            disabled: this.shouldMarkBeDisabled(ids)
          }
        ],
      handler: this.renderSelectionConfig
    };
  }

  renderFilterConfig(): IFilterConfig {
    return {
      handler: this.onClickFilter,
      initialFilterState: { showCardDetails: this.state.showCardDetails },
      filters: [
        {
          key: "time",
          type: "pill",
          items: [
            { key: "showToday", text: "Show Today" },
            { key: "show7Days", text: "Last 7 Days" }
          ]
        },
        {
          key: "divider",
          type: "divider"
        },
        {
          key: "status",
          type: "pill",
          items: [
            { key: "showPaid", text: "Paid" },
            { key: "showInProgress", text: "In Progress" },
            { key: "showError", text: "Error" }
          ]
        },
        {
          key: "divider",
          type: "divider"
        },
        {
          key: "flags",
          type: "checkbox",
          items: [{ key: "showNotExported", text: "Not Exported" }]
        },
        {
          key: "split",
          type: "split"
        },
        {
          key: "cardDetails",
          type: "switch",
          items: [{ key: "showCardDetails", text: "Show Card Details" }]
        }
      ]
    };
  }

  renderPageConfig(): IPageConfig {
    let pgNumber =
      Math.ceil(this.state.windowStart / this.state.windowSize) + 1;

    return {
      handler: this.onClickPageChange,
      pageNumber: pgNumber
    };
  }

  renderRowLimitConfig(): IRowLimitConfig {
    return {
      handler: this.onClickRowChange,
      rowLimit: this.state.windowSize
    };
  }

  renderSubCardConfig(): ICardOption {
    return {
      heading: {},
      className: "secondary",
      dataType: "cell",
      width: "95%",
      cardCell: record => {
        const { virtual_credit_cards, attributes } = record;
        let link =
          (attributes &&
            attributes.json &&
            JSON.parse(attributes.json).cc_info_link) ||
          "";

        const cardNumber =
          virtual_credit_cards &&
          virtual_credit_cards.account_number &&
          formatCreditCard(virtual_credit_cards.account_number);

        if (cardNumber) {
          const expiry = virtual_credit_cards.expiry_date;
          const cvv = virtual_credit_cards.cvc2;

          return (
            <CardDetails id={`card_details_${record.id}`}>
              <StyledPayment />
              {link === "" ? (
                <span data-card-number>{cardNumber}</span>
              ) : (
                <HoverLink text={cardNumber} link={link} />
              )}
              {expiry && (
                <span data-card-expiry>
                  {formatNormalUtcDate(expiry, "MM/YY")}
                </span>
              )}
              {cvv && <span data-card-cvv>{cvv}</span>}
              <StyledMoreDetails onClick={() => this.handleMoreDetails([record.id])}>More Details</StyledMoreDetails>&nbsp;<StyledMoreDetailsArrow/>
            </CardDetails>
          );
        } else {
          return (
            <CardDetails id={`card_details_${record.id}`}>
              <StyledPayment />
              <span data-card-number>{"No Card Details Available"}</span>
            </CardDetails>
          );
        }
      }
    };
  }

  renderAggregatesConfig(): ReadonlyArray<IAggregateConfig> {
    return [
      {
        key: "payments",
        data: [
          {
            title: "Payments",
            value: json => json.num_records.toString(10) || "-",
            visible: (_) => true,
          },
          {
            title: "Total",
            value: json => {
              const totalSum = json.aggregates && json.aggregates["total"];
              return totalSum ? centsToDisplay(totalSum, json.records[0].currency) : "-";
            },
            visible: (json) => {
              const uniqueCurrencies: Set<string> = new Set(json.records.map<string>(record => record.currency));
              return uniqueCurrencies.size === 1;
            }
          }
        ]
      }
    ];
  }

  renderInvoiceCell(record: IJsonRecord) {
    return <InvoiceCell record={record} />;
  }

  renderExportedCell(record: IJsonRecord) {
    const exported = Boolean(
      record && record.attributes && record.attributes.exported
    );
    return exported ? (
      <span>
        <PositiveCheckCircle />
      </span>
    ) : undefined;
  }

  renderPaymentStatusCell(record: IJsonRecord) {
    switch (record.status) {
      case "paid":
        return (
          <div className="secondary">
            <GreenPaid>Paid</GreenPaid>
          </div>
        );
      case "error":
      case "error_declined":
      case "error_nsf":
      case "reversal":
        return (
          <div className="secondary">
            <HoverLink text={getUpperName(record.status)}
            toolTipText={record.return_reason || record.error_message || "No message available"}
            link={""}
            placement={"bottom-start"} />
          </div>
        );
      case null:
      case undefined:
        return undefined;
      default:
        return (
          <div className="secondary">
            {getUpperName(record.status)}
          </div>
        );
    }
  }

  renderPaymentCell(record: IJsonRecord) {
    return (
      <StyledPaymentNo>{record.display_identifier}</StyledPaymentNo>
    );
  }

  render() {
    return (
      <ListingPage
        heading="Payments"
        json={this.state.jsonData}
        row={this.renderRowConfig()}
        selection={this.renderSelectionConfig()}
        aggregates={this.renderAggregatesConfig()}
        filter={this.renderFilterConfig()}
        pageSelect={this.renderPageConfig()}
        rowLimitSelect={this.renderRowLimitConfig()}
        search={this.onChangeSearch}
        isBanner={getPreviewModeFromToken()}
        pageLayoutProps={this.paymentPageLayoutProps()}
        overlayContent={this.renderOverlayContent}
        isSearching={this.state.isSearching}
        topBar={this.renderTopBar()}
      />
    );
  }
}

export default withRouter(PaymentListingPage);
