import styled, { DefaultThemeProps } from "styled-components";

type StyledProps = { minwidth?: string } & DefaultThemeProps;

const StyledAnchor = styled.a<StyledProps>`
  height: 44px;
  min-width: ${props => props.minwidth || "228px"};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => props.theme.providedColor};
  box-shadow: 0px 2px 6px ${props => props.theme.shadowColor};
  border-radius: 3px;
  margin-top: 32px;

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: ${props => props.theme.activeText};
  }
`;
StyledAnchor.displayName = "StyledAnchor";

export default StyledAnchor;
