import React from "react";
import styled from "styled-components";
import StyledButton from "./StyledButton";
import VerticalAlignBottom from "@material-ui/icons/VerticalAlignBottom";

const IconlessDownloadButton = styled(StyledButton)`
  padding: 0;
  justify-content: center;
  width: 44px;
`;
IconlessDownloadButton.displayName = "IconlessDownloadButton";

const DownloadButton = (props: any) => {
  return (
    <IconlessDownloadButton
      {...props}
    >
      <VerticalAlignBottom />
    </IconlessDownloadButton>
  );
}

export default DownloadButton;
