import React, {Component, Fragment} from "react";
import {History} from "history";
import {RouteComponentProps, withRouter} from "react-router-dom";
import PageLayout from "../../../common/components/PageLayout/PageLayout";
import {pageLayoutProps} from "../../lib/helpers";
import styled, {DefaultThemeProps} from "styled-components";
import LoginFooter from "../LoginFooter";
import { clearToken } from "../../../common/lib/helpers";

const BoxDiv = styled.div<DefaultThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  max-width: 420px;
  padding: 20px;

  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
`;
BoxDiv.displayName = "BoxDiv";

const BoxDivMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
`;
BoxDivMessageWrapper.displayName = "BoxDivMessageWrapper";

const BoxMessageTitle = styled.span<DefaultThemeProps>`
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;

  color: ${props => props.theme.primaryText};
  text-align: center;
  width: 100%;
`;
BoxMessageTitle.displayName = "BoxMessageTitle";

const PageContentDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;
PageContentDiv.displayName = "PageContentDiv";

const BoxDivButtonWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
`;
BoxDivButtonWrapper.displayName = "BoxDivButtonWrapper";

type PageProps = {
    padding: string;
    renderBack?: boolean;
    history: History;
} & RouteComponentProps;

export class LockPage extends Component<PageProps> {

    constructor(props: PageProps) {
        super(props);
        clearToken();
    }

    renderBox = () => {
        return (
            <BoxDiv>
                <BoxDivMessageWrapper>
                    <BoxMessageTitle>Your account is locked</BoxMessageTitle>
                </BoxDivMessageWrapper>
                <p/>
                <BoxDivButtonWrapper>
                    To recover your account, please contact Versapay by phone at 1 866 999 8729 or by email support@versapay.com.
                </BoxDivButtonWrapper>
            </BoxDiv>
        );
    };

    pageContent = (): React.ReactNode => {
        return (
            <Fragment>
                <PageContentDiv>
                    {this.renderBox()}
                    {LoginFooter()}
                </PageContentDiv>
            </Fragment>
        );
    };

    render() {
        return (
            <PageLayout
                noNavLinks
                noFooter={true}
                pageLayoutProps={pageLayoutProps()}
                scrollableContent={this.pageContent}
            />
        );
    }
}

export default withRouter(LockPage);
