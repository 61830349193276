import {
  postJson,
  getSenderIdFromToken
} from "../lib/helpers";
import { AxiosResponse } from "axios";

const UPDATE_PAY_BASE_URL = "/updatepayments/payments/mark/sender";

export const markPaid = (ids: number[]): Promise<AxiosResponse> => {
  const senderId = getSenderIdFromToken();

  return postJson(`${UPDATE_PAY_BASE_URL}/${senderId}`, ids);
};
