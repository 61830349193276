import React, { memo, useState } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";
import { IJsonRecord } from "../../common/types";

const StyledExpandHide = styled.span<DefaultThemeProps>`
  cursor: pointer;
  color: ${props => props.theme.interactiveText};
`;
StyledExpandHide.displayName = "StyledExpandHide";

const InvoiceSpan = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 75px;
`;
InvoiceSpan.displayName = "InvoiceSpan";

const InvoiceCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
InvoiceCellWrapper.displayName = "InvoiceCellWrapper";

type InvoiceCellProps = { record: IJsonRecord, isShowing?: boolean | undefined };

function InvoiceCell({ record, isShowing }: InvoiceCellProps) {
  const [showMore, setShowMore] = useState(false);

  let numberOfInvoices = record.payment_amounts.length;
  let recordID = record.id;

  const masking = isShowing === undefined ? false : !isShowing;

  if (numberOfInvoices === 1) {
    return (
      <InvoiceSpan className="secondary">
        {record.payment_amounts[0].invoice_number}
      </InvoiceSpan>
    );
  } else if (numberOfInvoices >= 10) {
    return (
      <InvoiceSpan className="secondary">
        {numberOfInvoices} Invoices
      </InvoiceSpan>
    );
  }

  return (
    <InvoiceCellWrapper className="secondary">
      {!showMore ? (
        <InvoiceSpan id={`invoice_hidden_${recordID}`}>
          {numberOfInvoices} Invoices
        </InvoiceSpan>
      ) : (
        record.payment_amounts.map(
          (value: { [index: string]: any }, index: string) => {
            return (
              <InvoiceSpan
                id={`invoice_${recordID}_${index}`}
                key={`invoice_${recordID}_${index}`}
              >
                {value.invoice_number}
                <br />
              </InvoiceSpan>
            );
          }
        )
      )}
      <StyledExpandHide
        id={`${!showMore ? `show_btn_${recordID}` : `hide_btn_${recordID}`}`}
        onClick={() => {
          setShowMore(!showMore);
        }}
      >
        {!masking ? !showMore ? "Show" : "Hide" : undefined}
        {!masking ? !showMore ? <KeyboardArrowDown /> : <KeyboardArrowUp /> : undefined}
      </StyledExpandHide>
    </InvoiceCellWrapper>
  );
}

export default memo(InvoiceCell);
