import {
  postJson,
  getJson,
  mockDataPromise,
  getSenderIdFromToken,
  deleteJson
} from "../lib/helpers";
import { AxiosResponse } from "axios";
import { USE_MOCK_JSON } from "../lib/constants";
import MOCK_FORMAT_DATA from "../assets/paymentExportFormats.json";
import MOCK_SINGLE_FORMAT_DATA from "../assets/paymentExportDuplicateFormat.json";

const FORMAT_BASE_URL = "/manageexports/format/";
const FORMAT_NEW_BASE_URL = `${FORMAT_BASE_URL}sender`;

export const createFormat = (format: any): Promise<AxiosResponse> => {
  if (USE_MOCK_JSON) {
    return new Promise(resolve => {
      let newFormat = {
        id: format.id,
        name: format.name,
        multi_line_invoice: false,
        description: format.description,
        created_at: 1554933180111,
        created_by: "dude@versapay.com",
        columns: format.columns
      };
      setTimeout(() => {
        resolve(mockDataPromise(newFormat));
      }, 250);
    });
  }
  return postJson(FORMAT_BASE_URL, format);
};

export const getFormats = (): Promise<any> => {
  if (USE_MOCK_JSON) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mockDataPromise(MOCK_FORMAT_DATA));
      }, 250);
    });
  }
  return getJson(`${FORMAT_NEW_BASE_URL}/${getSenderIdFromToken()}`);
};

export const getFormat = (id: number): Promise<AxiosResponse> => {
  if (USE_MOCK_JSON) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mockDataPromise(MOCK_SINGLE_FORMAT_DATA));
      }, 250);
    });
  }
  return getJson(
    `${FORMAT_NEW_BASE_URL}/${getSenderIdFromToken()}/format/${id}`
  );
};

export const deleteFormat = (id: number): Promise<AxiosResponse> => {
  return deleteJson(
    `${FORMAT_NEW_BASE_URL}/${getSenderIdFromToken()}/format/${id}`
  );
};

export const getColumns = (): Promise<any> => {
  return getJson(`${FORMAT_BASE_URL}column/issuer/${getSenderIdFromToken()}`);
};
