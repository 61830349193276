import React, { useState } from "react";
import styled from "styled-components";
import { IDropdownItem } from "../types";
import Truncate from "react-truncate";

import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";

type DropdownProps = {
  initialValue?: string;
  list?: ReadonlyArray<IDropdownItem | any>;
  handleValue?(value: IDropdownItem): void;
  handleReq?(value: boolean): void;
  isOpen?: boolean;
  isPlaceholder?: boolean;
  title?: string;
  placeholder?: string;
  required?: boolean;
  id?: string;
  width?: number;
  height?: string;
};

const DropdownTitleWrapper = styled.div<DropdownProps>`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 5px;

  width: ${props => (props.width ? `${props.width}px` : "240px")};
  margin: auto;
`;
DropdownTitleWrapper.displayName = "DropdownTitleWrapper";

const DropdownTitleText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: ${props => props.theme.secondaryText};
`;
DropdownTitleText.displayName = "DropdownTitleText";

const DropdownTitleRequiredText = styled.div`
  font-size: 11px;
  line-height: 13px;

  color: ${props => props.theme.negativeText};
`;
DropdownTitleRequiredText.displayName = "DropdownTitleRequiredText";

const DropdownWrapper = styled.div<DropdownProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.width ? `min-width: ${props.width}px` : "min-width: 240px;"};
  ${props => (props.width ? `width: ${props.width}px` : "")};

  margin: auto;

  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
`;

const DropdownHeader = styled.div<DropdownProps>`
  cursor: pointer;
  height: auto;
  color: ${props => props.theme.secondaryText};
  position: relative;

  width: ${props => (props.width ? `${props.width}px` : "240px")};
  height: ${props => (props.height ? props.height : "50px")};

  border: 1px solid ${props => props.theme.borderColor};
  box-sizing: border-box;
  border-radius: ${props => (props.isOpen ? "4px 4px 0px 0px" : "4px")};
`;
DropdownHeader.displayName = "DropdownHeader";

const DropdownSelectedValue = styled.div`
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: row;

  svg {
    margin: auto 8px;
    height: 32px;
    width: 32px;
  }
`;
DropdownSelectedValue.displayName = "DropdownSelectedValue";

const DropdownTitle = styled.div<DropdownProps>`
  flex: 1;
  display: flex;
  align-self: flex-start;
  margin: auto;
  margin-left: 16px;
  margin-right: 16px;
  font-size: 14px;
  color: ${props =>
    props.isPlaceholder ? props.theme.secondaryText : props.theme.primaryText};
`;
DropdownTitle.displayName = "DropdownTitle";

const StyledPopover = styled(Popover)`
  z-index: 5;
  left: 140px;
  border: 1px solid ${props => props.theme.borderColor};
  box-sizing: border-box;
  border-radius: 4px;

  .MuiPaper-rounded {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .MuiPaper-elevation8 {
    box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
  }
`;

const DropdownList = styled.ul<DropdownProps>`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;

  ::-webkit-scrollbar {
    display: none;
  }
`;
DropdownList.displayName = "DropdownList";

const DropdownItem = styled.li<DropdownProps>`
  cursor: pointer;
  color: ${props => props.theme.primaryText};
  ${props => (props.width ? `width: ${props.width - 2}px` : "width: 239px")};
  padding: 10px 16px;
`;
DropdownItem.displayName = "DropdownItem";

const DropdownItemText = styled.span`
  font-size: 14px;
`;
DropdownItemText.displayName = "DropdownItemText";

export default function Dropdown({
  list,
  initialValue,
  handleValue,
  title,
  required,
  placeholder,
  handleReq,
  id,
  width,
  height
}: DropdownProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [value, setValue] = useState(
    initialValue ? initialValue : placeholder ? placeholder : ""
  );
  const [isRequired, setReqBool] = useState(required ? true : false);

  function onClickDropdownClose() {
    setAnchorEl(null);
  }

  function onClickDropdownOpen(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    setAnchorEl(event.currentTarget);
  }

  function onClickToggleItem(val: IDropdownItem) {
    setValue(val.title);
    setReqBool(false);
    handleValue && handleValue(val);
    handleReq && handleReq(true);
    onClickDropdownClose();
  }

  const open = Boolean(anchorEl);
  const idStem = id ? id + "_" : "";

  return (
    <div id={`${idStem}dropdown_wrapper`}>
      <DropdownTitleWrapper id="dropdown_title_wrapper" width={width}>
        {title && (
          <DropdownTitleText id="dropdown_title_text">
            {title}
          </DropdownTitleText>
        )}
        {isRequired && (
          <DropdownTitleRequiredText>Required</DropdownTitleRequiredText>
        )}
      </DropdownTitleWrapper>
      <DropdownWrapper width={width}>
        <DropdownHeader
          id={`${idStem}dropdown_header`}
          onClick={onClickDropdownOpen}
          isOpen={open}
          width={width}
          height={height}
        >
          <DropdownSelectedValue>
            <DropdownTitle isPlaceholder={value === placeholder}>
              <Truncate lines={2}>{value}</Truncate>
            </DropdownTitle>
            <ExpandMore />
          </DropdownSelectedValue>
        </DropdownHeader>
        <StyledPopover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left"
          }}
          open={open}
          onClose={onClickDropdownClose}
          anchorEl={anchorEl}
          transitionDuration={0}
        >
          <DropdownList>
            {list &&
              list.map((item, index) => (
                <DropdownItem
                  key={`${idStem}dropdown_item_${index}`}
                  id={`${idStem}dropdown_item_${index}`}
                  onClick={() => onClickToggleItem(item)}
                  width={width}
                >
                  <DropdownItemText id={`${idStem}dropdown_item_${index}_text`}>
                    {item.title}
                  </DropdownItemText>
                </DropdownItem>
              ))}
          </DropdownList>
        </StyledPopover>
      </DropdownWrapper>
    </div>
  );
}
