import React from "react";
import styled, { DefaultThemeProps } from "styled-components";
import USER_SILHOUETTE from "../assets/userSilhouette.png";

export type ProfileIconSize = "small" | "medium" | "large";

type Props = {
  size?: ProfileIconSize;
};

type StyleProps = {
  size: ProfileIconSize;
} & DefaultThemeProps;

const sizes = {
  small: { wrapper: "40px", img: "16px" },
  medium: { wrapper: "60px", img: "23.5px" },
  large: { wrapper: "100px", img: "40px" }
};

const CircleWrapper = styled.div<StyleProps>`
  height: ${props => sizes[props.size].wrapper};
  width: ${props => sizes[props.size].wrapper};
  background: ${props => props.theme.providedColor};
  border: 4px solid ${props => props.theme.inactiveBackground};
  box-shadow: 0px 2px 6px ${props => props.theme.shadowColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: ${props => sizes[props.size].img};
  }
`;
CircleWrapper.displayName = "CircleWrapper";

const ProfileIcon = ({ size }: Props) => (
  <CircleWrapper size={size || "small"}>
    <img src={USER_SILHOUETTE} alt="User" />
  </CircleWrapper>
);

export default ProfileIcon;
