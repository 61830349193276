import React, { ReactNode, Fragment } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import Popper from "@material-ui/core/Popper";
import FullPageOverlay from "./FullPageOverlay";
import { IPopoverAction } from "../types";
import POPOVER_ARROW from "../assets/popoverArrow.svg";

type Props = {
  trigger: ReactNode;
  actions: ReadonlyArray<IPopoverAction>;
  triggerComponent?: React.ComponentType<any>;
  triggerComponentProps?: any;
};

const TriggerWrapper = styled.span`
  cursor: pointer;
`;
TriggerWrapper.displayName = "TriggerWrapper";

const PopoverActions = styled.div<DefaultThemeProps>`
  margin-bottom: 5px;
  min-width: 255px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;

  .options {
    background: ${props => props.theme.solidBackground};
    border: 1px solid ${props => props.theme.borderColor};
    box-shadow: 0px 3px 6px ${props => props.theme.shadowColor};
    border-radius: 3px;
  }

  .option {
    font-weight: normal;
    cursor: pointer;
    padding: 14px;
    border-bottom: 1px solid ${props => props.theme.borderColor};
    color: ${props => props.theme.providedColor};
  }

  .option:last-child {
    border-bottom: none;
  }

  .option.negative {
    color ${props => props.theme.negativeText};
  }

  .option.positive {
    color ${props => props.theme.positiveText};
  }

  .option.disabled {
    opacity: 0.3;
    cursor: default;
  }
`;
PopoverActions.displayName = "PopoverActions";

const StyledArrowPopper = styled.span`
  position: absolute;
  font-size: 7px;
  width: 3em;
  height: 3em;
  &::before: {
    content: '""';
    margin: auto;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
  }
`;

const StyledPopper = styled(Popper)`
  z-index: 5;

  &[x-placement*="bottom"] {
    margin-top: 10px;
    & ${StyledArrowPopper} {
      left: 225px !important;
      margin-top: -0.9em;
    }
    & ${PopoverActions} {
      margin-top: 2px;
    }
  }

  &[x-placement*="top"] {
    margin-bottom: 10px;
    & ${StyledArrowPopper} {
      transform: rotate(180deg);
      bottom: 0;
      margin-bottom: -0.9em;
    }
    & ${PopoverActions} {
      margin-bottom: 2px;
    }
  }

  &[x-placement*="right"] {
    margin-left: 10px;
    & ${StyledArrowPopper} {
      transform: rotate(-90deg);
      margin-left: -0.9em;
    }
    & ${PopoverActions} {
      margin-left: 2px;
    }
  }

  &[x-placement*="left"] {
    margin-right: 10px;

    & ${StyledArrowPopper} {
      transform: rotate(90deg);
      right: 0;
      width: 1em;
    }
    & ${PopoverActions} {
      margin-right: 2px;
    }
  }
`;

const Popover = ({
  trigger,
  triggerComponent,
  triggerComponentProps,
  actions
}: Props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);

  function isOpen(): boolean {
    return Boolean(anchorEl);
  }

  function onClickClosePopover() {
    setAnchorEl(null);
  }

  function getArrowRef(node: any) {
    setArrowRef(node);
  }

  function onClickOpenPopover(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function onClickAction(action: IPopoverAction) {
    action.clickHandler();
    onClickClosePopover();
  }

  function renderTrigger() {
    const Component = triggerComponent ? triggerComponent : TriggerWrapper;
    return (
      <Component
        onClick={onClickOpenPopover}
        {...triggerComponentProps}
        className={isOpen() ? "popoverOpen" : "popoverClosed"}
      >
        {trigger}
      </Component>
    );
  }

  function renderActions() {
    return (
      actions &&
      actions.filter(action => !action.invisible).map((action, idx) => {
        let handleClick = true;
        let classNames = ["option"];
        if (action.negative) classNames.push("negative");
        if (action.positive) classNames.push("positive");
        if (action.disabled) {
          classNames.push("disabled");
          handleClick = false;
        }

        return (
          <div
            key={`popover_${idx}`}
            className={classNames.join(" ")}
            onClick={handleClick ? () => onClickAction(action) : undefined}
          >
            {action.text}
          </div>
        );
      })
    );
  }

  return (
    <Fragment>
      {renderTrigger()}
      {isOpen() && (
        <Fragment>
          <FullPageOverlay onClick={onClickClosePopover} />
          <StyledPopper
            open
            anchorEl={anchorEl}
            modifiers={{
              flip: {
                enabled: true
              },
              arrow: {
                enabled: true,
                element: arrowRef
              }
            }}
            placement="bottom-end"
          >
            <StyledArrowPopper ref={getArrowRef}>
              <img src={POPOVER_ARROW} alt="popover_arrow" />
            </StyledArrowPopper>
            <PopoverActions>
              <div className="options">{renderActions()}</div>
            </PopoverActions>
          </StyledPopper>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Popover;
