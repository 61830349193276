import React, { PureComponent } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { pick } from "lodash";
import { IFilterPanelState, IFilterSwitchGroup } from "../types";
import Switch from "./Switch";

const FilterSwitch = styled.div<DefaultThemeProps>`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  label {
    cursor: pointer;
    color: ${props => props.theme.secondaryText};
    margin-bottom: 0;
    margin-right: 10px;
  }
`;
FilterSwitch.displayName = "FilterSwitch";

const Switches = styled.div<DefaultThemeProps>`
  display: flex;
  justify-content: center;
  ${FilterSwitch} {
    margin-right: 40px;
  }
  ${FilterSwitch}:last {
    margin-right: 0;
  }
`;
Switches.displayName = "Switches";

type Props = {
  filterState: IFilterPanelState;
  filterGroup: IFilterSwitchGroup;
  applyFilters: (filters: IFilterPanelState) => void;
};

export default class FilterSwitches extends PureComponent<
  Props,
  IFilterPanelState
> {
  state = pick(
    this.props.filterState,
    this.props.filterGroup.items.map(item => item.key)
  );

  onClickToggleSwitch = (key: string): void => {
    this.setState(
      Object.assign({}, this.state, { [key]: !Boolean(this.state[key]) }),
      () => {
        this.props.applyFilters(
          Object.assign({}, this.props.filterState, this.state)
        );
      }
    );
  };

  renderSwitch = (
    text: string,
    key: string,
    active: boolean
  ): React.ReactNode => {
    const id = `filter_switch_option_${this.props.filterGroup.key}_${key}`;
    return (
      <FilterSwitch
        key={id}
        id={id}
        data-selected={active}
        onClick={() => this.onClickToggleSwitch(key)}
      >
        <label>{text}</label>
        <Switch checked={active} />
      </FilterSwitch>
    );
  };

  render() {
    return (
      <Switches>
        {this.props.filterGroup.items.map(filterOption =>
          this.renderSwitch(
            filterOption.text,
            filterOption.key,
            this.state[filterOption.key] as boolean
          )
        )}
      </Switches>
    );
  }
}
