import React, { Component } from "react";
import { Route, Redirect, Switch, BrowserRouter } from "react-router-dom";
import { toast, cssTransition } from "react-toastify";
import MastercardRoutes from "./enablement/lib/routes";
import { authenticated } from "./enablement/lib/helpers";
import "react-toastify/dist/ReactToastify.min.css";

toast.configure({
  autoClose: 2500,
  closeButton: false,
  closeOnClick: false,
  draggable: false,
  hideProgressBar: true,
  position: toast.POSITION.TOP_CENTER,
  transition: cssTransition({
    enter: "Toastify__slide-enter",
    exit: "Toastify__slide-exit",
    duration: [300, 300],
    appendPosition: true
  })
});

const AuthenticatedRoute = ({ component, render, fallback, ...rest }) => {
  const componentToRender = props => {
    if (authenticated() === true) {
      return <Route component={component} render={render} {...props} />;
    }
    return <Redirect to={fallback} />;
  };
  return <Route {...rest} render={props => componentToRender(props)} />;
};

class App extends Component {
  routeElement(props, idx, prefix, fallback) {
    const key = `${prefix}_route_${idx}`;
    const { open, ...routeProps } = props;
    if (routeProps.from) {
      return <Redirect key={key} {...routeProps} />;
    } else if (open) {
      return <Route key={key} {...routeProps} />;
    } else {
      return (
        <AuthenticatedRoute key={key} fallback={fallback} {...routeProps} />
      );
    }
  }

  render() {
    return (
      <BrowserRouter basename="app">
        <Switch>
          <Redirect exact from="/" to="/enablement" />
          {MastercardRoutes.map((props, idx) =>
            this.routeElement(props, idx, "enablement", "/enablement/login")
          )}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
