import React from "react";
import styled from "styled-components";
import {pageLayoutProps} from "../lib/helpers";
import VERSAPAY_LOGO from "../assets/versapayLogo.svg";

const StyledFooter = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  padding-top: 10px;
  font-size: 10px;
  justify-content: center;
  color: ${props => props.theme.secondaryText};
`;
StyledFooter.displayName = "StyledFooter";

const StyledLink = styled.a`
  color: #616161;
  font-weight: 400;
  text-align: center;
  position: relative;

  &:visited {
    color: blue;
  }

  &:hover {
    color: darkblue;
  }
`;
StyledLink.displayName = "StyledLink";

const StyledSpan = styled.span`
  margin: 5px 0px;
`;
StyledSpan.displayName = "StyledSpan";

export default function LoginFooter() {
  return (
    <StyledFooter>
      <StyledSpan id="footer_line1">
        <StyledLink href={"https://arc.versapay.com/faqs/"}>Support</StyledLink>
        &nbsp;|&nbsp;
        <StyledLink href={"https://uat.versapay.com/privacy_en.html"}>Privacy Policy</StyledLink>
      </StyledSpan>
      <StyledSpan id="application_footer">
        &nbsp;&copy; {pageLayoutProps().applicationCopyright.split(" ")[0]} &nbsp;
        <img
            id="application_logo"
            src={VERSAPAY_LOGO}
            height="15px"
            alt={pageLayoutProps().applicationCopyright}
        />
      </StyledSpan>
    </StyledFooter>
  );
}
