import React, { Component, Fragment } from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";
import Dropdown from "../../../common/components/Dropdown";
import StyledButton from "../../../common/components/StyledButton";
import Checkbox from "../../../common/components/Checkbox";
import PageLayout from "../../../common/components/PageLayout/PageLayout";

import Input from "../../../common/components/Input";
import styled, { DefaultThemeProps } from "styled-components";
import { ErrorType, IDropdownItem } from "../../../common/types";
import { REG_EXP } from "../../../common/lib/constants";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import StyledAlert, { StyledSuccess } from "../StyledAlert";

import {
  getProfile,
  getSecurityQuestions,
  updatePassword,
  updateProfile,
  updateQAs,
} from "../../services/userServices";
import {
  getUserFromToken,
  getPreviewModeFromToken,
  pageLayoutProps,
} from "../../lib/helpers";

const BoxDiv = styled.div<DefaultThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: start;

  min-width: 280px;
  max-width: 1200px;
  min-height: 300px;

  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
`;
BoxDiv.displayName = "BoxDiv";

const EditWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 60px 60px 40px 60px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
EditWrapper.displayName = "EditWrapper";

const EmailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
`;
EmailWrapper.displayName = "EmailWrapper";

const PasswordReqSpan = styled.span`
  cursor: default;
  margin-left: 10px;
  font-size: 12px;
  color: #5a5a5a;
`;
PasswordReqSpan.displayName = "PasswordReqSpan";

const PasswordReqWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 10px;
`;
PasswordReqWrapper.displayName = "PasswordReqWrapper";

const PasswordReqRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;
PasswordReqRowWrapper.displayName = "PasswordReqRowWrapper";

const PasswordReqItemWrapper = styled(PasswordReqRowWrapper)`
  align-items: center;
  width: 150px;
  margin-bottom: 10px;
`;
PasswordReqItemWrapper.displayName = "PasswordReqItemWrapper";

const UsersDialogRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 14px 14px 0px;
`;
UsersDialogRowWrapper.displayName = "UsersDialogRowWrapper";

const SecurityDialogRowWrapper = styled(UsersDialogRowWrapper)`
  margin-bottom: 7px;
  padding: 0px;
`;
SecurityDialogRowWrapper.displayName = "SecurityDialogRowWrapper";

const UsersDialogButtonWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 20px;
`;
UsersDialogButtonWrapper.displayName = "UsersDialogButtonWrapper";

const CardHeader = styled.div<DefaultThemeProps>`
  padding-left: 10px;
  padding-top: 14px;
  padding-bottom: 14px;
  min-width: 1200px;
  max-width: 1200px;
  display: flex;
  align-items: left;
  text-align: left;
  min-height: 40px;
  font-weight: 500;
  font-size: 14px;
  color: #443344;
  background: #ebebeb;
  border-bottom: 1px solid ${props => props.theme.borderColor};
`;
CardHeader.displayName = "CardHeader";

const ConfirmationWrapper = styled.div<DefaultThemeProps>`
  text-align: left;
  max-width: 100%;
  margin-bottom: 20px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: lightgrey;
`;
ConfirmationWrapper.displayName = "ConfirmationWrapper";

const RedText = styled.span`
  color: red;
`;
RedText.displayName = "RedText";

const ConfirmationSecondaryText = styled.span`
  flex: 1;
  text-align: right;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 16px;
  font-weight: normal;
  line-height: 150%;
  margin-top: 15px;
  white-space: nowrap;
  color: #5a5a5a;
`;
ConfirmationSecondaryText.displayName = "ConfirmationSecondaryText";

const ConfirmationTertiaryText = styled.span`
  font-size: 16px;
  font-weight: normal;
  line-height: 150%;
  margin-top: 15px;
  width: 300px;
  white-space: nowrap;
`;
ConfirmationTertiaryText.displayName = "ConfirmationTertiaryText";

const SecurityText = styled.span`
  white-space: nowrap;
`;
SecurityText.displayName = "SecurityText";

const ConfirmationButton = styled(StyledButton)`
  padding: 12px 50px;
`;
ConfirmationButton.displayName = "ConfirmationButton";

const StyledMidInvisible = styled(KeyboardArrowLeft)`
  width: 25%;
  display: inline-flex;
  position: relative;
  color: white;
`;
StyledMidInvisible.displayName = "StyledMidInvisible";

const StyledQuestionMidInvisible = styled(StyledMidInvisible)`
  width: 45%;
`;
StyledQuestionMidInvisible.displayName = "StyledQuestionMidInvisible";

const StyledFillerInvisible = styled(StyledMidInvisible)`
  width: 4%;
  height: 1px;
`;
StyledFillerInvisible.displayName = "StyledFillerInvisible";

const StyledEndListInvisible = styled(StyledFillerInvisible)`
  width: 50px;
`;
StyledEndListInvisible.displayName = "StyledEndListInvisible";

type PageProps = {
    padding: string;
    renderBack?: boolean;
    history: History;
} & RouteComponentProps;

type PageState = {
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  title: string;
  department: string;
  phoneNumber: string;
  question1: string;
  question2: string;
  answer1: string;
  answer2: string;
  password: string;
  confirmPassword: string;
  oldPassword: string;
  question1Custom: boolean;
  question2Custom: boolean;
  regExp8Char: boolean;
  regExpUpper: boolean;
  regExpLower: boolean;
  regExp1Num: boolean;
  securityQuestions1: ReadonlyArray<IDropdownItem>;
  securityQuestions2: ReadonlyArray<IDropdownItem>;
  isUpdatedProfile: boolean;
  isUpdatedPassword: boolean;
  isUpdatedQuestions: boolean;
  passwordErrorMessage: string;
  isGenericError: boolean;
};

export class UserProfilePage extends Component<PageProps, PageState> {
  state: PageState = {
    email: "",
    firstName: "",
    lastName: "",
    fullName: "",
    title: "",
    department: "",
    phoneNumber: "",
    question1: "",
    question2: "",
    answer1: "",
    answer2: "",
    password: "",
    confirmPassword: "",
    oldPassword: "",
    question1Custom: false,
    question2Custom: false,
    regExp8Char: false,
    regExpUpper: false,
    regExpLower: false,
    regExp1Num: false,
    securityQuestions1: [],
    securityQuestions2: [],
    isUpdatedProfile: false,
    isUpdatedPassword: false,
    isUpdatedQuestions: false,
    passwordErrorMessage: "",
    isGenericError: false,
  };

  handleScroll = () => {
    window.scrollTo(0, 0);
  };

  handlePassword = (event: React.ChangeEvent<{ value: string }>) => {
    let { RegExp8Char, RegExpUpper, RegExpLower, RegExp1Number } = REG_EXP;

    let valTrim = event.target.value.trim();
    this.setState({
      ...this.state, ...{
        password: event.target.value,
        regExp8Char: RegExp8Char.test(valTrim),
        regExpUpper: RegExpUpper.test(valTrim),
        regExpLower: RegExpLower.test(valTrim),
        regExp1Num: RegExp1Number.test(valTrim)
      }
    });
  };

  handleStringVal = (key: string, event: React.ChangeEvent<{ value: string }>) => {
    this.setState({
      ...this.state, ...{
        [key]: event.target.value,
      }
    });
  };

  handleAlphanumericVal = (key: string, event: React.ChangeEvent<{ value: string }>) => {
    event.target.value = Array.from(event.target.value).filter(x => x.match(/^(.*[A-Za-z0-9])/g)).join('');
    this.handleStringVal(key, event);
  };

  handleNumericVal = (key: string, event: React.ChangeEvent<{ value: string }>) => {
    event.target.value = Array.from(event.target.value).filter(x => x.match(/^(.*[0-9])/g)).join('');
    this.handleStringVal(key, event);
  };

  handleDropdownVal = (key: string, selected: IDropdownItem) => {
    this.setState({
      ...this.state, ...{
        [key]: selected.value,
      }
    });
  };

  renderMyProfile = (): React.ReactNode => {
    let {
      title,
      department,
      firstName,
      lastName,
      phoneNumber,
      email,
    } = this.state;

   return (
    <BoxDiv>
      <CardHeader>My Profile</CardHeader>
      <EditWrapper>
        <EmailWrapper>
          <ConfirmationSecondaryText>Email:</ConfirmationSecondaryText>
          <ConfirmationTertiaryText>{email}</ConfirmationTertiaryText>
        </EmailWrapper>
        <UsersDialogRowWrapper>
          <ConfirmationSecondaryText>First Name:</ConfirmationSecondaryText>
          <Input
            width="300px"
            id="new_user_fname"
            onChange={e => this.handleAlphanumericVal('firstName', e)}
            value={firstName}
          />
        </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <ConfirmationSecondaryText>Last Name:</ConfirmationSecondaryText>
          <Input
            width="300px"
            id="new_user_lname"
            onChange={e => this.handleAlphanumericVal('lastName', e)}
            value={lastName}
          />
        </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <ConfirmationSecondaryText>Title:</ConfirmationSecondaryText>
          <Input
            width="300px"
            id="new_user_title"
            onChange={e => this.handleAlphanumericVal('title', e)}
            value={title}
          />
        </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <ConfirmationSecondaryText>Department:</ConfirmationSecondaryText>
          <Input
            width="300px"
            id="new_user_department"
            onChange={e => this.handleAlphanumericVal('department', e)}
            value={department}
          />
        </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <ConfirmationSecondaryText>Telephone:</ConfirmationSecondaryText>
          <Input
            width="300px"
            onChange={e => this.handleNumericVal('phoneNumber', e)}
            id="new_user_phone"
            value={phoneNumber}
          />
        </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <ConfirmationSecondaryText>Timezone:</ConfirmationSecondaryText>
          <ConfirmationTertiaryText>(GMT-05:00) Eastern Time (US &amp; Canada)</ConfirmationTertiaryText>
        </UsersDialogRowWrapper>
        <UsersDialogButtonWrapper>
          <ConfirmationButton
                id="new_user_profile_confirmation_button"
            onClick={() => {
              this.handleProfileUpdate();
            }}
          >
            Update
          </ConfirmationButton>
        </UsersDialogButtonWrapper>
      </EditWrapper>
    </BoxDiv>
   );
  };

  renderChangePassword = (): React.ReactNode => {
    let {
      password,
      confirmPassword,
      oldPassword,
      regExp1Num,
      regExp8Char,
      regExpLower,
      regExpUpper
    } = this.state;
   return (
    <BoxDiv>
      <CardHeader>Change Password</CardHeader>
      <EditWrapper>
        <UsersDialogRowWrapper>
          <ConfirmationSecondaryText><RedText>*</RedText> Current Password:</ConfirmationSecondaryText>
          <Input
            type="password"
            width="300px"
            id="new_user_current_password"
            onChange={e => this.handleStringVal('oldPassword', e)}
            value={oldPassword}
          />
        </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <ConfirmationSecondaryText><RedText>*</RedText> New Password:</ConfirmationSecondaryText>
          <Input
            type="password"
            width="300px"
            id="new_user_password"
            onChange={e => this.handlePassword(e)}
            value={password}
          />
        </UsersDialogRowWrapper>
         <UsersDialogRowWrapper>
            <ConfirmationSecondaryText />
            <PasswordReqWrapper>
              <PasswordReqRowWrapper>
                <PasswordReqItemWrapper id="password_req_item_length_wrapper">
                  <Checkbox checked={regExp8Char} />
                  <PasswordReqSpan>12+ Characters</PasswordReqSpan>
                </PasswordReqItemWrapper>
                <PasswordReqItemWrapper id="password_req_item_number_wrapper">
                  <Checkbox checked={regExp1Num} />
                  <PasswordReqSpan>1+ Number</PasswordReqSpan>
                </PasswordReqItemWrapper>
              </PasswordReqRowWrapper>
              <PasswordReqRowWrapper>
                <PasswordReqItemWrapper id="password_req_item_uppercase_wrapper">
                  <Checkbox checked={regExpUpper} />
                  <PasswordReqSpan>1+ Uppercase</PasswordReqSpan>
                </PasswordReqItemWrapper>
                <PasswordReqItemWrapper id="password_req_item_lowercase_wrapper">
                  <Checkbox checked={regExpLower} />
                  <PasswordReqSpan>1+ Lowercase</PasswordReqSpan>
                </PasswordReqItemWrapper>
              </PasswordReqRowWrapper>
              <PasswordReqRowWrapper>
                <PasswordReqItemWrapper id="password_req_item_password_match_wrapper">
                  <Checkbox
                    checked={
                      password === confirmPassword && password.trim() !== ""
                    }
                  />
                  <PasswordReqSpan>Password Match</PasswordReqSpan>
                </PasswordReqItemWrapper>
              </PasswordReqRowWrapper>
            </PasswordReqWrapper>
          </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <ConfirmationSecondaryText><RedText>*</RedText> Confirm New Password:</ConfirmationSecondaryText>
          <Input
            type="password"
            width="300px"
            id="new_user_confirm_password"
            onChange={e => this.handleStringVal('confirmPassword', e)}
            value={confirmPassword}
          />
        </UsersDialogRowWrapper>
        <UsersDialogButtonWrapper>
          <ConfirmationButton
            id="new_user_password_confirm_button"
            disabled={
              oldPassword.trim() === "" || password !== confirmPassword || password.trim() === "" || !regExpLower || !regExpUpper || !regExp1Num || !regExp8Char
            }
            onClick={() => {
              this.handlePasswordUpdate();
            }}
          >
            Change Password
          </ConfirmationButton>
        </UsersDialogButtonWrapper>
      </EditWrapper>
    </BoxDiv>
   );
  };



  renderSecurityQuestions = (): React.ReactNode => {
   let {
     question1,
     question2,
     question1Custom,
     question2Custom,
     answer1,
     answer2,
     securityQuestions1,
     securityQuestions2,
   } = this.state;

   return (
    <BoxDiv>
      <CardHeader>Security Questions</CardHeader>
      <EditWrapper>
            <SecurityDialogRowWrapper>
              {question1Custom ? (
                <Input
                  id="new_user_customsecurityquestionone"
                  width="300px"
                  placeholder="Example: what is your favorite food?"
                  value={question1}
                  onChange={e => this.handleStringVal('question1', e)}
                />) : (
                <Dropdown
                  id="new_user_securityquestionone"
                  placeholder="Pick a Security Question"
                  handleValue={e => this.handleDropdownVal('question1', e)}
                  list={securityQuestions1}
                  width={300}
                />)}
              <StyledMidInvisible/>
              {question2Custom ? (
                <Input
                  id="new_user_customsecurityquestiontwo"
                  width="300px"
                  placeholder="Example: what is your favorite food?"
                  value={question2}
                  onChange={e => this.handleStringVal('question2', e)}
                />) : (
                <Dropdown
                  id="new_user_securityquestiontwo"
                  placeholder="Pick a Security Question"
                  handleValue={e => this.handleDropdownVal('question2', e)}
                  list={securityQuestions2}
                  width={300}
                />)}
            </SecurityDialogRowWrapper>
            <br/>
            <SecurityDialogRowWrapper>
              <EmailWrapper>
                {question1Custom ? (
                  <SecurityText>
                    or <u
                      onClick={() => {
                        this.setState({
                          ...this.state, ...{ question1: "", question1Custom: false }
                        });
                      }}
                    >select from a list</u><StyledEndListInvisible/>
                  </SecurityText>
                  ) : (
                  <SecurityText>
                    or <u
                      onClick={() => {
                        this.setState({
                          ...this.state, ...{ question1: "", question1Custom: true }
                        });
                      }}
                    >write your own question</u>
                  </SecurityText>)}
              </EmailWrapper>
              <StyledQuestionMidInvisible/>
              <EmailWrapper>
                {question1Custom && (<StyledFillerInvisible/>)}
                {question2Custom ? (
                  <SecurityText>
                    or <u
                      onClick={() => {
                        this.setState({
                          ...this.state, ...{ question2: "", question2Custom: false }
                        });
                      }}
                    >select from a list</u><StyledEndListInvisible/>
                  </SecurityText>
                  ) : (
                  <SecurityText>
                    or <u
                      onClick={() => {
                        this.setState({
                          ...this.state, ...{ question2: "", question2Custom: true }
                        });
                      }}
                    >write your own question</u>
                  </SecurityText>)}
              </EmailWrapper>
            </SecurityDialogRowWrapper>
            <br/>
            <SecurityDialogRowWrapper>
              <Input
                id="new_user_securityanswerone"
                type="password"
                width="300px"
                placeholder="Security answer"
                value={answer1}
                onChange={e => this.handleStringVal('answer1', e)}
              />
              <StyledMidInvisible/>
              <Input
                id="new_user_securityanswertwo"
                type="password"
                width="300px"
                placeholder="Security answer"
                value={answer2}
                onChange={e => this.handleStringVal('answer2', e)}
              />
            </SecurityDialogRowWrapper>
        <UsersDialogButtonWrapper>
          <ConfirmationButton
              id="new_user_security_confirmation_button"
            disabled={
              question1.trim() === "" || question2.trim() === "" || answer1.trim() === "" || answer2.trim() === ""
            }
            onClick={() => {
              this.handleQAUpdate();
            }}
          >
            Update Security Questions
          </ConfirmationButton>
        </UsersDialogButtonWrapper>
      </EditWrapper>
    </BoxDiv>
   );
  };

  renderLanguageSettings = (): React.ReactNode => {
   return (
    <BoxDiv>
      <CardHeader>Language Settings</CardHeader>
      <EditWrapper>
        <UsersDialogRowWrapper>
          <ConfirmationSecondaryText>Default Language:</ConfirmationSecondaryText>
          <Input
            width="300px"
            id="new_user_language"
            disabled
            defaultValue="English"
          />
        </UsersDialogRowWrapper>
        <UsersDialogButtonWrapper>
          <ConfirmationButton
              id="new_user_language_confirmation_button"
            disabled={true}
          >
            Update
          </ConfirmationButton>
        </UsersDialogButtonWrapper>
      </EditWrapper>
    </BoxDiv>
   );
  };

  pageContent = (): React.ReactNode => {
    return (
      <Fragment>
          {this.state.passwordErrorMessage && (
            <StyledAlert icon={false} severity="error" >
              {this.state.passwordErrorMessage}
            </StyledAlert>
          )}
          {this.state.isGenericError && (
            <StyledAlert icon={false} severity="error" >
              Oops, there was an issue.
            </StyledAlert>
          )}
          {this.state.isUpdatedProfile && (
            <StyledSuccess icon={false} severity="error" >
              User Profile updated successfully
            </StyledSuccess>
          )}
          {this.state.isUpdatedPassword && (
            <StyledSuccess icon={false} severity="error" >
              Password Changed Successfully
            </StyledSuccess>
          )}
          {this.state.isUpdatedQuestions && (
            <StyledSuccess icon={false} severity="error" >
              Security Questions Updated Successfully
            </StyledSuccess>
          )}
          {this.renderMyProfile()}
          <p/>
          {this.renderChangePassword()}
          <p/>
          {this.renderSecurityQuestions()}
          <p/>
          {this.renderLanguageSettings()}
      </Fragment>
    );
  };

  statePromiseCatch = () => {
    return (error: ErrorType) => {
        if (error.message && error.message === "Request failed with status code 401") {
          this.props.history.replace("/enablement/login");
        }
        this.setState({
          isUpdatedProfile: false,
          isUpdatedPassword: false,
          isUpdatedQuestions: false,
          passwordErrorMessage: "",
          isGenericError: true,
        });
        this.handleScroll();
    };
  };

  handleQuestions = () => {
    getSecurityQuestions()
      .then(response => {
        response && response.data && response.data.en && response.data.en.question_set_1 && this.setState({ securityQuestions1: response.data.en.question_set_1.map((q: string) => ({ 'title': q, 'value': q })) });

        response && response.data.en && response.data.en.question_set_2 && this.setState({securityQuestions2: response.data.en.question_set_2.map((q: string) => ({ 'title': q, 'value': q })) });
      })
    .catch(this.statePromiseCatch());
  };

  handleProfile = () => {
    getProfile()
      .then(response => {
        response && response.data && this.setState({
          department: response.data.department || "",
          firstName: response.data.first_name || "",
          lastName: response.data.last_name || "",
          title: response.data.title || "",
          phoneNumber: response.data.telephone || "",
          fullName: [response.data.first_name, response.data.last_name].filter(Boolean).join(" ") || ""
        });
      })
    .catch(this.statePromiseCatch());
  };

  nullifyIfEmpty = (val: string) => {
    if (val === "") {
      return null;
    } else {
      return val;
    }
  };

  handlePasswordUpdate = () => {
    const req = {
      user: {
        email: this.state.email,
        current_password: this.state.oldPassword,
        new_password: this.state.password,
        new_password_confirmation: this.state.confirmPassword,
      }
    };
    updatePassword(req)
      .then(response => {
        this.setState({
          isUpdatedProfile: false,
          isUpdatedPassword: true,
          isUpdatedQuestions: false,
          passwordErrorMessage: "",
          isGenericError: false,
          password: "",
          confirmPassword: "",
          oldPassword: "",
          regExp8Char: false,
          regExpUpper: false,
          regExpLower: false,
          regExp1Num: false,
        });
        this.handleScroll();
      })
    .catch(this.passwordPromiseCatch());
  };

  removePrefix = (input: string, prefix: string): string =>
      input.startsWith(prefix)
          ? input.substring(prefix.length).trimLeft()
          : input;

  passwordPromiseCatch = () => {
    return (error: ErrorType) => {
      console.log("error response ", error.response);
      if(error.response && error.response.data) {
        console.log("error response data", error.response.data);
        console.log("error response data message", error.response.data.message);
      }
      let message = "";
      if (error.response && error.response.status && (error.response.status === 401 || error.response.status === 403) ) {
        this.props.history.replace("/enablement/login");
      }
      if (error.response && error.response.data && error.response.data.error) {
          message = error.response.data.error;
      }
      if (message) {
        message = this.removePrefix(message, "precondition failed:");
      }
      if(message && message === "invalid username or password") {
        message = "Current password invalid";
      }
      if (message) {
        this.setState({
          isUpdatedProfile: false,
          isUpdatedPassword: false,
          isUpdatedQuestions: false,
          passwordErrorMessage: message,
          password: "",
          confirmPassword: "",
          oldPassword: "",
          regExp8Char: false,
          regExpUpper: false,
          regExpLower: false,
          regExp1Num: false,
          isGenericError: false,
        });
        this.handleScroll();
      } else {
        this.setState({
          isUpdatedProfile: false,
          isUpdatedPassword: false,
          isUpdatedQuestions: false,
          passwordErrorMessage: "",
          password: "",
          confirmPassword: "",
          oldPassword: "",
          regExp8Char: false,
          regExpUpper: false,
          regExpLower: false,
          regExp1Num: false,
          isGenericError: true,
        });
        this.handleScroll();
      }
    };
  };

  handleProfileUpdate = () => {
    const req = {
      profile: {
        email: this.state.email,
        title: this.nullifyIfEmpty(this.state.title),
        department: this.nullifyIfEmpty(this.state.department),
        telephone: this.nullifyIfEmpty(this.state.phoneNumber),
        first_name: this.nullifyIfEmpty(this.state.firstName),
        last_name: this.nullifyIfEmpty(this.state.lastName),
      }
    };
    updateProfile(req)
      .then(response => {
        this.setState({
          isUpdatedProfile: true,
          isUpdatedPassword: false,
          isUpdatedQuestions: false,
          passwordErrorMessage: "",
          isGenericError: false,
        });
        this.handleProfile();
        this.handleScroll();
      })
    .catch(this.statePromiseCatch());
  };

  handleQAUpdate = () => {
    const req = {
      security_question_1: this.state.question1,
      security_question_2: this.state.question2,
      security_answer_1: this.state.answer1,
      security_answer_2: this.state.answer2,
    };
    updateQAs(req)
      .then(response => {
        this.setState({
          isUpdatedProfile: false,
          isUpdatedPassword: false,
          isUpdatedQuestions: true,
          passwordErrorMessage: "",
          isGenericError: false,
        });
        this.handleScroll();
      })
    .catch(this.statePromiseCatch());
  };

  componentDidMount() {
    if (getPreviewModeFromToken()) {
      this.props.history.replace("/enablement/payments");
    }
    this.setState({email: getUserFromToken().email});
    this.handleProfile();
    this.handleQuestions();
  }

  render() {
    return (
      <PageLayout
        pageLayoutProps={pageLayoutProps(this.state.fullName)}
        scrollableContent={this.pageContent}
      />
    );
  }
}

export default withRouter(UserProfilePage);
