import React, { useState } from "react";
import styled from "styled-components";
import { IRowlimitItem } from "../types";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Popover from "@material-ui/core/Popover";

type RowlimitProps = {
  initialValue?: number;
  list?: ReadonlyArray<IRowlimitItem>;
  handleValue?(value: number): void;
  isOpen?: boolean;
};

const RowlimitWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RowlimitHeader = styled.div<RowlimitProps>`
  cursor: pointer;
  background: ${props => props.theme.mutedBackground};
  height: auto;
  color: ${props => props.theme.secondaryText};
  position: relative;

  ${props =>
    props.isOpen
      ? "border-bottom-left-radius: 3px; border-bottom-right-radius: 3px;"
      : "border-radius: 3px;"}
`;

const RowlimitSelectedValue = styled.div`
  margin: 7px 11px;
  width: 27px;
  display: flex;
  flex-direction: row;
  svg {
    height: 16px;
    width: 16px;
  }
`;

const RowlimitTitle = styled.div`
  font-size: 12px;
`;

const StyledPopover = styled(Popover)`
  z-index: 5;

  .MuiPaper-rounded {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .MuiPaper-elevation8 {
    box-shadow: none;
  }
`;

const RowlimitList = styled.ul<RowlimitProps>`
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: ${props => props.theme.mutedBackground};
`;

const RowlimitItem = styled.li`
  cursor: pointer;
  line-height: 30px;
  padding-right: 13px;

  font-size: 12px;
  height: 30px;
  width: 49px;

  color: ${props => props.theme.secondaryText};
`;

export default function RowLimit({
  list,
  initialValue,
  handleValue,
  isOpen
}: RowlimitProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [value, setValue] = useState(initialValue ? initialValue : "");

  function onClickRowLimitClose() {
    setAnchorEl(null);
  }

  function onClickRowLimitOpen(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) {
    setAnchorEl(event.currentTarget);
  }

  function onClickToggleItem(val: number) {
    setValue(val);
    handleValue && handleValue(val);
    onClickRowLimitClose();
  }

  const open = Boolean(anchorEl);

  return (
    <RowlimitWrapper>
      <StyledPopover
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={open}
        onClose={onClickRowLimitClose}
        anchorEl={anchorEl}
        transitionDuration={0}
      >
        <RowlimitList>
          {list &&
            list.map(item => (
              <RowlimitItem
                key={`row_limit_${item.value}`}
                id={`row_limit_${item.value}`}
                onClick={() => onClickToggleItem(item.value)}
              >
                {item.title}
              </RowlimitItem>
            ))}
        </RowlimitList>
      </StyledPopover>
      <RowlimitHeader
        id="row_limit_header"
        onClick={onClickRowLimitOpen}
        isOpen={open}
      >
        <RowlimitSelectedValue>
          <RowlimitTitle>{value}</RowlimitTitle>
          {open ? <ExpandLess /> : <ExpandMore />}
        </RowlimitSelectedValue>
      </RowlimitHeader>
    </RowlimitWrapper>
  );
}
