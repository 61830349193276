export type IAttachmentData = {
  data: string;
  name: string;
  type: string;
  extension: string;
  isZip: string;
};

export type IEmailData = {
  subject: string;
  to: string[];
  from: string[];
  body: string;
  attachments: IAttachmentData[];
};

export const attachment2Blob = (attachment: IAttachmentData): Blob => {
  var binData = atob(attachment.data);
  var binBuffer = new ArrayBuffer(binData.length);
  var arr = new Uint8Array(binBuffer);
  for (var i = 0; i < binData.length; i++) {
    arr[i] = binData.charCodeAt(i);
  }
  var blob = new Blob([arr], { type: attachment.type });
  return blob;
}
