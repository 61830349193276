import React from "react";
import Authorize from "../components/Authorize";
import SignoutPage from "../components/SignoutPage";
import NotFoundPage from "../components/NotFoundPage";
import { get } from "lodash";
import TermsConditionsPage from "../components/TermsConditionsPage";
import PaymentListingPage from "../components/Payments/PaymentListingPage";
import PaymentExportsListingPage from "../components/PaymentExports/PaymentExportsListingPage";
import PaymentExportsConfigPage from "../components/PaymentExports/PaymentExportsConfigPage";
import UsersListingPage from "../components/Users/UsersListingPage";
import UserProfilePage from "../components/Users/UserProfilePage";

import SignUpPage from "../components/SignUp/SignUpPage";
import { PageLayout } from "../../common/components/PageLayout/PageLayout";
import { pageLayoutProps } from "./helpers";
import { RouteComponentProps } from "react-router";
import { NewUserPage } from "../components/Users/NewUserPage";
import { LockPage } from "../components/Lock/LockPage";
import {LoginPage} from "../components/Login/LoginPage";

export default [
  { from: "/enablement", to: "/enablement/payments", exact: true },
  { open: true, path: "/enablement/signout", component: SignoutPage },
  {
    path: "/enablement/terms",
    component: TermsConditionsPage,
    exact: true
  },
  {
    open: true,
    path: "/enablement/authorize/:token([a-zA-Z0-9]+)",
    component: Authorize,
    exact: true
  },
  {
    open: true,
    path: "/enablement/authorize/ltoken/:token([a-zA-Z0-9-]+)",
    component: Authorize,
    exact: true
  },
  {
    path: "/enablement/error",
    render: (props: RouteComponentProps) => (
      <PageLayout
        title="Error"
        {...props}
        pageLayoutProps={pageLayoutProps()}
        scrollableContent={() => {
          return `Error: ${get(props.location.state, "message")}`;
        }}
      />
    )
  }, //TODO: proper error page
  { path: "/enablement/payments", component: PaymentListingPage, exact: true },
  {
    path: "/enablement/payment_exports",
    component: PaymentExportsListingPage,
    exact: true
  },
  {
    path: "/enablement/payment_exports/schedules",
    component: PaymentExportsConfigPage,
    exact: true
  },
  {
    path: "/enablement/users",
    component: UsersListingPage,
    exact: true
  },
  {
    path: "/enablement/users/profile",
    component: UserProfilePage,
    exact: true
  },
  {
    path: "/enablement/users/new_user",
    component: NewUserPage,
    exact: true
  },
  {
    path: "/enablement/sign_up",
    component: SignUpPage,
    open: true
  },
  {
    path: "/enablement/login",
    component: LoginPage,
    open: true
  },
  {
    path: "/enablement/locked",
    component: LockPage,
    open: true
  },
  // { path: "/enablement/payments/:id(\\d+)", component: PaymentDetailPage, exact: true },
  {
    path: "/enablement/*",
    component: NotFoundPage
  }
];
