import React, {Component, Fragment} from "react";
import {History} from "history";
import {RouteComponentProps, withRouter} from "react-router-dom";
import PageLayout from "../../../common/components/PageLayout/PageLayout";
import {
    authenticateWithPassword,
    getBrandColorFromToken,
    getBrandLogoFromToken,
    getUserIdFromToken,
    knownBrowser,
    pageLayoutProps,
    setKnownBrowser,
    standardPromiseCatch,
} from "../../lib/helpers";
import {
  getSecurityQuestion,
  getUserAccountToken,
  resetPassword,
  resetQuestions,
  verifyKnownBrowser,
  verifySecurityAnswer,
} from "../../services/userServices";
import styled, {DefaultThemeProps} from "styled-components";
import Input from "../../../common/components/Input";
import StyledButton from "../../../common/components/StyledButton";
import LoginFooter from "../LoginFooter";
import StyledAlert, { StyledSuccess } from "../StyledAlert";
import {
    clearToken,
    setToken,
    setBrandColor,
    setBrandLogo
} from "../../../common/lib/helpers";
import { ErrorType } from "../../../common/types";

const BoxDiv = styled.div<DefaultThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-width: 280px;
  max-width: 420px;
  min-height: 220px;
  padding: 20px;

  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
`;
BoxDiv.displayName = "BoxDiv";


const BoxRowDiv = styled.div<DefaultThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-width: 1040px;
  max-width: 1040px;
  min-height: 300px;
  padding: 20px;

  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
`;
BoxRowDiv.displayName = "BoxRowDiv";

const BoxDivSpacer = styled.div<DefaultThemeProps>`
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  color: ${props => props.theme.providedColor};
  align-items: center;

  span {
    cursor: pointer;
  }
`;
BoxDivSpacer.displayName = "BoxDivSpacer";

const BoxDivMessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 20px;
`;
BoxDivMessageWrapper.displayName = "BoxDivMessageWrapper";

const QuestionMessageWrapper = styled(BoxDivMessageWrapper)`
  margin: 0px 0px 10px;
`;
QuestionMessageWrapper.displayName = "QuestionMessageWrapper";

const BoxMessageTitle = styled.span<DefaultThemeProps>`
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;

  color: ${props => props.theme.primaryText};
  text-align: center;
  width: 100%;
`;
BoxMessageTitle.displayName = "BoxMessageTitle";

const PageContentDiv = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
`;
PageContentDiv.displayName = "PageContentDiv";

const SignInButton = styled(StyledButton)`
  padding: 12px 67px;
  text-align: center;
  justify-content: center;
  width: 100%;
`;
SignInButton.displayName = "StyledNext";

const EmailWrapper = styled.div`
  margin: 40px 0px 0px;
`;
EmailWrapper.displayName = "EmailWrapper";

const PasswordWrapper = styled(EmailWrapper)`
  margin-top: 5px;
`;
PasswordWrapper.displayName = "PasswordWrapper";

const BoxDivButtonWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
`;
BoxDivButtonWrapper.displayName = "BoxDivButtonWrapper";

const SecurityBoxDivButtonWrapper = styled(BoxDivButtonWrapper)`
  width: 300px;
`;
SecurityBoxDivButtonWrapper.displayName = "SecurityBoxDivButtonWrapper";

const LoginLinkAnchor = styled.a<DefaultThemeProps>`
  height: 20px;
  min-width: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;

  span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    color: ${props => props.theme.activeLinkText};
  }
`;
LoginLinkAnchor.displayName = "EnterAnchor";

const SecurityLoginLinkAnchor = styled(LoginLinkAnchor)<DefaultThemeProps>`
  span {
    color: #2b2b2b;
  }
`;
SecurityLoginLinkAnchor.displayName = "SecurityLoginLinkAnchor";

const QuestionHeaderText = styled.span<DefaultThemeProps>`
  color: #616161;
  height: 20px;
  min-width: 128px;
  max-width: 302px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  text-align: center;
`;
QuestionHeaderText.displayName = "QuestionHeaderText";

const ResetHeaderText = styled(QuestionHeaderText)`
  font-weight: 400;
`;
ResetHeaderText.displayName = "ResetHeaderText";

type PageProps = {
    padding: string;
    renderBack?: boolean;
    history: History;
} & RouteComponentProps;

type PageState = {
    boxNum: number;
    answer: string;
    question1: string;
    question2: string;
    questionNum: 1 | 2;
    isFailedLogin: boolean;
    attemptsLeft: number;
    isJustReset: boolean;
    isJustQuestionReset: boolean;
    email: string;
    password: string;
    unAuthErrorDescription: string;
}

export class LoginPage extends Component<PageProps, PageState> {
    state: PageState = {
        boxNum: 1,
        answer: "",
        question1: "",
        question2: "",
        questionNum: 1,
        isFailedLogin: false,
        attemptsLeft: 0,
        isJustReset: false,
        isJustQuestionReset: false,
        email: "",
        password: "",
        unAuthErrorDescription: ""
    }

    handleEmail = (event: React.ChangeEvent<{ value: string }>) => {
        this.setState({
            ...this.state, 
            ...{ email: event.target.value }
        });
    };

    handlePassword = (event: React.ChangeEvent<{ value: string }>) => {
        this.setState({
            ...this.state, 
            ...{ password: event.currentTarget.value }
        });
    };

    handleAnswer = (event: React.ChangeEvent<{ value: string }>) => {
        this.setState({
            ...this.state, 
            ...{ answer: event.currentTarget.value }
        });
    };

    handleQuestionGet = () => {
        getSecurityQuestion().then(response => {
            this.setState({
                ...this.state, 
                ...{ 
                    questionNum: (Math.floor(Math.random() * 2) + 1) === 1 ? 1 : 2,
                    question1: response.data.security_question_1,
                    question2: response.data.security_question_2,
                }
            });
        });
    };

    handleLoginKeyPress = (event: React.KeyboardEvent<any>) => {
      if (event.key === "Enter") {
        this.handleAuthentication();
      }
    };

    handleQuestionsKeyPress = (event: React.KeyboardEvent<any>) => {
      if (event.key === "Enter") {
        this.handleVerify();
      }
    };

    handleResetEmailKeyPress = (event: React.KeyboardEvent<any>) => {
      if (event.key === "Enter") {
        this.handleResetRequest();
      }
    };

    handleAuthentication = () => {
        authenticateWithPassword(this.state.email, this.state.password).then(response => {
            setToken(response.data.access_token);
            setBrandColor(getBrandColorFromToken());
            setBrandLogo(getBrandLogoFromToken());
            verifyKnownBrowser({user_id: -1, supplier_id: -1, token: knownBrowser()}).then(response => {
              this.props.history.replace("/enablement/payments");
            }).catch(() => {
              this.handleQuestionGet();
              this.setState({ isJustQuestionReset: false, isJustReset: false, attemptsLeft: 0, isFailedLogin: false, boxNum: 2 });
            });
        }).catch(this.authenticationPromiseCatch());
    }

    handleVerify = () => {
        verifySecurityAnswer({user_id: -1, security_question_number: this.state.questionNum, answer: this.state.answer}).then(response => {
            getUserAccountToken().then(resp => {
                setKnownBrowser(getUserIdFromToken() + resp.data.token);
            }).catch(standardPromiseCatch(this.props.history));
            this.props.history.replace("/enablement/payments");
        }).catch(this.statePromiseCatch());
    }

    handleResetRequest = () => {
        resetPassword(this.state.email).then(response => {
            this.setState({
                ...this.state, 
                ...{ boxNum: 1, isJustQuestionReset:false, isJustReset: true }
            });
        }).catch(standardPromiseCatch(this.props.history));
    }

    handleResetQuestionsRequest = () => {
        resetQuestions(this.state.email).then(response => {
            clearToken();
            this.setState({
                ...this.state, 
                ...{ boxNum: 1, isJustQuestionReset: true, isJustReset: false, password: "" }
            });
        }).catch(standardPromiseCatch(this.props.history));
    }

    handleAnotherQuestion = () => {
        this.setState({
            ...this.state, 
            ...{ questionNum: this.state.questionNum === 1 ? 2 : 1 }
        });
    }

  statePromiseCatch = () => {
    return (error: ErrorType) => {
      if (error.response && error.response.status === 403 && error.response.data && error.response.data.access_error_type === "user_locked") {
        this.props.history.replace("/enablement/locked");
      } else {
        let attemptsLeft = 0;
        if (error.response && error.response.data && error.response.data.message) {
          try {
            attemptsLeft = JSON.parse(error.response.data.message).attempts_left;
          } catch (exception) {
            // ignore, probably not an attempts left message but a human readable one for a different type of error
          }
        }
        this.setState({
          ...this.state, ...{ password: "", attemptsLeft: attemptsLeft, isFailedLogin: true, isJustReset: false, isJustQuestionReset: false }
        });
      }
    };
  };

  authenticationPromiseCatch = () => {
      return (error: ErrorType) => {
          if (error.response && error.response.status === 403 && error.response.data && error.response.data.access_error_type === "user_locked") {
              this.props.history.replace("/enablement/locked");
          } else {
              let errorDescription = "Invalid email address or password, please try again.";
              if (error.response && error.response.data) {
                  try {
                      let errObjType = {
                          error: "",
                          error_description: "",
                          obj: {}
                      };
                      const errorObj = Object.assign(errObjType, error.response.data);
                      errorDescription = errorObj.error_description
                  } catch (exception) {
                      // ignore, probably not an attempts left message but a human readable one for a different type of error
                  }
              }
              this.setState({
                  ...this.state, ...{ password: "", unAuthErrorDescription: errorDescription, isFailedLogin: true, isJustReset: false, isJustQuestionReset: false }
              });
          }
      };
  };

    renderResetPasswordBox = () => {
        let {
            email,
        } = this.state;

        return (
            <BoxDiv>
                <BoxDivMessageWrapper>
                    <ResetHeaderText><b>Reset your Password</b></ResetHeaderText>
                </BoxDivMessageWrapper>

                <PasswordWrapper>
                    <QuestionHeaderText>Enter your email below to reset your password.</QuestionHeaderText>
                </PasswordWrapper>
                <br/>
                <PasswordWrapper>
                    <Input
                        placeholder="Email"
                        width="300px"
                        value={email}
                        onChange={e => this.handleEmail(e)}
                        onKeyPress={e => this.handleResetEmailKeyPress(e)}
                    />
                </PasswordWrapper>

                <SecurityBoxDivButtonWrapper>
                    <SignInButton
                        onClick={() => {
                            this.handleResetRequest();
                        }}
                    >
                        Send Instructions
                    </SignInButton>
                </SecurityBoxDivButtonWrapper>
            </BoxDiv>
        );
    };

    renderQuestionsBox = () => {
        let {
            answer,
            question1,
            question2,
        } = this.state;

        return (
            <BoxDiv>
                <QuestionMessageWrapper>
                    <QuestionHeaderText>To verify your identity, please answer your security question.</QuestionHeaderText>
                </QuestionMessageWrapper>

                <PasswordWrapper>
                    <QuestionHeaderText>{this.state.questionNum === 1 ? question1 : question2}</QuestionHeaderText>
                </PasswordWrapper>
                <br/>
                <PasswordWrapper>
                    <Input
                        type="password"
                        placeholder="Answer"
                        width="300px"
                        value={answer}
                        onChange={e => this.handleAnswer(e)}
                        onKeyPress={e => this.handleQuestionsKeyPress(e)}
                    />
                </PasswordWrapper>

                <SecurityBoxDivButtonWrapper>
                    <SignInButton
                        onClick={() => {
                            this.handleVerify();
                        }}
                        disabled={answer === ""}
                    >
                        Sign in
                    </SignInButton>
                </SecurityBoxDivButtonWrapper>
                <SecurityLoginLinkAnchor id="reset_questions_link" href="#"
                        onClick={() => {
                            this.handleResetQuestionsRequest();
                        }}
                >
                    <span><u>Reset Your Questions</u></span>
                </SecurityLoginLinkAnchor>
                <SecurityLoginLinkAnchor id="another_question_link" href="#"
                        onClick={() => {
                            this.handleAnotherQuestion();
                        }}
                >
                    <span><u>Try another question</u></span>
                </SecurityLoginLinkAnchor>
            </BoxDiv>
        );
    };

    renderLoginBox = () => {
        let {
            email,
            password,
        } = this.state;

        return (
            <BoxDiv>
                <BoxDivMessageWrapper>
                    <BoxMessageTitle>Smart. Simple. Secure.</BoxMessageTitle>
                </BoxDivMessageWrapper>

                <EmailWrapper>
                    <Input
                        width="300px"
                        placeholder="Email"
                        value={email}
                        onChange={e => this.handleEmail(e)}
                    />
                </EmailWrapper>
                <PasswordWrapper>
                    <Input
                        type="password"
                        placeholder="Password"
                        width="300px"
                        value={password}
                        onChange={e => this.handlePassword(e)}
                        onKeyPress={e => this.handleLoginKeyPress(e)}
                    />
                </PasswordWrapper>

                <BoxDivButtonWrapper>
                    <SignInButton
                        onClick={() => {
                            this.handleAuthentication();
                        }}
                    >
                        Sign in
                    </SignInButton>
                </BoxDivButtonWrapper>
                <LoginLinkAnchor id="forgot_password_link" href="#"
                    onClick={() => {
                        this.setState({attemptsLeft: 0, isFailedLogin: false, isJustReset: false, isJustQuestionReset: false, boxNum: 3, email: ""});
                    }}
                >
                    <span>Forgot your password?</span>
                </LoginLinkAnchor>
            </BoxDiv>
        );
    };

    renderBox = () => {
        let {
            boxNum,
        } = this.state;
        if (boxNum === 2) {
            return this.renderQuestionsBox();
        } else if (boxNum === 3) {
            return this.renderResetPasswordBox();
        } else {
            return this.renderLoginBox();
        }
    };

    pageContent = (): React.ReactNode => {
        return (
            <Fragment>
              {this.state.isFailedLogin && (
                <StyledAlert icon={false} severity="error" >
                  {this.state.boxNum === 2 ? (<Fragment>Invalid answer, {this.state.attemptsLeft} attempts left.</Fragment>) : (<Fragment>{this.state.unAuthErrorDescription}</Fragment>) }
                </StyledAlert>
              )}
              {this.state.isJustReset && (
                <StyledSuccess icon={false} severity="error" >
                  If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.
                </StyledSuccess>
              )}
              {this.state.isJustQuestionReset && (
                <StyledSuccess icon={false} severity="error" >
                  You will receive a recovery link to the email associated with this account shortly
                </StyledSuccess>
              )}
                <PageContentDiv>
                    {this.renderBox()}
                    {LoginFooter()}
                </PageContentDiv>
            </Fragment>
        );
    };

    render() {
        return (
            <PageLayout
                noNavLinks
                noFooter={true}
                pageLayoutProps={pageLayoutProps()}
                scrollableContent={this.pageContent}
            />
        );
    }
}

export default withRouter(LoginPage);
