import React from "react";
import styled, { DefaultThemeProps } from "styled-components";

type StyledHeaderProps = {
  isInvalid?: boolean;
  disabled?: boolean;
} & DefaultThemeProps;

type InputProps = {
  id?: string;
  value?: string;
  defaultValue?: string;
  isRequired?: boolean;
  isInvalid?: boolean;
  maxLength?: number;
  placeholder?: string;
  width?: string;
  height?: string;
  header?: string;
  type?: string;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<{ value: string }>) => void;
  onKeyPress?: (event: React.KeyboardEvent<any>) => void;
};

type StyledInputProps = InputProps & DefaultThemeProps;

const StyledInput = styled.input<StyledInputProps>`
  height: ${props => (props.height ? props.height : "50px")};
  font-size: 14px;
  color: ${props => props.theme.primaryText};
  padding: 0px 16px;
  box-shadow: 0px 2px 6px ${props => props.theme.shadowColor};
  border-radius: 4px;
  border: ${props =>
    props.isInvalid
      ? `1px solid ${props.theme.negativeText}`
      : `1px solid ${props.theme.borderColor}`};

  opacity: ${props => (props.disabled ? "0.3" : "1")};
  box-sizing: border-box;
  ${props => (props.width ? `width: ${props.width}` : "")};

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${props => props.theme.tertiaryText};
  }
`;
StyledInput.displayName = "StyledInput";

const StyledInputHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 5px;
`;
StyledInputHeaderWrapper.displayName = "StyledInputHeaderWrapper";

const StyledInputRequiredTextWrapper = styled.div`
  font-size: 11px;
  line-height: 14px;
  color: ${props => props.theme.negativeText};
`;
StyledInputRequiredTextWrapper.displayName = "StyledInputRequiredTextWrapper";

const StyledHeaderText = styled.span<StyledHeaderProps>`
  color: ${props =>
    props.isInvalid ? props.theme.negativeText : props.theme.secondaryText};
  font-size: 14px;
  text-align: left;
  font-weight: 500;
  line-height: 14px;
  opacity: ${props => (props.disabled ? "0.3" : "1")};
`;
StyledHeaderText.displayName = "StyledHeaderText";

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
StyledInputWrapper.displayName = "StyledInputWrapper";

export default function Input({
  id,
  value,
  placeholder,
  isRequired,
  width,
  header,
  isInvalid,
  type,
  disabled,
  onChange,
  onKeyPress,
  maxLength,
  defaultValue,
  height
}: InputProps) {
  return (
    <StyledInputWrapper>
      <StyledInputHeaderWrapper>
        {header && (
          <StyledHeaderText
            isInvalid={isInvalid}
            disabled={disabled}
            id={id && `${id}_input_header`}
          >
            {header}
          </StyledHeaderText>
        )}
        {isRequired && (
          <StyledInputRequiredTextWrapper
            id={id && `${id}_input_required_text`}
          >
            Required
          </StyledInputRequiredTextWrapper>
        )}
      </StyledInputHeaderWrapper>
      <StyledInput
        isInvalid={isInvalid}
        height={height}
        id={id && `${id}_input`}
        maxLength={maxLength}
        defaultValue={defaultValue}
        value={value}
        placeholder={placeholder}
        width={width}
        disabled={disabled}
        type={type}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </StyledInputWrapper>
  );
}
