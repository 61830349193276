import React, { Component, Fragment } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { forEach, values, size, every } from "lodash";
import Dots from "@material-ui/icons/MoreHoriz";

import PageLayout from "./PageLayout";

import Searchbox from "../Searchbox";
import Popover from "../Popover";
import DataTable from "../DataTable";
import StyledButton from "../StyledButton";
import FilterPills from "../FilterPills";
import FilterCheckboxes from "../FilterCheckboxes";
import FilterSwitches from "../FilterSwitches";

import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';

import {
  IIndexJsonData,
  IAggregateConfig,
  IPageLayoutProps,
  IFilterPanelState,
  ISelectedItems,
  IFilterConfig,
  ISelectionConfig,
  ISelectionAction,
  IRowConfig,
  IFilterPillGroup,
  IFilterCheckboxGroup,
  IPageConfig,
  IRowLimitConfig,
  IFilterSwitchGroup
} from "../../types";
import { ROW_LIMIT } from "../../../enablement/lib/constants";

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 600;
  text-decoration: bold;
  font-weight: 500;
  font-size: 17px;
  background: ${props => props.theme.providedColor};
  color: ${props => props.theme.activeText};
`;
StyledAlert.displayName = "StyledAlert";

const StyledCloseIcon = styled(CloseIcon)`
  display: inline-flex;
  position: relative;
`;
StyledCloseIcon.displayName = "StyledCloseIcon";

const StyledInvisible = styled(StyledCloseIcon)`
  width: 440px;
  color: ${props => props.theme.providedColor};
`;
StyledCloseIcon.displayName = "StyledCloseIcon";

const StyledPasswordLink = styled.a`
  display: inline-flex;
  text-decoration: underline;
  background: ${props => props.theme.providedColor};
  color: ${props => props.theme.activeText};
  position: relative;

  &:visited, &:hover {
    color: ${props => props.theme.solidBackground};
    text-decoration: underline;
  }
`;
StyledPasswordLink.displayName = "StyledPasswordLink";

const ActionBar = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const PopoverOptionsButton = styled(StyledButton)`
  display: inline;
  padding: 12px 14px;

  span {
    margin-right: 6px;
  }
`;
PopoverOptionsButton.displayName = "PopoverOptionsButton";

const Divider = styled.div<DefaultThemeProps>`
  margin-left: 10px;
  margin-right: 10px;
  width: 1px;
  height: 32px;
  background: ${props => props.theme.borderColor};
`;
Divider.displayName = "Divider";

const FilterSplitter = styled.div`
  margin-left: auto;
`;

type ListingPageProps = {
  pageLayoutProps: IPageLayoutProps;
  scrollableContent?(): React.ReactNode;
  overlayContent?(): React.ReactNode;
  heading: string;
  json: IIndexJsonData | null;
  row: IRowConfig;
  emptyResult?: React.ReactNode;
  aggregates?: ReadonlyArray<IAggregateConfig>;
  topBar?: React.ReactNode;
  search?: (value: string) => void;
  filter?: IFilterConfig;
  selection?: ISelectionConfig;
  pageSelect?: IPageConfig;
  rowLimitSelect?: IRowLimitConfig;
  isSearching?: boolean;
  isBanner?: boolean;
};

type ListingPageState = {
  selectedIds: ISelectedItems;
  activeSearch: boolean;
  pageNumber: number;
  rowLimit: number;
  filterState?: IFilterPanelState | null;
};

export class ListingPage extends Component<ListingPageProps, ListingPageState> {
  constructor(props: ListingPageProps) {
    super(props);

    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onClickUpdateSelection = this.onClickUpdateSelection.bind(this);
    this.onClickFilterAndReset = this.onClickFilterAndReset.bind(this);
    this.onClickFilters = this.onClickFilters.bind(this);
    this.onClickPageChange = this.onClickPageChange.bind(this);
    this.onClickRowChange = this.onClickRowChange.bind(this);
    this.onClickCloseBanner = this.onClickCloseBanner.bind(this);
    this.renderFilterAndOptions = this.renderFilterAndOptions.bind(this);
    this.renderSearchbox = this.renderSearchbox.bind(this);
    this.renderSelection = this.renderSelection.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderScrollableContent = this.renderScrollableContent.bind(this);
    this.renderTableContent = this.renderTableContent.bind(this);

    if (!('showBanner' in sessionStorage)) {
      sessionStorage.showBanner = props.isBanner ? '1' : '0';
    }
  }

  state: ListingPageState = {
    selectedIds: {},
    activeSearch: false,
    pageNumber: this.props.pageSelect ? this.props.pageSelect.pageNumber : 1,
    rowLimit: this.props.rowLimitSelect
      ? this.props.rowLimitSelect.rowLimit
      : ROW_LIMIT[0].value,
    filterState: {
      showCardDetails:
        (this.props.filter &&
          this.props.filter.initialFilterState &&
          this.props.filter.initialFilterState.showCardDetails) ||
        false
    }
  };

  onClickCloseBanner() {
    sessionStorage.showBanner = '0';
    this.forceUpdate();
  }

  onChangeSearch(value: string) {
    this.setState(
      {
        activeSearch: size(value) !== 0,
        pageNumber: 1,
        selectedIds: {}
      },
      () => {
        this.props.search && this.props.search(value);
      }
    );
  }

  onClickUpdateSelection(selectedIds: ISelectedItems) {
    this.setState({ selectedIds });
  }

  onClickFilterAndReset(nextFilterState: IFilterPanelState | null) {
    this.onClickFilters(nextFilterState);
    this.setState({ selectedIds: {} });
  }

  onClickFilters(nextFilterState: IFilterPanelState | null) {
    if (size(nextFilterState) === 0) {
      nextFilterState = null;
    } else if (
      every(values(nextFilterState), val => {
        return !Boolean(val);
      })
    ) {
      nextFilterState = null;
    }
    this.setState(
      {
        filterState: nextFilterState,
        pageNumber: 1
      },
      () => {
        this.props.filter && this.props.filter.handler(nextFilterState);
      }
    );
  }

  onClickPageChange(nextPageNumber: number) {
    this.setState(
      {
        pageNumber: nextPageNumber,
        selectedIds: {}
      },
      () => {
        this.props.pageSelect && this.props.pageSelect.handler(nextPageNumber);
      }
    );
  }

  onClickRowChange(nextRowLimit: number) {
    this.setState(
      {
        pageNumber: 1,
        rowLimit: nextRowLimit,
        selectedIds: {}
      },
      () => {
        this.props.rowLimitSelect &&
          this.props.rowLimitSelect.handler(nextRowLimit);
      }
    );
  }

  onClickOptionSelect(action: ISelectionAction) {
    action.handler(this.getSelectedIdsAsArray());
  }

  getSelectedIdsAsArray(): number[] {
    const { selectedIds } = this.state;
    const asArray: number[] = [];
    forEach(selectedIds, (value, key) => {
      value && asArray.push(parseInt(key, 10));
    });
    return asArray;
  }

  renderFilterAndOptions() {
    if (!this.props.filter && !this.props.selection) return;
    return (
      <ActionBar>
        {this.renderFilters()}
        {this.renderSelection()}
      </ActionBar>
    );
  }

  renderSearchbox() {
    const { activeSearch } = this.state;
    return (
      <Searchbox
        id={`${this.props.row.identifier}_search`}
        activeSearch={activeSearch}
        handleSearch={this.onChangeSearch}
      />
    );
  }

  renderSelection() {
    const { selection } = this.props;
    if (!selection) return;

    const { actions } = selection.handler ? selection.handler(this.getSelectedIdsAsArray()) : selection;
    if (!actions) return;

    const disabled = size(this.getSelectedIdsAsArray()) === 0;

    const popoverActions = actions.map(action => {
      return {
        text: action.text,
        clickHandler: () => this.onClickOptionSelect(action),
        disabled: action.disabled
      };
    });

    return (
      <Popover
        trigger={
          <Fragment>
            <span>Options</span>
            <Dots />
          </Fragment>
        }
        triggerComponent={PopoverOptionsButton}
        triggerComponentProps={{ disabled: disabled }}
        actions={popoverActions}
      />
    );
  }

  renderFilters() {
    if (!this.props.filter) return;

    const theFilterState = this.state.filterState || {};

    return this.props.filter.filters.map((filterGroup, idx) => {
      if (filterGroup.type === "pill") {
        return (
          <FilterPills
            key={filterGroup.key}
            filterGroup={filterGroup as IFilterPillGroup}
            filterState={theFilterState}
            applyFilters={this.onClickFilterAndReset}
          />
        );
      } else if (filterGroup.type === "checkbox") {
        return (
          <FilterCheckboxes
            key={filterGroup.key}
            filterGroup={filterGroup as IFilterCheckboxGroup}
            filterState={theFilterState}
            applyFilters={this.onClickFilterAndReset}
          />
        );
      } else if (filterGroup.type === "divider") {
        return <Divider key={`${filterGroup.key}_${idx}`}>&nbsp;</Divider>;
      } else if (filterGroup.type === "split") {
        return (
          <FilterSplitter key={`${filterGroup.key}_${idx}`}>
            &nbsp;
          </FilterSplitter>
        );
      } else if (filterGroup.type === "switch") {
        return (
          <FilterSwitches
            key={filterGroup.key}
            filterGroup={filterGroup as IFilterSwitchGroup}
            filterState={theFilterState}
            applyFilters={this.onClickFilters}
          />
        );
      }
      return null;
    });
  }

  renderScrollableContent() {
    return (
      <Fragment>
        {sessionStorage.showBanner === '1' && (
          <StyledAlert icon={false} severity="warning" >
            Create an account to unlock more features in the platform.&nbsp;
            <StyledPasswordLink href="/app/enablement/sign_up">Click here to add a password.</StyledPasswordLink>
            <StyledInvisible/>
            <StyledCloseIcon
                      type="submit"
                      onClick={() => {
                        this.onClickCloseBanner()
                      }}
            />
          </StyledAlert>
        )}
        {!this.props.scrollableContent ? (
          <Fragment>
            {this.props.topBar}
            {this.props.search && <ActionBar>{this.renderSearchbox()}</ActionBar>}
            {this.renderFilterAndOptions()}
            {this.renderTableContent()}
          </Fragment>
        ) : this.props.scrollableContent()}
      </Fragment>
    );
  }

  renderTableContent() {
    const {
      json,
      row,
      aggregates,
      selection,
      pageSelect,
      rowLimitSelect,
      isSearching,
      emptyResult
    } = this.props;

    const { selectedIds, pageNumber, rowLimit } = this.state;

    return (
      <DataTable
        json={json}
        row={row}
        aggregates={aggregates}
        selection={selection}
        updateSelection={this.onClickUpdateSelection}
        pageSelect={pageSelect}
        applyPage={this.onClickPageChange}
        rowLimitSelect={rowLimitSelect}
        applyRowLimit={this.onClickRowChange}
        selectedIds={selectedIds}
        pageNumber={pageNumber}
        rowLimit={rowLimit}
        isSearching={isSearching}
        emptyResult={emptyResult}
      />
    );
  }

  render() {
    return (
      <PageLayout
        pageLayoutProps={this.props.pageLayoutProps}
        title={this.props.heading}
        scrollableContent={this.renderScrollableContent}
        overlayContent={this.props.overlayContent}
      />
    );
  }
}

export default ListingPage;
