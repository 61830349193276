import React from "react";
import styled from "styled-components";
import ArrowRight from "@material-ui/icons/ArrowRightAlt";
import Scrollbars from "react-custom-scrollbars";
import FieldBoxComponent from "../../../common/components/FieldBox";
import { ButtonMedium } from "../../../common/components/StyledButton";
import SeparatorDiv from "../../../common/components/SeparatorDiv";
import { IField } from "../../../common/types";

type Props = {
  fields: IField[];
  updateFields: Function;
};

const StyledLabel = styled.label`
  font-weight: 500;
  font-size: 15px;
  line-height: 50px;
  margin-bottom: 0;
  margin-left: 19px;
`;
StyledLabel.displayName = "StyledLabel";

const SeparatorDivFlexEnd = styled(SeparatorDiv)`
  justify-content: flex-end;
`;

const StyledButtonMedium = styled(ButtonMedium)`
  margin: 8px 20px 8px 0;
`;
StyledButtonMedium.displayName = "StyledButtonMedium";

const SelectedAllButton = styled(StyledButtonMedium)`
  padding-left: 8px;
  svg {
    margin-left: 8px;
  }
`;
SelectedAllButton.displayName = "SelectedAllButton";

const AvailableFields = (props: Props) => {
  let addFields = (field?: IField) => {
    const selectedFields = props.fields.filter(selectedField => {
      return selectedField.isSelected;
    });
    let newFields = props.fields.map((currentField, index) => {
      if (field) {
        // select the specific field
        if (field.name === currentField.name) {
          currentField.isSelected = true;
          // it will be the latest item when add
          currentField.selectedOrder = selectedFields.length;
          // init selected alias
          currentField.selectedAlias = currentField.description;
        }
      } else {
        // if don't have specific field add all fields
        currentField.isSelected = true; // add field just selected to true
        currentField.selectedOrder = index;
        // init selected alias
        currentField.selectedAlias = currentField.description;
      }
      return currentField;
    });
    props.updateFields(newFields);
  };
  let availableFields = props.fields.filter(field => {
    return !field.isSelected;
  });
  return (
    <FieldBoxComponent title="Available Fields">
      <Scrollbars>
        <SeparatorDivFlexEnd id="selected_field_add_all_button_wrapper">
          <SelectedAllButton
            id="selected_field_add_all_button"
            disabled={availableFields.length === 0}
            onClick={() => {
              addFields();
            }}
          >
            Select All <ArrowRight />
          </SelectedAllButton>
        </SeparatorDivFlexEnd>
        <div key="available_fields_list" id="available_fields_list">
          {availableFields.map((field, i) => {
            return (
              <SeparatorDiv
                key={`available_field_${i}`}
                id={`available_field_${i}`}
              >
                <StyledLabel>{field.description}</StyledLabel>
                <StyledButtonMedium
                  onClick={() => {
                    addFields(field);
                  }}
                >
                  <ArrowRight />
                </StyledButtonMedium>
              </SeparatorDiv>
            );
          })}
        </div>
      </Scrollbars>
    </FieldBoxComponent>
  );
};

export default AvailableFields;
