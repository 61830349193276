import React from "react";
import ReactDOMServer from "react-dom/server";
import { DefaultTheme, createGlobalStyle } from "styled-components";
import Currency from "react-currency-formatter";
import Big from "big.js";
import color from "onecolor";
import reboot from "styled-reboot";
import Payment from "payment";
import moment from "moment";
import { isInteger } from "lodash";
import { CURRENCY_LOCALE } from "./constants";

export const centsToDollars = (cents: number): number => {
  const dollars = new Big(cents).div(100).toFixed(2);
  return Number(dollars);
};

export const formatCurrencyCode = (currencyCode: string): string => {
  const currencyCodePattern = /^[A-Z]{3}$/i;
  if (!currencyCodePattern.test(currencyCode)) {
    return "";
  }
  return `${currencyCode.toUpperCase()} `;
};

export const formattedDollars = (
    dollars: number,
    currency: string,
    preferredLanguage: string,
    currencyFormat: string
): string => {
  return ReactDOMServer.renderToString(
      <Currency
          pattern={currencyFormat}
          quantity={dollars}
          symbol={`${formatCurrencyCode(currency)}`}
          currency={currency}
          locale={CURRENCY_LOCALE[preferredLanguage]}
      />
  );
};

export const centsToDisplay = (cents: number, currency: string = ""): string => {
  if (!isInteger(cents)) {
    return "";
  }

  return formattedDollars(
      centsToDollars(cents),
      currency,
      "en",
      "!#,##0.00;(!#,##0.00)"
  );
};

const calculateColorVariants = (
  providedColor: string
): {
  lighterVariant: string;
  darkerVariant: string;
  selectedVariant: string;
} => {
  const parsedProvidedColor = color(providedColor);
  const theHue = parseInt(
    new Big(parsedProvidedColor.hue()).times(360).toFixed(),
    10
  );
  const lighterSign =
    theHue <= 60 ||
    (theHue > 120 && theHue <= 180) ||
    (theHue > 240 && theHue <= 300)
      ? 1
      : -1;
  const lighterHueDelta =
    parseFloat(new Big(2).div(360).toPrecision(10)) * lighterSign;
  const lighterSaturationDelta = -0.08;
  const lighterValueDelta = 0.08;
  const darkerHueDelta = lighterHueDelta * -1;
  const darkerSaturationDelta = lighterSaturationDelta * -1;
  const darkerValueDelta = lighterValueDelta * -1;

  const lighterVariant = parsedProvidedColor
    .hue(lighterHueDelta, true)
    .saturation(lighterSaturationDelta, true)
    .value(lighterValueDelta, true)
    .hex();

  const darkerVariant = parsedProvidedColor
    .hue(darkerHueDelta, true)
    .saturation(darkerSaturationDelta * -1, true)
    .value(darkerValueDelta * -1, true)
    .hex();

  const selectedVariant = parsedProvidedColor.alpha(0.08).cssa();

  return { lighterVariant, darkerVariant, selectedVariant };
};

export const createTheme = (providedColor: string): DefaultTheme => {
  const {
    lighterVariant,
    darkerVariant,
    selectedVariant
  } = calculateColorVariants(providedColor);

  return {
    providedColor,
    providedColorLighter: lighterVariant,
    providedColorDarker: darkerVariant,
    selectedColor: selectedVariant,

    positiveText: "#628020",
    negativeText: "#D7373C",
    interactiveText: providedColor,

    primaryText: "#444340",
    secondaryText: "#96918B",
    tertiaryText: "#B3ACA4",
    activeText: "#FFFFFF",
    activeLinkText: "#007bff",

    borderColor: "rgba(0,0,0,0.08)",
    overlayColor: "rgba(0,0,0,0.35)",
    shadowColor: "rgba(0,0,0,0.15)",

    solidBackground: "#FFFFFF",
    inactiveBackground: "#FAFAFA",
    titleBackground: "#F7F7F7",
    mutedBackground: "rgba(0,0,0,0.04)"
  };
};

export const GlobalStyle = createGlobalStyle`
  ${reboot()}
  body {
    margin: 0;
    padding: 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: normal;
    color: ${props => props.theme.primaryText};
  }

  a:hover, a:focus {
    text-decoration: none;
    color: ${props => props.theme.activeText};
  }

  .Toastify__toast.arc-toast {
    width: 280px;
    margin: auto;
    padding: 0;
    cursor: auto;
    border-radius: 2px;
    box-shadow: 0px 3px 10px ${props => props.theme.shadowColor};
  }

  .Toastify__toast.arc-toast.clickable {
    cursor: pointer;
  }

  .Toastify__toast.arc-toast.Toastify__toast--error {
    color: ${props => props.theme.activeText};
    background: ${props => props.theme.negativeText};
  }

  .Toastify__toast.arc-toast.Toastify__toast--success {
    color: ${props => props.theme.activeText};
    background: ${props => props.theme.positiveText};
  }
`;

export const setBrandColor = (hex_color: string): void => {
  sessionStorage.setItem("hex_color", hex_color);
};

export const getBrandColor = (): string | null => {
  return sessionStorage.getItem("hex_color");
};

export const setBrandLogo = (brand_code: string): void => {
  return sessionStorage.setItem("brand_code", brand_code);
};

export const getBrandLogo = (): string | null => {
  return sessionStorage.getItem("brand_code");
};

export const setToken = (token: string): void => {
  sessionStorage.setItem("token", token);
};

export const getToken = (): string | null => {
  return sessionStorage.getItem("token");
};

export const clearToken = (): void => {
  sessionStorage.removeItem("token");
};

export const clearSession = (): void => {
  sessionStorage.clear();
};

export const formUrlEncodedHeader = () => {
  return { "content-type": "application/x-www-form-urlencoded" };
};

export const authorizationHeader = (): { [index: string]: string } => {
  const token = getToken();
  if (token) {
    return { Authorization: `Bearer ${token}` };
  }
  return {};
};

export const formatCreditCard = (card: string): string => {
  const formatted = Payment.fns.formatCardNumber(card);
  if (formatted.length <= card.length) {
    return groupIntoFour(card);
  }
  return formatted;
};

const groupIntoFour = (input: string): string => {
  let parts = input.split(/\b/);
  let result = parts.map(part => {
    if (part.length === 16 && /^[0-9xX]+$/.test(part)) {
      return part.replace(/(.{4})/g, '$1 ').trim();
    }
    return part;
  });
  return result.join('');
}

export const formatDate = (
  date: string | number | moment.Moment | Date,
  toFormat: string,
  fromFormat?: string
): string => {
  if (date) {
    const parseFormat = fromFormat || moment.ISO_8601;
    return moment(date, parseFormat, true).format(toFormat);
  } else {
    return "";
  }
};

export const formatNormalDate = (date: number, format?: string): string => {
  return moment(date).format(format || "MM/DD/YYYY - h:mmA");
};

export const formatNormalUtcDate = (date: number, format?: string): string => {
  return moment.utc(date).format(format || "MM/DD/YYYY - h:mmA");
};

export const getUpperName = (name: string): string => {
  let names = name.replace("_", " ").split(" ");
  for (let i = 0; i < names.length; i++) {
    if (names[i][0] !== undefined) {
      names[i] = names[i][0].toUpperCase() + names[i].substr(1);
    }
  }
  return names.join(" ");
};
