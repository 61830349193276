import React from "react";
import NavLinkTabs from "../../../common/components/NavLinkTabs";

const PaymentExportTabs = () => {
  return (
    <NavLinkTabs
      links={[
        {
          key: "payment_export_exports",
          text: "Exports",
          linkTo: "/enablement/payment_exports"
        },
        {
          key: "payment_export_schedules",
          text: "Schedules & Formats",
          linkTo: "/enablement/payment_exports/schedules"
        }
      ]}
      id="payment_exports_tabs"
    />
  );
};

export default PaymentExportTabs;
