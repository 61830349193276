import React from "react";
import styled, { DefaultThemeProps } from "styled-components";
import ArrowRight from "@material-ui/icons/ArrowRightAlt";
import CloseIcon from "@material-ui/icons/Close";
import Scrollbars from "react-custom-scrollbars";
import FieldBoxComponent from "../../../common/components/FieldBox";
import { ButtonMedium } from "../../../common/components/StyledButton";
import SeparatorDiv from "../../../common/components/SeparatorDiv";
import Input from "../../../common/components/Input";
import { IField } from "../../../common/types";

type Props = {
  fields: IField[];
  updateFields: Function;
};

const UseArrowGuideline = styled.div<DefaultThemeProps>`
  padding: 20px;
  font-size: 14px;
  color: ${props => props.theme.tertiaryText};
`;
UseArrowGuideline.displayName = "UseArrowGuideline";

const ArrowLeft = styled(ArrowRight)`
  transform: rotate(-180deg);
`;

const ArrowUp = styled(ArrowRight)`
  transform: rotate(-90deg);
`;
ArrowUp.displayName = "ArrowUp";

const ArrowDown = styled(ArrowRight)`
  transform: rotate(90deg);
`;
ArrowDown.displayName = "ArrowDown";

const SeparatorDivWithInput = styled(SeparatorDiv)`
  padding: 10px 0px 10px 19px;
  align-items: flex-end;
`;

const SeparatorDivFlexEnd = styled(SeparatorDiv)`
  justify-content: flex-end;
`;

const RemoveButton = styled(ButtonMedium)`
  background: ${props => props.theme.negativeText};
  margin: 8px 20px 8px 0;
`;
RemoveButton.displayName = "RemoveButton";

const RemoveAllButton = styled(RemoveButton)`
  padding-right: 8px;
  svg {
    margin-right: 8px;
  }
`;
RemoveAllButton.displayName = "RemoveAllButton";

const Buttons = styled.div`
  margin-bottom: -4px;
`;

const UpDownButton = styled(ButtonMedium)`
  margin-right: 8px;
`;
UpDownButton.displayName = "UpDownButton";

const StyledDiv = styled.div`
  width: 240px;
`;

const SelectedFields = (props: Props) => {
  const selectedFieldsSorted = props.fields
    .filter(field => {
      return field.isSelected;
    })
    .sort((field1, field2) => {
      return field1.selectedOrder - field2.selectedOrder;
    });

  const removeFields = (field?: IField) => {
    var newFields = props.fields.map(oldField => {
      if (!field) {
        // don't have specific field mean remove all
        oldField.isSelected = false;
      } else {
        if (field.name === oldField.name) {
          // remove the specific field
          oldField.isSelected = false; // remove field just selected to false
        }
      }
      oldField.selectedOrder = 0; // reset order
      return oldField;
    });
    props.updateFields(newFields);
  };

  // direction should be 1 or -1
  const moveField = (currentIndex: number, direction: number) => {
    let newSortedFields = selectedFieldsSorted.map((selectedField, i) => {
      selectedField.selectedOrder = i;
      if (i === currentIndex) {
        // the current item
        selectedField.selectedOrder = currentIndex + direction;
      }
      if (i === currentIndex + direction) {
        // if move down here is the next item, if move up here is the previous item
        selectedField.selectedOrder = currentIndex;
      }
      return selectedField;
    });
    // update the order to the props.fields
    let newFields = props.fields.map(field => {
      let newField = newSortedFields.find(
        sortedField => sortedField.name === field.name
      );
      if (newField) {
        field.selectedOrder = newField.selectedOrder;
      }
      return field;
    });
    props.updateFields(newFields);
  };

  const moveFieldUp = (currentIndex: number) => {
    if (currentIndex === 0) return;
    moveField(currentIndex, -1);
  };

  const moveFieldDown = (currentIndex: number) => {
    if (currentIndex === selectedFieldsSorted.length) return; // do nothing for the last item
    moveField(currentIndex, 1);
  };

  const updateSelectedKey = (value: string, field: IField) => {
    var newFields = props.fields.map(oldField => {
      if (field.name === oldField.name) {
        oldField.selectedAlias = value;
      }
      return oldField;
    });
    props.updateFields(newFields);
  };

  return (
    <FieldBoxComponent title="Selected Fields">
      {selectedFieldsSorted.length ? (
        <Scrollbars>
          <SeparatorDivFlexEnd id="selected_field_remove_all_button_wrapper">
            <RemoveAllButton
              id="selected_field_remove_all_button"
              onClick={() => {
                removeFields();
              }}
            >
              <ArrowLeft /> Remove All
            </RemoveAllButton>
          </SeparatorDivFlexEnd>
          <div key="selected_fields_list" id="selected_fields_list">
            {selectedFieldsSorted.map((field, i) => {
              return (
                <SeparatorDivWithInput
                  key={`selected_field_${i}`}
                  id={`selected_field_${i}`}
                >
                  <StyledDiv>
                    <Input
                      header={field.description}
                      placeholder={field.name}
                      value={field.selectedAlias}
                      maxLength={255}
                      onChange={(
                        event: React.ChangeEvent<{ value: string }>
                      ) => {
                        updateSelectedKey(event.currentTarget.value, field);
                      }}
                    />
                  </StyledDiv>
                  <Buttons>
                    <UpDownButton
                      onClick={() => {
                        moveFieldUp(i);
                      }}
                    >
                      <ArrowUp />
                    </UpDownButton>
                    <UpDownButton
                      onClick={() => {
                        moveFieldDown(i);
                      }}
                    >
                      <ArrowDown />
                    </UpDownButton>
                    <RemoveButton
                      onClick={() => {
                        removeFields(field);
                      }}
                    >
                      <CloseIcon />
                    </RemoveButton>
                  </Buttons>
                </SeparatorDivWithInput>
              );
            })}
          </div>
        </Scrollbars>
      ) : (
        <UseArrowGuideline id="selected_field_guideline_text">
          <span>Use the Arrow Buttons on the left to begin...</span>
        </UseArrowGuideline>
      )}
    </FieldBoxComponent>
  );
};

export default SelectedFields;
