import React, { memo, useState } from "react";
import Dropdown from "../../../common/components/Dropdown";
import StyledButton from "../../../common/components/StyledButton";

import Input from "../../../common/components/Input";
import styled, { DefaultThemeProps } from "styled-components";
import { IDropdownItem, IUsersFormat } from "../../../common/types";

import moment from "moment";

const BoxDiv = styled.div<DefaultThemeProps>`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  min-width: 280px;
  max-width: 420px;
  min-height: 300px;

  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 4px 14px ${props => props.theme.shadowColor};
`;
BoxDiv.displayName = "BoxDiv";

const EditWrapper = styled.div`
  display: flex;
  height: 651px;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 60px 60px 40px 60px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;
EditWrapper.displayName = "EditWrapper";

const EmailWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
EmailWrapper.displayName = "EmailWrapper";

const UsersDialogRowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
UsersDialogRowWrapper.displayName = "UsersDialogRowWrapper";

const UsersDialogButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
UsersDialogButtonWrapper.displayName = "UsersDialogButtonWrapper";

const ConfirmationWrapper = styled.div<DefaultThemeProps>`
  text-align: center;
  max-width: 320px;
  height: 98px;
  margin-bottom: 20px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
ConfirmationWrapper.displayName = "ConfirmationWrapper";

const ConfirmationHeaderText = styled.span`
  font-size: 24px;
  line-height: 29px;
  font-weight: bold;
  color: ${props => props.theme.primaryText};
`;
ConfirmationHeaderText.displayName = "ConfirmationHeaderText";

const ConfirmationSecondaryText = styled.span`
  font-size: 16px;
  font-weight: normal;
  line-height: 150%;
  color: ${props => props.theme.secondaryText};
`;
ConfirmationSecondaryText.displayName = "ConfirmationSecondaryText";

const ConfirmationButton = styled(StyledButton)`
  padding: 12px 50px;
`;
ConfirmationButton.displayName = "ConfirmationButton";

type UsersDialogProps = {
  userID?: number;
  onClose: () => void;
  onConfirm: (details: IUsersFormat) => void;
};

function AddUsersBox({ userID, onClose, onConfirm }: UsersDialogProps) {
  let content = [
    "Add A User",
    "Send an invitation to someone to allow them to join your account."
  ];

  const [email, setEmail] = useState("" as string);
  const [firstName, setFirstName] = useState("" as string);
  const [lastName, setLastName] = useState("" as string);
  const [userType, setUserType] = useState("" as string);
  const [title, setTitle] = useState("" as string);
  const [titleDetails, setTitleDetails] = useState("" as string);
  const [phoneNumber, setPhoneNumber] = useState("" as string);
  const [timeZone, setTimeZone] = useState(12341 as number);

  function handleFirstNameChange(event: React.ChangeEvent<{ value: string }>) {
    setFirstName(event.target.value as string);
  }

  function handleEmailChange(event: React.ChangeEvent<{ value: string }>) {
    setEmail(event.target.value as string);
  }

  function handleLastNameChange(event: React.ChangeEvent<{ value: string }>) {
    setLastName(event.target.value as string);
  }
  function handleTitleChange(event: React.ChangeEvent<{ value: string }>) {
    setTitle(event.target.value as string);
  }
  function handleTitleDetailsChange(
    event: React.ChangeEvent<{ value: string }>
  ) {
    setTitleDetails(event.target.value as string);
  }
  function handlePhoneNumberChange(
    event: React.ChangeEvent<{ value: string }>
  ) {
    setPhoneNumber(event.target.value as string);
  }
  function handleTimeZoneChange(timeZone: IDropdownItem) {
    setTimeZone(timeZone.value as number);
  }
  function handleUserTypeChange(userType: IDropdownItem) {
    setUserType(userType.value as string);
  }

  return (
    <BoxDiv>
      <EditWrapper>
        <ConfirmationWrapper>
          <ConfirmationHeaderText>{content[0]}</ConfirmationHeaderText>
          <ConfirmationSecondaryText>{content[1]}</ConfirmationSecondaryText>
        </ConfirmationWrapper>
        <Dropdown
          title="Role"
          id="dropdown_usertype"
          width={300}
          placeholder={"Select Role"}
          handleValue={handleUserTypeChange}
          required
          list={[
            { title: `Admin`, value: `Admin` },
            { title: `User`, value: `User` }
          ]}
        />
        <Input
          header="Email"
          isRequired={email === ""}
          width="300px"
          id="new_user_email"
          onChange={handleEmailChange}
          defaultValue={email}
        />
        <UsersDialogRowWrapper>
          <Input
            header="First Name"
            width="140px"
            id="new_user_fname"
            onChange={handleFirstNameChange}
            defaultValue={firstName}
          />
          <Input
            header="Last Name"
            width="140px"
            id="new_user_lname"
            onChange={handleLastNameChange}
            defaultValue={lastName}
          />
        </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <Input
            header="Title"
            width="140px"
            id="new_user_title"
            onChange={handleTitleChange}
            defaultValue={title}
          />
          <Input
            header="Department"
            width="140px"
            id="new_user_department"
            onChange={handleTitleDetailsChange}
            defaultValue={titleDetails}
          />
        </UsersDialogRowWrapper>
        <UsersDialogRowWrapper>
          <Input
            header="Phone Number"
            width="140px"
            onChange={handlePhoneNumberChange}
            id="new_user_phone"
            defaultValue={phoneNumber}
          />
          <Dropdown
            title="Time Zone"
            id="dropdown_timezone"
            width={140}
            handleValue={handleTimeZoneChange}
            placeholder={moment(timeZone).format("Z z")}
            list={[
              {
                title: `-04:00`,
                value: 1554933169020
              },
              {
                title: `-03:58`,
                value: 1554933169040
              }
            ]}
          />
        </UsersDialogRowWrapper>
        <UsersDialogButtonWrapper>
          <ConfirmationButton
            disabled={
              userType.trim() === ""
            }
            onClick={() => {
              onConfirm({
                userType,
                firstName,
                lastName,
                phoneNumber,
                title,
                titleDetails,
                timeZone,
                email
              } as IUsersFormat);
            }}
          >
            Add User
          </ConfirmationButton>
        </UsersDialogButtonWrapper>
      </EditWrapper>
    </BoxDiv>
  );
}

export default memo(AddUsersBox);
