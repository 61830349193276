import React, { Component } from "react";
import styled, { DefaultThemeProps, keyframes } from "styled-components";
import { withRouter, RouteComponentProps } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  postVerificationOfTC,
  toastPromiseCatch,
  standardPromiseCatch,
  getTermsOfConditions
} from "../lib/helpers";

import PageLayout from "../../common/components/PageLayout/PageLayout";
import StyledButton from "../../common/components/StyledButton";
import Checkbox from "../../common/components/Checkbox";

type TCState = {
  disableCheck: boolean;
  title: string;
  checkText: string;
  buttonText: string;
  checked: boolean;
  tcBodyText: string;
};

type TCProps = {} & RouteComponentProps;

type BooleanTypeCheck = {
  disabled: boolean;
};

type StyleProps = BooleanTypeCheck & DefaultThemeProps;

const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const TCCheckText = styled.span<StyleProps>`
  padding-left: 10px;
  font-size: 12px;
  text-align: left;
  font-style: normal;
  font-weight: normal;
  line-height: 150%;
  opacity: ${props => (props.disabled ? "0.3" : "1")};
  cursor: ${props => (props.disabled ? "auto" : "pointer")};
`;
TCCheckText.displayName = "TCCheckText";

const TCCheckWrapper = styled.div<StyleProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: ${props => (props.disabled ? "auto" : "pointer")};
`;
TCCheckWrapper.displayName = "TCCheckWrapper";

const TCWrapper = styled.div`
  display: flex;
  width: 800px;
  height: 800px;
  flex-direction: column;

  border: 1px solid ${props => props.theme.borderColor};
  box-sizing: border-box;
  box-shadow: 0px 3px 10px ${props => props.theme.shadowColor};
  border-radius: 3px;
`;
TCWrapper.displayName = "TCWrapper";

const TCButton = styled(StyledButton)`
  padding: 12px 34px;
`;
TCButton.displayName = "TCButton";

const TCHeader = styled.div`
  height: 34px;
  background: ${props => props.theme.titleBackground};
  border-radius: 3px 3px 0px 0px;
  padding: 10px 19px;
`;
TCHeader.displayName = "TCHeader";

const TCHeaderText = styled.span`
  font-weight: 600;
  font-size: 11px;
  color: ${props => props.theme.secondaryText};
  text-transform: uppercase;
  position: absolute;
`;
TCHeaderText.displayName = "TCHeaderText";

const fadein = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const TCBody = styled.div`
  animation: ${fadein} 0.35s;
  flex: 1;
  display: flex;
  overflow: auto;
  flex-direction: column;
  padding: 20px;
`;
TCBody.displayName = "TCBody";

const TCFooter = styled.div`
  border-top: 1px solid ${props => props.theme.borderColor};
  box-sizing: border-box;
  border-radius: 0px 0px 3px 3px;

  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
TCFooter.displayName = "TCFooter";

export class TermsAndConditionsPage extends Component<TCProps, TCState> {
  constructor(props: TCProps) {
    super(props);
    this.onClickToggleCheck = this.onClickToggleCheck.bind(this);
    this.onClickAcceptTerms = this.onClickAcceptTerms.bind(this);
    this.getData = this.getData.bind(this);
  }

  state: TCState = {
    disableCheck: false,
    title: "Terms & Conditions",
    checkText: "Yes, I agree to the terms of use.",
    buttonText: "Continue",
    checked: false,
    tcBodyText: ""
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    getTermsOfConditions()
      .then(resp =>
        this.setState({
          tcBodyText: resp.data
        })
      )
      .catch(standardPromiseCatch(this.props.history));
  }

  onClickToggleCheck() {
    let { disableCheck, checked } = this.state;

    if (!disableCheck) {
      this.setState({ checked: !checked });
    }
  }

  onClickAcceptTerms() {
    let { history } = this.props;

    postVerificationOfTC()
      .then(() => {
        history.replace("/enablement/payments");
      })
      .catch(toastPromiseCatch(history));
  }

  renderTermsAndConditions() {
    let { tcBodyText } = this.state;
    return (
      <ReactMarkdown
        source={tcBodyText.replace(/#####/g, "#### ")}
        escapeHtml={true}
      />
    );
  }

  render() {
    let { title, checked, disableCheck, checkText, buttonText } = this.state;

    return (
      <PageLayout>
        <PageWrapper>
          <TCWrapper id="terms_conditions_wrapper">
            <TCHeader id="terms_conditions_header">
              <TCHeaderText>{title}</TCHeaderText>
            </TCHeader>
            <TCBody id="terms_conditions_body">
              {this.renderTermsAndConditions()}
            </TCBody>

            <TCFooter id="terms_conditions_footer">
              <TCCheckWrapper
                disabled={disableCheck}
                id="terms_conditions_checkbox_wrapper"
                onClick={this.onClickToggleCheck}
              >
                <Checkbox checked={checked} />
                <TCCheckText disabled={disableCheck}>{checkText}</TCCheckText>
              </TCCheckWrapper>
              <TCButton
                id="terms_conditions_footer_button"
                disabled={!checked}
                onClick={this.onClickAcceptTerms}
              >
                {buttonText}
              </TCButton>
            </TCFooter>
          </TCWrapper>
        </PageWrapper>
      </PageLayout>
    );
  }
}

export default withRouter(TermsAndConditionsPage);
