import React, { memo, useState } from "react";
import Dialog from "../../common/components/Dialog";
import Dropdown from "../../common/components/Dropdown";
import styled from "styled-components";

import { IDropdownItem, IDialogType, IDialogFormat } from "../../common/types";

const exportText: IDialogType = "Export";
const alert: IDialogFormat = "alert";

const ExportWrapper = styled.div`
  width: 332px;
  height: 145px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
`;

const ExportText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: ${props => props.theme.primaryText};
  text-align: center;
`;
ExportText.displayName = "ExportText";

const ExportChild = styled.div`
  flex: 1;
  width: 240px;
  margin-left: 46px;
  margin-right: 46px;
`;

const ExportTextWrapper = styled.div`
  flex: 1;
  text-align: center;
`;

type ExportDialogProps = {
  list: Array<IDropdownItem | any>;
  onClose: () => void;
  onConfirm: (format: IDropdownItem) => void;
};

function ExportDialog({ list, onClose, onConfirm }: ExportDialogProps) {
  const [disabled, setDisable] = useState(false);
  const [selectedFormat, setSelectedFormat] = useState({} as IDropdownItem);

  function handleConfirmationButton(val: boolean) {
    setDisable(val);
  }

  function handleFormatVal(formatVal: IDropdownItem) {
    setSelectedFormat(formatVal);
  }

  return (
    <Dialog
      onClose={onClose}
      onConfirm={() => {
        onConfirm(selectedFormat);
      }}
      title="Export Format"
      noBodyMargin
      type={exportText}
      format={alert}
      isEnabled={disabled}
    >
      <ExportWrapper>
        <ExportTextWrapper>
          <ExportText>
            Select the format to be used for this payment export
          </ExportText>
        </ExportTextWrapper>
        <ExportChild>
          <Dropdown
            title="Format"
            required
            handleValue={handleFormatVal}
            placeholder={"Select Format"}
            list={list}
            handleReq={handleConfirmationButton}
          />
        </ExportChild>
      </ExportWrapper>
    </Dialog>
  );
}

export default memo(ExportDialog);
