import { AxiosResponse } from "axios";
import { find } from "lodash";
import {
  postJson,
  mockDataPromise,
  getSenderIdFromToken
} from "../lib/helpers";
import { USE_MOCK_JSON } from "../lib/constants";

import MOCK_PAYMENTS_DATA from "../assets/payments.json";

export const getPayments = (json: object): Promise<AxiosResponse> => {
  if (USE_MOCK_JSON) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mockDataPromise(MOCK_PAYMENTS_DATA));
      }, 750);
    });
  }

  return postJson("/querypayments/payments/advanced", json);
};

export const getPayment = (id: number): Promise<AxiosResponse> => {
  if (USE_MOCK_JSON) {
    const jsonData = JSON.parse(JSON.stringify(MOCK_PAYMENTS_DATA));
    const payment = find(jsonData.records, payment => {
      return payment.id === id;
    });
    jsonData.records = [payment];

    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mockDataPromise(jsonData));
      }, 500);
    });
  }

  const json: { [index: string]: any } = {
    sender_id: getSenderIdFromToken(),
    filters: [{ key: "id", value: [id] }],
    window_start: 0,
    window_size: 1,
    columns: [
      "id",
      "date",
      "created_at",
      "payor_name",
      "display_identifier",
      "amount_cents",
      "payment_amounts.amount_cents",
      "payment_amounts.invoice_number",
      "payment_amounts.invoice_identifier",
      "payment_amounts.invoice_display_number",
      "virtual_credit_cards.account_number",
      "virtual_credit_cards.expiry_date",
      "virtual_credit_cards.cvc2",
      "attributes.exported",
      "currency",
      "attributes.email_identifier"
    ]
  };
  return postJson("/querypayments/payments/advanced", json);
};
