import { IRowlimitItem, IDropdownItem } from "../../common/types";

export const BASE_URL: string = process.env.REACT_APP_API_BASE_URL
  ? process.env.REACT_APP_API_BASE_URL
  : "";
export const USE_MOCK_JSON: boolean = false; //TODO: this needs to be removed

export const ROW_LIMIT: ReadonlyArray<IRowlimitItem> = [
  { title: "15", value: 15 },
  { title: "30", value: 30 },
  { title: "60", value: 60 }
];

export const SCHEDULE_FREQUENCIES: Array<IDropdownItem> = [
  { title: "Daily", value: "daily" },
  { title: "Weekly", value: "weekly" }
];

export const FORMAT_MULTILINE: Array<IDropdownItem> = [
  { title: "Payment Header (CSV)", value: "payment_header" },
  { title: "Payment Detail (CSV)", value: "payment_detail" }
];
