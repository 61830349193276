import React from "react";
import styled, { DefaultThemeProps } from "styled-components";
import Check from "@material-ui/icons/Check";

type Props = { checked: boolean };

type StyleProps = Props & DefaultThemeProps;

const StyledCheck = styled(Check)`
  font-size: 20px;
  color: white;
`;
StyledCheck.displayName = "StyledCheck";

const Box = styled.div<StyleProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid ${props => props.theme.borderColor};
  border-radius: 4px;
  background: ${props =>
    props.checked ? props.theme.providedColor : props.theme.inactiveBackground};
`;
Box.displayName = "Box";

const Checkbox = ({ checked }: Props) => {
  return <Box checked={checked}>{checked && <StyledCheck />}</Box>;
};

export default Checkbox;
