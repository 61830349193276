import styled, { DefaultThemeProps } from 'styled-components';

type Props = {
  muted?: boolean
} & DefaultThemeProps;

const FullPageOverlay = styled.div<Props>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: ${props => (props.muted ? props.theme.overlayColor : '')};
`;
FullPageOverlay.displayName = 'FullPageOverlay';

export default FullPageOverlay;
