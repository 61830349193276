import React from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { NavLink } from "react-router-dom";

interface INavLinkTab {
  key: string;
  text: string;
  linkTo: string;
}

type Props = {
  links: ReadonlyArray<INavLinkTab>;
  id: string;
};

const NavigationLink = styled(NavLink)<DefaultThemeProps>`
  margin-left: 5px;
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.activeText};
  min-width: 220px;
  height: 44px;
  background: ${props => props.theme.secondaryText};
  box-shadow: 0px 3px 10px ${props => props.theme.shadowColor};
  border-radius: 4px;
  font-weight: normal;
  font-size: 16px

  :hover {
    color: ${props => props.theme.activeText};
  }

  &.active {
    background: ${props => props.theme.providedColor};
    cursor: pointer;
  }
`;
NavigationLink.displayName = "NavigationLink";

const NavLinksWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
`;
NavLinksWrapper.displayName = "NavLinksWrapper";

const NavLinkTabs = ({ links, id }: Props) => {
  function navLink(link: INavLinkTab) {
    const key = `nav_link_tab_${link.key}`;
    return (
      <NavigationLink id={key} key={key} to={link.linkTo} exact>
        {link.text}
      </NavigationLink>
    );
  }
  return (
    <NavLinksWrapper id={`nav_link_wrapper_${id}`}>
      {links.map(link => navLink(link))}
    </NavLinksWrapper>
  );
};

export default NavLinkTabs;
