import React, { Component, Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import filesize from "filesize";
import PaymentExportTabs from "./PaymentExportTabs";
import { concat, get } from "lodash";

import LineWeight from "@material-ui/icons/LineWeight";

import { EXPORT_STATES } from "../../../common/lib/constants";
import { formatDate } from "../../../common/lib/helpers";
import { getPreviewModeFromToken, pageLayoutProps, standardPromiseCatch } from "../../lib/helpers";
import { getExport, getExports } from "../../services/exportServices";

import DownloadButton from "../../../common/components/DownloadButton";
import StyledNavLink from "../../../common/components/StyledNavLink";
import ListingPage from "../../../common/components/PageLayout/ListingPage";
import {
  IIndexJsonData,
  IJsonRecord,
  IRowConfig,
  IPageConfig,
  IRowLimitConfig
} from "../../../common/types.js";
import { ROW_LIMIT } from "../../lib/constants";

const EmptyCardNavLink = styled(StyledNavLink)`
  margin-bottom: 30px;
`;
EmptyCardNavLink.displayName = "EmptyCardNavLink";

type PageProps = {} & RouteComponentProps;
type PageState = {
  jsonData: IIndexJsonData | null;
  windowStart: number;
  windowSize: number;
};

export class PaymentExportsListingPage extends Component<PageProps, PageState> {
  state: PageState = {
    jsonData: null,
    windowStart: 0,
    windowSize: ROW_LIMIT[0].value
  };

  constructor(props: PageProps) {
    super(props);

    this.onClickDownloadExport = this.onClickDownloadExport.bind(this);
    this.onClickPageChange = this.onClickPageChange.bind(this);
    this.onClickRowChange = this.onClickRowChange.bind(this);
    this.getData = this.getData.bind(this);
    this.renderRowConfig = this.renderRowConfig.bind(this);
    this.renderDownloadCell = this.renderDownloadCell.bind(this);
    this.renderPageConfig = this.renderPageConfig.bind(this);
    this.renderRowLimitConfig = this.renderRowLimitConfig.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  // TODO: PUSH LOWER
  getData() {
    const { windowStart, windowSize } = this.state;
    getExports(windowStart, windowSize)
      .then(response => this.setState({ jsonData: response.data }))
      .catch(standardPromiseCatch(this.props.history));
  }

  nameCell(record: IJsonRecord): string {
    return record.name.split("/")[2];
  }

  exportStatusCell(record: IJsonRecord): string | null {
    if (record.export_status) {
      if (record.export_status === "success") {
        return "positive";
      } else if (record.export_status === "failed") {
        return "negative";
      }
    }
    return null;
  }

  fileSizeCell(record: IJsonRecord): string {
    const noDownloadButtonStates = concat(
      EXPORT_STATES.failed,
      EXPORT_STATES.not_available,
      EXPORT_STATES.processing
    );
    if (
      !(
        record.export_status &&
        noDownloadButtonStates.includes(record.export_status)
      )
    ) {
      return filesize(record.size);
    } else {
      return "";
    }
  }

  onClickDownloadExport(
    format_name: string,
    export_date: number,
    export_id: number,
    event: React.FormEvent<HTMLFormElement>
  ) {
    event.preventDefault();
    const dateString = formatDate(export_date, "MMDDYYYY-hmm", "x");
    getExport(export_id, format_name, dateString).catch(
      standardPromiseCatch(this.props.history)
    );
  }

  renderDownloadCell(record: IJsonRecord) {
    const buttonClasses = [];
    if (record && record.export_status) {
      if (EXPORT_STATES.processing.includes(record.export_status)) {
        buttonClasses.push("disabled");
      }
    }
    const disableButton =
      EXPORT_STATES.processing.includes(record.export_status) ||
      record.remote_file_name === "null/null";
    const hideButton = concat(
      EXPORT_STATES.failed,
      EXPORT_STATES.not_available
    ).includes(record.export_status);
    return (
      <form
        onSubmit={e =>
          this.onClickDownloadExport(
            record.format_name,
            record.lastModifiedMillis,
            record.export_id,
            e
          )
        }
      >
        {!hideButton && (
          <DownloadButton
            id={`download_export_${record.export_id}`}
            disabled={disableButton}
            className={buttonClasses.join(" ")}
            type="submit"
          />
        )}
      </form>
    );
  }

  emptyResult() {
    return (
      <Fragment>
        <LineWeight />
        <span>
          Export your payments from the payment listing, or create a new export
          schedule.
        </span>
        <EmptyCardNavLink
          id="schedules_formats_link"
          to="/enablement/payment_exports/schedules"
          exact
        >
          <span>Create Export Schedule</span>
        </EmptyCardNavLink>
      </Fragment>
    );
  }

  exportTypeCellTemplate(record: IJsonRecord) {
    return "? Export";
  }

  numPaymentsCellTemplate(record: IJsonRecord) {
    let numberOfPayments = get(record, "number_of_payments");
    return numberOfPayments === 1 ? "? Payment" : "? Payments";
  }

  exportStatusCellTemplate(record: IJsonRecord) {
    if (
      record.export_status &&
      EXPORT_STATES.processing.includes(record.export_status)
    ) {
      return "?...";
    }
    return "?";
  }

  renderRowConfig(): IRowConfig {
    const emptySettings = {
      heading: {},
      width: ""
    };

    return {
      identifier: "payment_exports",
      options: [
        {
          heading: { text: "Date Exported" },
          width: "17%",
          dataType: "nested",
          nested: [
            {
              ...emptySettings,
              dataType: "date",
              attribute: "lastModifiedMillis",
              parseFormat: "x",
              displayFormat: "MM/DD/YYYY - h:mmA"
            },
            {
              ...emptySettings,
              dataType: "text",
              attribute: "export_type",
              template: this.exportTypeCellTemplate,
              titleCase: true,
              className: "smaller"
            }
          ]
        },
        {
          heading: { text: "Format" },
          width: "13.33%",
          attribute: "format_name",
          dataType: "text"
        },
        {
          heading: { text: "Result" },
          width: "11.80%",
          dataType: "text",
          attribute: "number_of_payments",
          template: this.numPaymentsCellTemplate,
          className: "primary"
        },
        {
          heading: { text: "Export Status" },
          width: "37.5%",
          dataType: "text",
          attribute: "export_status",
          titleCase: true,
          classDecorator: this.exportStatusCell,
          template: this.exportStatusCellTemplate
        },
        {
          heading: {},
          width: "15.3%",
          align: "right",
          dataType: "cell",
          cardCell: this.fileSizeCell
        },
        {
          heading: {},
          width: "5.4%",
          align: "left",
          dataType: "cell",
          cardCell: this.renderDownloadCell
        }
      ]
    };
  }

  onClickPageChange(pageNumber: number) {
    let { windowSize } = this.state;
    let windowStart = pageNumber - 1;

    this.setState(
      {
        windowStart,
        windowSize,
        jsonData: null
      },
      this.getData
    );
  }

  renderPageConfig(): IPageConfig {
    let pgNumber =
      Math.ceil(this.state.windowStart / this.state.windowSize) + 1;

    return {
      handler: this.onClickPageChange,
      pageNumber: pgNumber
    };
  }

  onClickRowChange(rowLimit: number) {
    this.setState(
      {
        windowStart: 0,
        windowSize: rowLimit,
        jsonData: null
      },
      this.getData
    );
  }

  renderRowLimitConfig(): IRowLimitConfig {
    return {
      handler: this.onClickRowChange,
      rowLimit: this.state.windowSize
    };
  }

  render() {
    return (
      <ListingPage
        heading="Payment Exports"
        isBanner={getPreviewModeFromToken()}
        topBar={<PaymentExportTabs />}
        json={this.state && this.state.jsonData}
        emptyResult={this.emptyResult()}
        row={this.renderRowConfig()}
        pageLayoutProps={pageLayoutProps()}
        pageSelect={this.renderPageConfig()}
        rowLimitSelect={this.renderRowLimitConfig()}
      />
    );
  }
}

export default withRouter(PaymentExportsListingPage);
