import React, { Component, Fragment } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import Close from "@material-ui/icons/Close";
import PaymentExportTabs from "./PaymentExportTabs";
import ScheduleDialog from "../ScheduleDialog";
import Toast from "../../../common/components/Toast";
import Popover from "../../../common/components/Popover";
import Dots from "@material-ui/icons/MoreHoriz";
import { withRouter, RouteComponentProps } from "react-router-dom";
import {
  pageLayoutProps,
  standardPromiseCatch,
  toastPromiseCatch
} from "../../lib/helpers";

import {
  getSchedules,
  createSchedule,
  deleteSchedule,
  scheduleCheck
} from "../../services/scheduleServices";

import {
  getFormat,
  deleteFormat,
  getFormats,
  getColumns
} from "../../services/formatServices";

import {
  IRowConfig,
  IIndexJsonData,
  IJsonRecord,
  IPopoverAction,
  IScheduleFormat,
  IField,
  IDropdownItem
} from "../../../common/types";
import DataTable from "../../../common/components/DataTable";
import ListingPage from "../../../common/components/PageLayout/ListingPage";

import { find, cloneDeep } from "lodash";

import { SCHEDULE_FREQUENCIES } from "../../lib/constants";
import StyledButton from "../../../common/components/StyledButton";
import Dialog from "../../../common/components/Dialog";
import PaymentExportFormatModal from "./PaymentExportFormatModal";

type PageProps = {} & RouteComponentProps;

type PageState = {
  scheduleToDelete: IJsonRecord | null;
  schedulesJson: IIndexJsonData | null;
  formatToDelete: IJsonRecord | null;
  formatsJson: IIndexJsonData | null;
  createSchedule: boolean;
  createFormat: boolean;
  isProcessingFormat: boolean;
  isProcessingSchedule: boolean;
  selectedFrequency: IJsonRecord | null;
  selectedFormat: IJsonRecord | null;
  formatToDuplicate: IJsonRecord | null;
  originalFields: IField[];
  fields: IField[];
  failFormat: boolean;
};

const SpacerDataTable = styled(DataTable)`
  margin-top: 25px;
`;

const StyledClose = styled(Close)`
  font-size: 31px;
`;
StyledClose.displayName = "StyledClose";

const MenuButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 40px;
  height: 30px;
  color: ${props => props.theme.providedColor};
  &.popoverOpen {
    color: ${props => props.theme.activeText};
    background: ${props => props.theme.providedColor};
  }
`;
MenuButton.displayName = "MenuButton";

const DeleteButton = styled(StyledButton)<DefaultThemeProps>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  margin-left: auto;
  padding: 0;
  background: ${props => props.theme.negativeText};
`;
DeleteButton.displayName = "DeleteButton";

const ConfirmationWrapper = styled.div<DefaultThemeProps>`
  margin: 30px 30px 40px;
  color: ${props => props.theme.primaryText};
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  max-width: 332px;
  line-height: 150%;
  > p:last-child {
    margin-bottom: 0;
  }
`;

export class PaymentExportsConfigPage extends Component<PageProps, PageState> {
  constructor(props: PageProps) {
    super(props);

    this.onClickNewSchedule = this.onClickNewSchedule.bind(this);
    this.onClickNewFormat = this.onClickNewFormat.bind(this);
    this.onClickUpdateFormatsJson = this.onClickUpdateFormatsJson.bind(this);
    this.onClickCloseScheduleDialog = this.onClickCloseScheduleDialog.bind(
      this
    );
    this.onClickCloseFormatDialog = this.onClickCloseFormatDialog.bind(this);
    this.onClickConfirmScheduleDialog = this.onClickConfirmScheduleDialog.bind(
      this
    );
    this.getColumns = this.getColumns.bind(this);
    this.getSchedules = this.getSchedules.bind(this);
    this.getFormats = this.getFormats.bind(this);
    this.renderDeleteScheduleCell = this.renderDeleteScheduleCell.bind(this);
    this.renderUpdateProcessingFormat = this.renderUpdateProcessingFormat.bind(
      this
    );
    this.renderUpdateProcessingSchedule = this.renderUpdateProcessingSchedule.bind(
      this
    );
    this.renderSchedules = this.renderSchedules.bind(this);
    this.renderFormatsRowMenu = this.renderFormatsRowMenu.bind(this);
    this.renderFormatsRowMenuOptions = this.renderFormatsRowMenuOptions.bind(
      this
    );
    this.renderFormatsConfig = this.renderFormatsConfig.bind(this);
    this.renderFormats = this.renderFormats.bind(this);
    this.renderPageContent = this.renderPageContent.bind(this);
    this.renderOverlayContent = this.renderOverlayContent.bind(this);
  }

  state = {
    isProcessingFormat: false,
    isProcessingSchedule: false,
    scheduleToDelete: null,
    schedulesJson: null,
    formatToDelete: null,
    formatsJson: null,
    createSchedule: false,
    createFormat: false,
    selectedFormat: null,
    selectedFrequency: null,
    formatToDuplicate: null,
    failFormat: false,
    originalFields: [],
    fields: []
  };

  componentDidMount() {
    this.getSchedules();
    this.getFormats();
    this.getColumns();
  }

  getColumns() {
    getColumns()
      .then(response => {
        let value = response.data.records.map((val: any) => {
          return {
            name: val.name,
            description: val.description,
            isSelected: false,
            selectedOrder: 0
          };
        });
        this.setState({
          originalFields: cloneDeep(value),
          fields: cloneDeep(value)
        });
      })
      .catch(standardPromiseCatch(this.props.history));
  }

  getSchedules() {
    getSchedules()
      .then(response => {
        this.setState({
          schedulesJson: response.data,
          isProcessingSchedule: false,
          scheduleToDelete: null
        });
      })
      .catch(standardPromiseCatch(this.props.history));
  }

  getFormats() {
    getFormats()
      .then(response => {
        this.setState({
          formatsJson: response.data,
          isProcessingFormat: false,
          formatToDelete: null
        });
      })
      .catch(standardPromiseCatch(this.props.history));
  }

  onClickNewSchedule() {
    this.setState({ createSchedule: true });
  }

  onClickNewFormat() {
    this.setState({ createFormat: true });
  }

  renderDeleteScheduleCell(record: IJsonRecord) {
    return (
      <DeleteButton onClick={() => this.setState({ scheduleToDelete: record })}>
        <StyledClose />
      </DeleteButton>
    );
  }

  onClickUpdateFormatsJson(newFormatsJson: IIndexJsonData) {
    this.setState(
      Object.assign({}, this.state, { formatsJson: newFormatsJson })
    );
  }

  renderUpdateProcessingFormat(isProcessing: boolean) {
    this.setState(
      Object.assign({}, this.state, { isProcessingFormat: isProcessing })
    );
  }

  renderUpdateProcessingSchedule(isProcessing: boolean) {
    this.setState(
      Object.assign({}, this.state, { isProcessingSchedule: isProcessing })
    );
  }

  schedulesConfig(): IRowConfig {
    return {
      identifier: "payment_export_schedules",
      options: [
        {
          heading: { text: "Format" },
          width: "11.55%",
          dataType: "text",
          attribute: "format_name",
          className: "primary"
        },
        {
          heading: { text: "Frequency" },
          width: "11.89%",
          dataType: "text",
          attribute: "schedule",
          className: "secondary",
          titleCase: true
        },
        {
          heading: { text: "Last Run" },
          width: "17.4%",
          dataType: "date",
          attribute: "last_run_at",
          parseFormat: "x",
          displayFormat: "MM/DD/YYYY - h:mmA",
          className: "secondary"
        },
        {
          heading: { text: "Next Run" },
          width: "17.4%",
          dataType: "date",
          attribute: "next_run_at",
          parseFormat: "x",
          displayFormat: "MM/DD/YYYY - h:mmA",
          className: "secondary"
        },
        {
          heading: { text: "SFTP Destination" },
          width: "34.26%",
          dataType: "text",
          attribute: "sftp_destination",
          className: "secondary"
        },
        {
          heading: {},
          width: "7.5%",
          dataType: "cell",
          cardCell: this.renderDeleteScheduleCell
        }
      ]
    };
  }

  renderSchedules() {
    return (
      <SpacerDataTable
        header={{
          text: "Schedules",
          actions: [
            { text: "New Schedule", clickHandler: this.onClickNewSchedule }
          ]
        }}
        json={this.state.schedulesJson}
        row={this.schedulesConfig()}
        isProcessing={this.state.isProcessingSchedule}
      />
    );
  }

  updateFormatName(responseDataName: string): string {
    const pattern = /\(Copy\d*\)/g;
    let formatNameTrimmed = responseDataName.replace(pattern, "").trim();
    let copyVal = responseDataName.trim().match(pattern);
    let newFormatName = `${formatNameTrimmed} (Copy)`;
    if (copyVal) {
      let sliceVal = "1";
      let copyNumber = copyVal[0].slice(5, -1);

      if (copyNumber.length !== 0) {
        sliceVal = (parseInt(copyVal[0].slice(5, -1)) + 1).toString();
      }
      newFormatName = `${formatNameTrimmed} (Copy${sliceVal})`;
    }

    return newFormatName;
  }

  renderFormatsRowMenu(record: IJsonRecord) {
      if (record.name === "RDI820") {
        return null;
      }
      return (
        <div className="secondary">
          <Popover
            trigger={<Dots />}
            triggerComponent={MenuButton}
            actions={this.renderFormatsRowMenuOptions(record)}
          />
        </div>
      );
  }

  renderFormatsRowMenuOptions(record: IJsonRecord): IPopoverAction[] {
    const actions: IPopoverAction[] = [
      {
        text: "Duplicate",
        clickHandler: () => {
          getFormat(record.id)
            .then(response => {
              const columns = JSON.parse(response.data.columns);
              response.data.name = this.updateFormatName(response.data.name);

              const formatDuplicateColumns = this.state.fields.map(
                (value: any) => {
                  const order = find(columns, (column: any) => {
                    return value.name === column.column_name;
                  });

                  const selectedValue = columns.some((v: any) => {
                    return value.name === v.column_name;
                  });

                  return {
                    ...value,
                    isSelected: selectedValue,
                    selectedAlias: order && order.alias,
                    selectedOrder: order && order.order
                  };
                }
              );

              this.setState(
                {
                  failFormat: false,
                  formatToDuplicate: response.data,
                  fields: formatDuplicateColumns
                },
                () => {
                  this.onClickNewFormat();
                }
              );
            })
            .catch(toastPromiseCatch(this.props.history));
        }
      }
    ];

    if (record.deletable) {
      actions.push({
        text: "Remove",
        negative: true,
        clickHandler: () => {
          scheduleCheck(record.id)
            .then(resp => {
              record.schedule_count = resp.data.schedule_count;
              this.setState({ formatToDelete: record });
            })
            .catch(toastPromiseCatch(this.props.history));
        }
      });
    }

    return actions;
  }

  renderMultiLineCell(record: IJsonRecord) {
    if (record.name === "RDI820") {
      return (
        <span>
          RDI820
        </span>
      );
    }
    const multiLine = Boolean(record && record.multi_line_invoice);
    return (
      <span>
        {multiLine ? `Payment Detail\n(CSV)` : `Payment Header\n(CSV)`}
      </span>
    );
  }

  renderFormatsConfig(): IRowConfig {
    return {
      identifier: "payment_export_formats",
      options: [
        {
          heading: { text: "Name" },
          width: "11.55%",
          dataType: "text",
          attribute: "name",
          className: "primary"
        },
        {
          heading: { text: "Created By" },
          width: "15.55%",
          dataType: "nested",
          nested: [
            {
              heading: {},
              width: "",
              dataType: "text",
              attribute: "created_by",
              className: "secondary"
            },
            {
              heading: {},
              width: "",
              dataType: "date",
              attribute: "created_at",
              parseFormat: "x",
              displayFormat: "MM/DD/YYYY - h:mmA",
              className: "secondary smaller"
            }
          ]
        },
        {
          heading: { text: "Description" },
          width: "34.4%",
          dataType: "text",
          attribute: "description",
          className: "secondary"
        },
        {
          heading: { text: "Format Type" },
          width: "34.4%",
          dataType: "cell",
          attribute: "multi_line_invoice",
          className: "secondary",
          cardCell: this.renderMultiLineCell
        },
        {
          heading: { text: "" },
          width: "4.1%",
          align: "center",
          dataType: "cell",
          className: "secondary",
          cardCell: this.renderFormatsRowMenu
        }
      ]
    };
  }

  renderFormats() {
    return (
      <SpacerDataTable
        header={{
          text: "Formats",
          actions: [{ text: "New Format", clickHandler: this.onClickNewFormat }]
        }}
        json={this.state.formatsJson}
        row={this.renderFormatsConfig()}
        isProcessing={this.state.isProcessingFormat}
      />
    );
  }

  renderPageContent() {
    return (
      <Fragment>
        <PaymentExportTabs />
        {this.renderSchedules()}
        {this.renderFormats()}
      </Fragment>
    );
  }

  removalConfirmation(
    onClose: () => void,
    onConfirm: () => void,
    messages: string[]
  ) {
    return (
      <Dialog
        title="Removal Confirmation"
        type="Remove"
        format="alert"
        onClose={onClose}
        onConfirm={onConfirm}
        noBodyMargin
      >
        <ConfirmationWrapper>
          {messages.map((message, idx) => (
            <p key={`removal_confirmation_message_${idx}`}>{message}</p>
          ))}
        </ConfirmationWrapper>
      </Dialog>
    );
  }

  formatNotice(onClose: () => void, messages: string[]) {
    return (
      <Dialog
        title="Removal Notice"
        type="Notice"
        format="alert"
        onClose={onClose}
        noBodyMargin
      >
        <ConfirmationWrapper>
          {messages.map((message, idx) => (
            <p key={`removal_confirmation_message_${idx}`}>{message}</p>
          ))}
        </ConfirmationWrapper>
      </Dialog>
    );
  }

  onClickCloseScheduleDialog() {
    this.setState({ createSchedule: false });
  }

  onClickCloseFormatDialog() {
    this.setState({
      failFormat: false,
      createFormat: false,
      formatToDuplicate: null,
      fields: cloneDeep(this.state.originalFields)
    });
  }

  onClickConfirmScheduleDialog(options: IScheduleFormat) {
    this.renderUpdateProcessingSchedule(true);
    createSchedule(options)
      .then(() => {
        this.onClickCloseScheduleDialog();
        this.getSchedules();
        this.getFormats();
        Toast({ text: "Schedule Created" });
      })
      .catch(toastPromiseCatch(this.props.history));
  }

  renderOverlayContent() {
    const {
      scheduleToDelete,
      formatToDelete,
      createSchedule,
      formatsJson,
      createFormat
    } = this.state;
    let content = ["Are you sure you would like to remove this?"];

    if (createSchedule && formatsJson) {
      const frequencyOptions = SCHEDULE_FREQUENCIES;
      let formatInfo: Array<IDropdownItem> = [];
      formatInfo = (formatsJson! as IIndexJsonData).records.filter(format => format.name !== "RDI820").map(format => {
        return {
          title: format.name,
          value: format.id
        };
      });

      return (
        <ScheduleDialog
          onClose={this.onClickCloseScheduleDialog}
          history={this.props.history}
          onConfirm={this.onClickConfirmScheduleDialog}
          formatsList={formatInfo}
          frequenciesList={frequencyOptions}
        />
      );
    }

    if (createFormat) {
      return (
        <PaymentExportFormatModal
          onClose={this.onClickCloseFormatDialog}
          formatsJson={this.state.formatsJson}
          updateFormatsJson={this.onClickUpdateFormatsJson}
          updateIsProcessingFormat={this.renderUpdateProcessingFormat}
          history={this.props.history}
          failFormat={this.state.failFormat}
          onConfirm={() => {
            this.setState({
              createFormat: false,
              formatToDuplicate: null,
              fields: this.state.originalFields
            });
            this.renderUpdateProcessingFormat(true);
            this.getFormats();
          }}
          formatDuplicate={this.state.formatToDuplicate}
          fields={this.state.fields}
        />
      );
    }

    if (scheduleToDelete) {
      const toDelete = scheduleToDelete! as IJsonRecord;
      return this.removalConfirmation(
        () => {
          this.setState({ scheduleToDelete: null });
        },
        () => {
          this.renderUpdateProcessingSchedule(true);
          deleteSchedule(toDelete.id)
            .then(() => {
              this.getSchedules();
              this.getFormats();
            })
            .catch(standardPromiseCatch(this.props.history));
        },
        content
      );
    }

    if (formatToDelete) {
      const toDelete = formatToDelete! as IJsonRecord;
      const inUseCount = toDelete.schedule_count;
      const removeFormatToDelete = () =>
        this.setState({ formatToDelete: null });
      if (inUseCount && inUseCount !== 0) {
        content = [
          `This format cannot be removed as it is being used by ${inUseCount} export schedules.`,
          "Delete the associated schedules before removing the format."
        ];
        return this.formatNotice(removeFormatToDelete, content);
      }

      return this.removalConfirmation(
        removeFormatToDelete,
        () => {
          this.renderUpdateProcessingFormat(true);
          deleteFormat(toDelete.id)
            .then(() => {
              this.getFormats();
            })
            .catch(standardPromiseCatch(this.props.history));
        },
        content
      );
    }
  }

  render() {
    return (
      <ListingPage
        heading="Payment Exports"
        json={null}
        row={ {'identifier': '', 'options': []} }
        pageLayoutProps={pageLayoutProps()}
        scrollableContent={this.renderPageContent}
        overlayContent={this.renderOverlayContent}
      />
    );
  }
}

export default withRouter(PaymentExportsConfigPage);
