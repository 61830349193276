import React from "react";
import styled, { DefaultThemeProps } from "styled-components";

type Props = {
  checked: boolean;
};

type StyleProps = Props & DefaultThemeProps;

const StyledSpan = styled.span<StyleProps>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props =>
    props.checked ? props.theme.providedColor : props.theme.inactiveBackground};
  transition: 0.1s;
  border: 1px solid ${props => props.theme.borderColor};
  box-sizing: border-box;
  border-radius: 4px;

  &:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;

    background: ${props => props.theme.solidBackground};
    box-sizing: border-box;
    border: 1px solid ${props => props.theme.borderColor};
    box-shadow: 0px 2px 4px ${props => props.theme.shadowColor};
    border-radius: 4px;
    transition: 0.1s;
    transform: ${props => (props.checked ? "translateX(28px)" : "")};
  }
`;
StyledSpan.displayName = "StyledSpan";

const SwitchToggle = styled.span`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 32px;
`;
SwitchToggle.displayName = "SwitchToggle";

const Switch = ({ checked }: Props) => {
  return (
    <SwitchToggle>
      <StyledSpan checked={checked} />
    </SwitchToggle>
  );
};

export default Switch;
