import moment from "moment";
import { saveAs } from "file-saver";
import axios, { AxiosResponse } from "axios";
import queryString from "query-string";
import { compact } from "lodash";
import { BASE_URL } from "../lib/constants";
import {
  postJson,
  getSenderIdFromToken,
  mockDataPromise,
  getJson
} from "../lib/helpers";
import { USE_MOCK_JSON } from "../lib/constants";
import { authorizationHeader } from "../../common/lib/helpers";
import { IDropdownItem } from "../../common/types";

import MOCK_PAYMENT_EXPORTS from "../assets/paymentExports.json";

export const getExports = (
  windowStart: number,
  windowSize: number
): Promise<AxiosResponse> => {
  if (USE_MOCK_JSON) {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(mockDataPromise(MOCK_PAYMENT_EXPORTS));
      }, 1000);
    });
  }

  const urlHead = `/manageexports/export/sender/${getSenderIdFromToken()}`;
  const params = queryString.stringify({
    window_size: windowSize,
    window_start: windowStart
  });
  const url = compact([urlHead, params]).join("?");
  return getJson(url);
};

export const getExport = async (
  export_id: number,
  format_name: string,
  date: string
) => {
  const response = await axios
    .create({
      baseURL: BASE_URL,
      headers: Object.assign({}, authorizationHeader()),
      responseType: "blob"
    })
    .get(
      `/manageexports/sender/${getSenderIdFromToken()}/export/${export_id}/file`
    );
  if (format_name === "RDI820") {
    saveAs(new Blob([response.data]), `${format_name} ${date}.rdi`);
  } else {
    saveAs(new Blob([response.data]), `${format_name} ${date}.csv`);
  }
};

export const exportPayments = (
  ids: number[],
  format: IDropdownItem
): Promise<AxiosResponse> => {
  const exportPaymentsJson = {
    export_name: `export-${moment().format("YYYYMMDDhhmmss")}`,
    supplier_id: getSenderIdFromToken(),
    payment_ids: ids,
    format_id: format.value
  };
  return postJson(`/manageexports/export`, exportPaymentsJson);
};
