import React, { Component, Fragment } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import AddUsersBox from "./AddUsersBox";

import PageLayout from "../../../common/components/PageLayout/PageLayout";
import { pageLayoutProps } from "../../lib/helpers";
import { IIndexJsonData } from "../../../common/types.js";

const FullScreenDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;
FullScreenDiv.displayName = "FullScreenDiv";

type PageProps = {} & RouteComponentProps;
type PageState = {
  jsonData: IIndexJsonData | null;
  windowStart: number;
  windowSize: number;
  isRemoveOpen: boolean;
  addUser: boolean;
  editDetails: any;
};

export class NewUserPage extends Component<PageProps, PageState> {
  newUser = (onClose: () => void, onConfirm: () => void) => {
    return (
      <FullScreenDiv>
        <AddUsersBox onClose={onClose} onConfirm={onClose} />
      </FullScreenDiv>
    );

    // return (
    //   <FullScreenDiv>
    //     <EmailExistsBox onClose={onClose} onConfirm={onClose} />
    //   </FullScreenDiv>
    // );
  };

  pageContent = () => {
    return (
      <Fragment>
        {this.newUser(
          () => {
            this.props.history.replace("/enablement/users/");
          },
          () => {
            this.props.history.replace("/enablement/users/");
          }
        )}
      </Fragment>
    );
  };

  overlayContent = () => {
    return null;
  };

  render() {
    return (
      <PageLayout
        pageLayoutProps={pageLayoutProps()}
        scrollableContent={this.pageContent}
        overlayContent={this.overlayContent}
      />
    );
  }
}

export default withRouter(NewUserPage);
