import React, { PureComponent } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { every, values, pick, mapValues } from "lodash";
import { IFilterPanelState, IFilterPillGroup } from "../types";

type FilterPillProps = {
  active: boolean;
} & DefaultThemeProps;

const FilterPill = styled.button<FilterPillProps>`
  cursor: ${props => (props.active ? "" : "pointer")};
  color: ${props =>
    props.active ? props.theme.activeText : props.theme.secondaryText};
  background: ${props =>
    props.active ? `${props.theme.providedColor}` : "unset"};
  border: ${props =>
    `1px solid ${
      props.active ? props.theme.providedColor : props.theme.borderColor
    }`};
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  height: 32px;
  padding: 0 15px;
  min-width: 60px;
  :focus {
    outline: none;
  }
`;
FilterPill.displayName = "FilterPill";

const Pills = styled.div`
  display: flex;
  justify-content: center;
  ${FilterPill} {
    margin-right: 10px;
  }
  ${FilterPill}:last-child {
    margin-right: 0;
  }
`;
Pills.displayName = "Pills";

type Props = {
  filterState: IFilterPanelState;
  filterGroup: IFilterPillGroup;
  applyFilters: (filters: IFilterPanelState) => void;
};

export default class FilterPills extends PureComponent<
  Props,
  IFilterPanelState
> {
  constructor(props: Props) {
    super(props);

    this.onClickPill = this.onClickPill.bind(this);
    this.renderPill = this.renderPill.bind(this);
  }

  state = pick(
    this.props.filterState,
    this.props.filterGroup.items.map(item => item.key)
  );

  onClickAllPillActive(): boolean {
    return every(values(this.state), val => {
      return !Boolean(val);
    });
  }

  onClickPill(key: string): void {
    const newState = mapValues(this.state, () => false);
    if (key !== "all") {
      newState[key] = true;
    }

    this.setState(newState, () => {
      this.props.applyFilters(
        Object.assign({}, this.props.filterState, this.state)
      );
    });
  }

  renderPill(text: string, key: string, active: boolean): React.ReactNode {
    const id = `filter_pill_option_${this.props.filterGroup.key}_${key}`;
    return (
      <FilterPill
        id={id}
        key={id}
        data-selected={active}
        active={active}
        onClick={() => {
          !active && this.onClickPill(key);
        }}
      >
        {text}
      </FilterPill>
    );
  }

  render() {
    return (
      <Pills>
        {this.renderPill("All", "all", this.onClickAllPillActive())}
        {this.props.filterGroup.items.map(filterOption =>
          this.renderPill(filterOption.text, filterOption.key, this.state[
            filterOption.key
          ] as boolean)
        )}
      </Pills>
    );
  }
}
