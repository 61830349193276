import React, { PureComponent, Fragment, ReactNode } from "react";
import styled, { DefaultThemeProps } from "styled-components";
import { isArray, has, forEach, startCase, camelCase, compact } from "lodash";
import Search from "@material-ui/icons/Search";
import LineWeight from "@material-ui/icons/LineWeight";
import Dots from "@material-ui/icons/MoreHoriz";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";
import Checkbox from "./Checkbox";
import RowLimit from "./RowLimit";
import Pagination from "./Pagination";
import SortIndicator from "./SortIndicator";
import Popover from "./Popover";
import {
  IIndexJsonData,
  IRowConfig,
  IAggregateConfig,
  ISelectionConfig,
  IPageConfig,
  IRowLimitConfig,
  ICardOption,
  ISelectedItems,
  IJsonRecord,
  IClickableAction
} from "../types";
import { centsToDisplay, formatNormalUtcDate } from "../lib/helpers";
import { ROW_LIMIT } from "../../enablement/lib/constants";
import { objectLookup } from "../../enablement/lib/helpers";
import StyledButton from "./StyledButton";

const CardContainer = styled.div<DefaultThemeProps>`
  border: 1px solid ${props => props.theme.borderColor};
  box-shadow: 0px 2px 6px ${props => props.theme.shadowColor};
  border-radius: 3px;
  min-height: 400px;
  display: flex;
  flex-direction: column;
`;

const CardHeader = styled.div<DefaultThemeProps>`
  padding: 10px;
  display: flex;
  align-items: center;
  min-height: 40px;
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.secondaryText};
  border-bottom: 1px solid ${props => props.theme.borderColor};
`;
CardHeader.displayName = "CardHeader";

const PaginationWrapper = styled.div`
  margin-left: 30px;
`;

const CardFooter = styled.div<DefaultThemeProps>`
  padding: 10px 30px;
  min-height: 50px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid ${props => props.theme.borderColor};
`;

const CardWrapper = styled.div`
  flex: 1;
  > div {
    border-bottom: 1px solid ${props => props.theme.borderColor};
  }

  > div.hasSubcard {
    border-bottom: none;
  }
`;

const EmptyCard = styled.div`
  min-height: 276px;
  color: ${props => props.theme.secondaryText};
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    margin-top: 54px;
    color: ${props => props.theme.borderColor};
    height: 138px;
    width: 138px;
  }
`;
EmptyCard.displayName = "EmptyCard";

type CardElementProps = {
  align?: string;
  width?: string;
  onClick?(): void;
} & DefaultThemeProps;

const CardElementBase = styled.div<CardElementProps>`
  text-align: ${props => (props.align ? `${props.align}` : "")};
  width: ${props => (props.width ? `${props.width}` : "")};
  cursor: ${props => (props.onClick ? "pointer" : "")};
  padding: 0 20px;
  font-size: 14px;
  color: ${props => props.theme.secondaryText};
`;

type CardProps = {
  hilite: boolean;
} & DefaultThemeProps;

const Card = styled.div<CardProps>`
  padding: 10px;
  display: flex;
  min-height: 77px;
  > ${CardElementBase}:last-child {
    padding-left: 0px;
    padding-right: 20px;
  }
`;
Card.displayName = "Card";

const CardElement = styled(CardElementBase)`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  white-space: pre-wrap;
  word-wrap: break-word;

  .positive {
    color: ${props => props.theme.positiveText};
  }
  .negative {
    color: ${props => props.theme.negativeText};
  }
  .primary {
    font-weight: 500;
    color: ${props => props.theme.primaryText};
  }
  .secondary {
  }
  .smaller {
    font-size: 12px;
  }
  .emphasized {
    font-weight: 500;
    font-size: 18px;
    color: ${props => props.theme.primaryText};
  }
  .nested {
    > div {
      margin-bottom: 5px;
    }
    > div:last-child {
      margin-bottom: 0;
    }
  }
`;
CardElement.displayName = "CardElement";

const CardElementCheckbox = styled(CardElement)`
  cursor: pointer;
  padding: 0;
  width: 1.8%;
`;

const CardElementEmptySpace = styled.div`
  width: 40px;
  cursor: auto;
  padding: 0;
`;

const StyledSortIndicator = styled(SortIndicator)`
  margin-left: 5px;
  vertical-align: text-bottom;
`;

const CardElementHeader = styled(CardElementBase)``;
CardElementHeader.displayName = "CardElementHeader";

const SubCard = styled.div<CardProps>`
  padding: 0 0 0 10px;
  display: flex;
  min-height: 0;

  ${CardElement} {
    width: 100%;
    padding: 5px 10px 5px 0;
    border-top: 1px solid ${props => props.theme.borderColor};
  }

  ${CardElementCheckbox} {
    padding: 10px;
    margin-left: 0;
    border-top: 1px solid transparent;
  }
`;
SubCard.displayName = "SubCard";

const AggregatesWrapper = styled.div<DefaultThemeProps>`
  border-bottom: 1px solid ${props => props.theme.borderColor};
  color: ${props => props.theme.secondaryText};
  font-weight: 500;
  font-size: 12px;
  padding: 10px 31px 10px 10px;
  text-align: right;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

const HeaderRow = styled.div`
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.borderColor};
`;

const HeaderText = styled.div`
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
`;
HeaderText.displayName = "HeaderText";

const HeaderActions = styled.div`
  ${StyledButton} {
    margin-left: 10px;
  }
`;
HeaderActions.displayName = "HeaderActions";

const MenuButton = styled.div`
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  width: 40px;
  height: 30px;
  color: ${props => props.theme.providedColor};
  &.popoverOpen {
    color: ${props => props.theme.activeText};
    background: ${props => props.theme.providedColor};
  }
`;

type TableHeader = {
  text: string;
  actions?: ReadonlyArray<IClickableAction>;
  actionNodes?: ReadonlyArray<ReactNode>;
};

type Props = {
  className?: string;
  json: IIndexJsonData | null;
  isProcessing?: boolean;
  row: IRowConfig;
  aggregates?: ReadonlyArray<IAggregateConfig>;
  header?: TableHeader;

  // TODO: can this be normalized?
  selectedIds?: ISelectedItems;
  selection?: ISelectionConfig;
  updateSelection?: (selectedItems: ISelectedItems) => void;

  // TODO: can this be normalized?
  pageNumber?: number;
  pageSelect?: IPageConfig;
  applyPage?: (nextPageNumber: number) => void;

  // TODO: can this be normalized?
  rowLimit?: number;
  rowLimitSelect?: IRowLimitConfig;
  applyRowLimit?: (nextRowLimit: number) => void;
  isSearching?: boolean;
  emptyResult?: React.ReactNode;
};

export default class DataTable extends PureComponent<Props> {
  constructor(props: Props) {
    super(props);

    this.onClickSelectAll = this.onClickSelectAll.bind(this);
    this.onClickSelectRow = this.onClickSelectRow.bind(this);
    this.renderAggregates = this.renderAggregates.bind(this);
    this.renderCardHeader = this.renderCardHeader.bind(this);
    this.renderCardHeaders = this.renderCardHeaders.bind(this);
    this.renderCardFooter = this.renderCardFooter.bind(this);
    this.renderSubCard = this.renderSubCard.bind(this);
    this.renderCard = this.renderCard.bind(this);
    this.renderEmptyResult = this.renderEmptyResult.bind(this);
    this.renderHeader = this.renderHeader.bind(this);
  }

  onClickSelectAll(jsonData: IIndexJsonData, allSelected: boolean) {
    if (!this.props.updateSelection) return;

    let selectedIds = Object.assign({}, this.props.selectedIds);
    forEach(jsonData.records, record => {
      selectedIds[record.id] = !allSelected;
    });
    this.props.updateSelection(selectedIds);
  }

  onClickSelectRow(record: IJsonRecord) {
    if (!this.props.updateSelection) return;

    let selectedIds = Object.assign({}, this.props.selectedIds);
    selectedIds[record.id] = !selectedIds[record.id];
    this.props.updateSelection(selectedIds);
  }

  getCheckAllSelected(
    json: IIndexJsonData | null,
    selectedIds: ISelectedItems | null | undefined
  ): boolean {
    if (json && selectedIds && json.records.length > 0) {
      return json.records.every(record => {
        return selectedIds[record.id] === true;
      });
    }
    return false;
  }

  renderAggregates() {
    const { json, aggregates } = this.props;
    if (!json) return;

    return (
      aggregates && (
          <AggregatesWrapper>
            {aggregates.map(config => {
              const id = `${config.key}_aggregate_strip`;
              return config.data
                  .filter(conf => conf.visible(json))
                  .map((data, idx) => {
                    const dataId = `${id}_data_${idx} `;
                    return (
                        <span id={dataId} key={dataId}>
                          {idx > 0 && " - "}
                          <span className="data">{data.value(json)}</span>
                          <span> </span>
                          <span className="title">{data.title}</span>
                        </span>
                    );
                  });
            })}
          </AggregatesWrapper>
      )
    );
  }

  renderCardHeader(option: ICardOption, idx: number) {
    const { row } = this.props;
    const { width, align, heading } = option;
    const { text, clickHandler, sortDirection } = heading;
    const key = `${row.identifier}_card_header_${idx}`;
    return (
      <CardElementHeader
        key={key}
        id={key}
        width={width}
        align={align}
        onClick={clickHandler}
      >
        <span>{text}</span>
        {sortDirection && <StyledSortIndicator direction={sortDirection()} />}
      </CardElementHeader>
    );
  }

  renderCardHeaders() {
    const { json, selectedIds, selection, row } = this.props;
    const allSelected = this.getCheckAllSelected(json, selectedIds);
    const headerKey = `${row.identifier}_card_header`;
    const checkboxKey = `${headerKey}_checkbox`;

    if (row.noColumnHeader) return;

    return (
      <CardHeader id={headerKey}>
        {selection && (
          <CardElementCheckbox
            id={checkboxKey}
            key={checkboxKey}
            data-selected={allSelected}
            onClick={() => {
              json && this.onClickSelectAll(json, allSelected);
            }}
          >
            <Checkbox checked={allSelected} />
          </CardElementCheckbox>
        )}
        {row.options.map((option, idx) => {
          return this.renderCardHeader(option, idx);
        })}
      </CardHeader>
    );
  }

  renderCardFooter() {
    const {
      json,
      row,
      rowLimit,
      pageNumber,
      applyPage,
      pageSelect,
      applyRowLimit,
      rowLimitSelect
    } = this.props;

    if (!json) return;
    if (json.records.length === 0) return;

    const showRowLimit = Boolean(rowLimitSelect);
    const showPagination = Boolean(
      pageSelect && rowLimit && json.num_records > rowLimit
    );
    const footerKey = `${row.identifier}_card_footer`;

    return (
      (showRowLimit || showPagination) && (
        <CardFooter id={footerKey}>
          {showRowLimit && (
            <RowLimit
              initialValue={rowLimit}
              list={ROW_LIMIT}
              handleValue={applyRowLimit}
            />
          )}
          {showPagination && (
            <PaginationWrapper>
              <Pagination
                currentPage={pageNumber}
                rowsPerPage={rowLimit as number}
                totalRows={json.num_records}
                applyPage={applyPage}
              />
            </PaginationWrapper>
          )}
        </CardFooter>
      )
    );
  }

  cardCell(option: ICardOption, record: IJsonRecord) {
    let attribute: Array<string> = [];
    if (option.attribute) {
      attribute = option.attribute.split(".");
    }

    if (option.dataType === "cell") {
      return option.cardCell(record);
    }

    if (option.dataType === "date") {
      return (
        <div className={option.className}>
          {formatNormalUtcDate(
            objectLookup(attribute, record),
            option.displayFormat
          )}
        </div>
      );
    }

    if (option.dataType === "text") {
      let string = objectLookup(attribute, record);
      let classes: Array<string | null | undefined> = [option.className];

      if (String(attribute) === "created_by" && string === "null null") {
        return <div className={compact(classes).join(" ")} />;
      }

      if (option.classDecorator) {
        classes.push(option.classDecorator(record));
      }

      if (option.titleCase) {
        string = startCase(camelCase(string));
      }

      if (!string) {
        return <div className={compact(classes).join(" ")} />;
      }

      if (option.template) {
        string = option.template(record).replace("?", string);
      }

      return <div className={compact(classes).join(" ")}>{string}</div>;
    }

    if (option.dataType === "amount") {
      const currencyCode = objectLookup(["currency"], record);
      return (
        <div className={option.className}>
          {centsToDisplay(objectLookup(attribute, record) as number, currencyCode)}
        </div>
      );
    }

    if (option.dataType === "img") {
      const showImage =
        !has(option, "attribute") || objectLookup(attribute, record);
      return showImage && <img src={option.img} alt={option.alt} />;
    }

    if (option.dataType === "menu") {
      return (
        <div className={option.className}>
          <Popover
            trigger={<Dots />}
            triggerComponent={MenuButton}
            actions={option.menuOptions(record)}
          />
        </div>
      );
    }

    if (option.dataType === "nested") {
      return (
        <div className="nested">
          {option.nested.map((nestedOption, idx) => {
            return (
              <div key={`nested_option_${idx}_${record.id}`}>
                {this.cardCell(nestedOption, record)}
              </div>
            );
          })}
        </div>
      );
    }

    return null;
  }

  renderSubCard(
    record: IJsonRecord,
    option: ICardOption,
    selected: boolean,
    idx: number
  ) {
    const { row, selection } = this.props;
    const cardKey = `${row.identifier}_subcard_${idx}`;

    return (
      <SubCard hilite={selected}>
        {selection && <CardElementEmptySpace>&nbsp;</CardElementEmptySpace>}
        <CardElement
          id={cardKey}
          key={cardKey}
          width={option.width}
          align={option.align}
        >
          {this.cardCell(option, record)}
        </CardElement>
      </SubCard>
    );
  }

  renderCard(record: IJsonRecord, idx: number) {
    const { selectedIds, selection, row } = this.props;
    const selected = selectedIds && selectedIds[record.id] ? true : false;
    const subCard = row.subCard;
    const cardKey = `${row.identifier}_card_${idx}`;
    const checkboxKey = `${cardKey}_checkbox`;

    return (
      <Fragment key={`${row.identifier}_card_fragment_${idx} `}>
        <Card
          id={cardKey}
          key={cardKey}
          hilite={selected}
          className={subCard ? "hasSubcard" : ""}
        >
          {selection && (
            <CardElementCheckbox
              id={checkboxKey}
              key={checkboxKey}
              data-column={`${row.identifier}_card_column_checkbox`}
              data-selected={selected}
              onClick={() => this.onClickSelectRow(record)}
            >
              <Checkbox checked={selected} />
            </CardElementCheckbox>
          )}
          {row.options.map((option, elementIdx) => {
            const { width, align, onClick } = option;
            const columnKey = `${row.identifier}_card_column_${elementIdx}`;
            const elementKey = `${row.identifier}_card_${idx}_element_${elementIdx}`;
            return (
              <CardElement
                id={elementKey}
                key={elementKey}
                data-column={columnKey}
                width={width}
                align={align}
                onClick={
                  onClick &&
                  (() => {
                    onClick(record);
                  })
                }
              >
                {this.cardCell(option, record)}
              </CardElement>
            );
          })}
        </Card>
        {subCard && this.renderSubCard(record, subCard, selected, idx)}
      </Fragment>
    );
  }

  renderEmptyResult() {
    const { row } = this.props;
    let result: React.ReactNode | Element = (
      <Fragment>
        <LineWeight />
        <span>No results to show.</span>
      </Fragment>
    );
    if (this.props.emptyResult) {
      result = this.props.emptyResult;
    } else if (this.props.isSearching) {
      result = (
        <Fragment>
          <Search />
          <span>Your search did not match any results.</span>
        </Fragment>
      );
    }
    return <EmptyCard id={`${row.identifier}_empty_card`}>{result}</EmptyCard>;
  }

  renderHeader() {
    const { row, header } = this.props;
    if (!header) return;

    const headerKey = `${row.identifier}_header`;
    return (
      <HeaderRow id={headerKey}>
        <HeaderText id={`${headerKey}_text`}>{header.text}</HeaderText>
        {(header.actions || header.actionNodes) && (
          <HeaderActions id={`${headerKey}_actions`}>
            {header.actions &&
              header.actions.map((action, idx) => {
                const key = `${headerKey}_action_${idx}`;
                return (
                  <StyledButton
                    key={key}
                    id={key}
                    onClick={action.clickHandler}
                  >
                    {action.text}
                  </StyledButton>
                );
              })}
            {/* if action is react node */}
            {header.actionNodes &&
              header.actionNodes.map((actionNode, idx) => {
                const key = `${headerKey}_action_${idx}`;
                return <div key={key}> {actionNode} </div>;
              })}
          </HeaderActions>
        )}
      </HeaderRow>
    );
  }

  render() {
    const { json, row, className } = this.props;

    if (json) {
      return (
        <CardContainer id={row.identifier} className={className}>
          {this.renderHeader()}
          {this.renderAggregates()}
          {this.renderCardHeaders()}
          {/* spinner when adding record */}
          {this.props.isProcessing ? (
            <CardWrapper>
              <LoadingWrapper>
                <LoadingSpinner size="80px" />
              </LoadingWrapper>
            </CardWrapper>
          ) : (
            <CardWrapper>
              {!isArray(json.records) || !json.records.length
                ? this.renderEmptyResult()
                : json.records.map((record, idx) => {
                    return this.renderCard(record, idx);
                  })}
            </CardWrapper>
          )}
          {this.renderCardFooter()}
        </CardContainer>
      );
    } else {
      return (
        <CardContainer id={row.identifier} className={className}>
          <LoadingWrapper>
            <LoadingSpinner size="80px" />
          </LoadingWrapper>
        </CardContainer>
      );
    }
  }
}
