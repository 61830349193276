import styled, { DefaultThemeProps } from "styled-components";

const StyledButton = styled.button<DefaultThemeProps>`
  display: inline-flex;
  align-items: center;
  height: 44px;

  font-weight: 600;
  font-size: 16px;
  color: ${props => props.theme.activeText};

  background: ${props => props.theme.providedColor};
  box-shadow: 0px 3px 6px ${props => props.theme.shadowColor};
  border-radius: 3px;
  border: none;

  padding: 0 25px;

  :focus {
    outline: none;
  }

  :disabled {
    cursor: auto;
    opacity: 0.3;
    box-shadow: none;
  }

  cursor: pointer;
`;
StyledButton.displayName = "StyledButton";

const ButtonMedium = styled(StyledButton)`
  padding: 0 5px;
  height: 34px;
`;

export default StyledButton;

export { ButtonMedium };
